export default {
    //所有都包含在m里面
    m:{
        returnToEnterpriseCenter: "返回企业中心",
        source: {
            wechat :"微信",
            other :"其他"
        },
        placeholder: {
            pleaseEnter : "请输入",
            pleaseSelect: "请选择"
        },
        header:{
            toMaking:'设计端',
            help:'帮助与客服',
            custimer:"联系客服",
            time:"9:00-22:00  7*12小时服务",
            phone:"电话：",
            placeholder:"搜索功能和帮助",
            more:"了解更多",
            helpCenter:"帮助中心",
        },
        error:{
            length:'长度在 {min} 到 {max} 个字符'
        },
        menu:{
            dashboard:'概况',
            template:'模板',
            templateManage:"管理",
            allTemplate:"所有模板",
            templateGroup:'模板分组',
            goods:'商品',
            allGoods:'所有商品',
            goodsGroup:'商品分组',
            goodsTemp:'商品模板',
            shop:'小程序',
            subShop:'分店管理',
            allSubShop:'所有分店',
            miniPro:'小程序管理',
            codeTemplate:'微信代码模板',
            wechat:'微信',
            shopModel:'店铺装修',
            pages:'微页面',
            material:'企业素材',
            article:'文章',
            articleManage:'管理',
            allArticle:'所有文章',
            articleGroup:'文章分组',
            articleTemp:'文章模板',
            setting:'设置',
            channel:'渠道',
            tool:'工具',
            toolManage:'系统工具',
            channelManage:"管理",
            channelAll:"所有渠道",
            statistic:"统计",
            communion:'通知',
            communionManage:"管理",
            valuation:"通知列表",
            qa:"QA",
            formSubmit:"表单管理",
            searchManage:"查询管理",
            dataset:"管理",
            dataset_detail:"查询信息",
            order:'订单',
            orderManage:'订单管理',
            orderSearch:'订单查询',
            afterSale:"售后管理",
            customer:'客户',
            customerManage:"客户管理",
            customerSearch:"客户管理",
            enterpriseSearch:"企业管理",
            coupon:'卡券',
            couponManage:"卡券管理",
            couponList:"卡券列表",
            couponHistory:"核销记录",
        },
        dashboard:{
            buyNow:'立即续期',
            fabuxiaochengxu:"发布小程序",
            shejixiaochengxu:"设计小程序",
            qianwangfabu:"前往发布",
            yifabu:"已发布",
            xuanzemuban:"去设计",
            goumai:"购买升级",
            youxiaoqi:"有效期至：",
            liuliangzonglan:"流量总览",
            tongjishijian:"统计时间：",
            placeholder:"请选择",
            liulanfangwen:"浏览访问",
            chengjiaoshuju:"成交数据",
            fangkeliang:"访客数",
            liulanliang:"浏览量",
            renyunliulan:"人均浏览量",
            chengjiaodingdanshu:"成交订单数",
            chengjiaojine:"成交金额",
            bijiao:"较前一个月",
            liuliangqushi:"流量趋势",

        },
        //店铺
        shop:{
            model: {
                materialCenter: "素材中心",
                microPage: "微页面",
            },
            subShop: {
                allBranches: "所有分店"
            },
            wechatPaymentCertificateApplicationTutorial: "微信支付证书申请教程",
            uploaded: "已上传",
            shouquantitle:"授权及发布小程序",
            chakanjiaocheng:"查看教程",
            zhucexiaochengxu:"已在微信公众平台注册过小程序，立即授权发布",
            zhucetishi:"使用微信小程序管理员帐号扫码进行授权，授权过程中请勾选所有权限以确保小程序功能完整性。如未注册小程序，可以从公众号后台免微信认证创建小程序或直接前往“微信公众平台”注册企业主体的小程序帐号。",
            weixinzhuce:"微信官方注册小程序",
            lijishouquan:"立即授权",
            shuaxin:"刷新",
            shouquanjiechutips:"如需解除授权，请到微信公众平台。",
            pintaizhuyin:"微信平台解除授权指引",
            editminiName:"修改小程序名称",
            editminiNameTips:"修改小程序名称，需提交企业身份证明材料（如企业营业执照等照片），并等待微信审核。",
            tijiaoshenghe:"提交审核",
            shezhizhifu:"设置微信支付参数",
            shezhitishi:"请确认以下内容配置正确，否则小程序无法通过审核！",
            zhufujiaohceng:"微信支付申请及教程",
            tijiaoweixinshenghe:"提交微信审核",
            fabudaoxianshang:"发布到线上",
            fabuxiaochengxu:"完成所有准备，提交审核并发布小程序",
            zhifushanghuhao:"微信支付商户号：",
            zhifumiyao:"商户支付密钥：",
            uploadzs:"微信支付证书上传：",
            zftips:"微信支付证书主要用于商家售后服务自动退款功能，如果不上传证书将不能实现自动退款，只能线下给客户退款。",
            fabutishi:"提交微信审核（最长14个工作日），审核通过后即可立即发布版本",
            qianwangfabu:"前往发布",
            shezhixiaochengxu:"设置小程序信息",
            qingshuru:"请输入",
            queding:"确定",
            shibaiyuanyin:"失败原因：",
            guanliyuanshouquan:"管理员授权",
            saomatishi:"请小程序管理员打开微信扫一扫勾选所有权限并授权",
            yishouquan:"已授权",
            shibai:"已发布，审核失败",
            fabuSucess:"发布成功",
            jiebenxinxi:"微信小程序基本信息",
            banbenxinxi:"版本信息",
            shenghezhuangtai:"审核状态：",
            tijiaozhuangtai:"提交时间：",
            shengheshijian:"审核时间：",
            banbenhao:"版本号：",
            xiugai:"修改",
            tijiaoshenhe:"提交审核",
            chongxinshenhe:"重新审核",
            fabuhzuangtai:"发布状态：",
            fabushijian:"发布时间：",
            zanwushenhe:"您暂无提交审核的版本",
            zanwufabuneirong:"您暂无要发布的版本",
            label:{
                mininame:"小程序名称：",
                miniUrl:"小程序头像：",
                mininote:"小程序介绍：",
                fuwuleimu:"服务类目：",
                xainshangbanben:"线上版本：",
                updatetime:"更新时间：",
                updatestate:"更新状态：",
                appid:"APP ID：",
                appkey:"APP key：",
            },
            tips:{
                nametips:"小程序名称一年可以修改",
                nametipshou:"次，今年还可以修改",
                urltips:"头像一年可以修改",
                urltipshou:"次，今年还可以修改",
                notetips:"信息一个月可以修改",
                notetipshou:"次，本月还可以修改",
                fuwutips:"修改服务类目请到微信公众平台",
                fuwutipshou:"修改服务类目教程",
                ci:"次"
            },
            editBtn:{
                jiechubangding:"解除授权",
                edit:"修改",
                queding:"确定",
                update:"更新记录",
                shenghe:"重新提交审核",
            },
            placeholder:{
                maxlength:"4字以内",
                jieshao:"这里是小程序介绍",
            }
        },
        pubilc:{
            shanchu:'删除',
            cancel: '取消',
            clear: '清空',
            confirm: '确定',
            CheckFor:"查询",
            chongzhi:"重置",
            sousuo:"搜索",
            chakan:"查看",
            bianji:"编辑",
            fuzhi:"复制",
            xaizai:"下载",
            caozuo:'操作',
            xiangqing:"详情",
            tongguo:"通过",
            jujue:"拒绝",
            xuhao:"序号",
            tinajia:"添加",
            chaxunxiangmu:"查询项目",
            gong:"共",//注意英文不要填写
            tiaoye:"条，每页",
            tiao:'条',
            ge:"个",
            xiayibu:"下一步",
            shangyibu:"上一步",
            save:"保存",
            tip_success:"成功",
            tips:"提示",
            zhi:'至',
            back:'返回',
            export:'导出',
            tishi:'提示',
            zuojinyizhou:"最近一周",
            zuijinyigeyue:'最近一个月',
            zuijinsangeyue:"最近三个月",
            gexinxixiang:"信息项",
            tioaxinxi:"信息",
        },
        //文章
        article:{
            zixun:"文章",
            fabuzhong:"发布中",
            caogao:"草稿",
            caogaoxiang:"草稿箱",
            creationTime:"创建时间",
            zixunguanli:'文章管理',
            tianjiazixun:"添加文章",
            zixuntitle:"文章标题",
            InformationGroup:"文章分组",
            quanbu:'全部',
            xuhao:"序号",
            subhead:"副标题",
            author:"作者",
            quedingfuzhizixunma:'确定复制文章吗?',
            yixuanzixun:'已选文章',
            jiarucaogao:'加入草稿',
            yichucaogao:'移除草稿',
            xinjianzixun:"新建文章",
            zixuntupian:'文章图片',
            tianjiaimg:"添加图片",
            xuhaopaixu:'序号排序',
            xixunguanliorbianjizixunmoban:"文章管理 / 编辑文章模板",
            baocunzixunmoban:'保存文章模板',
            ziuxnmobanguanli:'文章模板管理',
            xinjianzixunmoban:'新建文章模板',
            xiugaishijiang:'修改时间',
            zixunzhuangtai:"文章状态",
            fenxiangmiaoshu:"分享描述",
            lijichuangjiang:"立即创建",
            qeudingfuzhizixunmobanma:'确定复制文章模板吗?',
            quedingshanchuzixunmobanma:'确定删除文章模板吗?',
            qingshuruzixunbiaoti:"请输入文章标题",
            changduzai1da255gezifu:"长度在 1 到 255 个字符",
            qingshurufubiaoti:"请输入副标题",
            qingshuruzuozhe:'请输入作者',
            qingxuanzezixunzhuangtai:"请选择文章状态",
            qingtianxiefeixiangmiaoshu:'请填写分享描述',
            qingxuanzhongfenzu:"请选中分组",
            piliangxiugaizixunchenggong:"批量修改文章分组成功！",
            xiugaixihuchenggong:"修改序号成功！",
            zixunfenzuguanli:"文章分组管理",
            xinjianfenzu:'新建文章分组',
            fenzumingcheng:"分组名称",
            mobanmingcheng:"模板名称",
            quedingfuzhizixunfenzuma:'确定复制文章分组吗?',
            quedingshanchuzixunfenzu:"确定删除文章分组吗?",
            quedingshanchushangpinfenzu:"确定删除商品分组吗?",
            zixunguanlihebianji:'文章管理/文章编辑',
            InformationQuantity:"文章数量",
            qingshurufenzuname:"请输入分组名称",
            changduzai2dao5gezifu:"长度在 2 到 5 个字符",
            gaifenzu:"改分组",
            shanchu:'删除',
            cancel: '取消',
            clear: '清空',
            confirm: '确定',
            CheckFor:"查询",
            chongzhi:"重置",
            caozuo:'操作',
            gong:"共",//注意英文不要填写
            tiaoye:"条，每页",
            tiao:'条',
            ge:"个",
        },
    // 商品
        goods:{
            selectTemplate: "选择模板",
            dragAndDropComponentsHere:"在此处拖放组件",
            productDetails: "商品详情",
            service: "收货后结算·快递发货",
            fills:'填充',
            shangpinguanli:'商品管理',
            shangpinfenzuguanli:"商品分组管理",
            xaioshouzhong:'销售中',
            yishoukong:'已售罄',
            cangkuzhong:'仓库中',
            shangpinmingchengorbianma:"商品名称或编码",
            pleaseInput:"请输入",
            commodityGroup:"商品分组",
            qingxuanze:"请选择",
            zongxiaoliang:"总销量",
            shangpinhuojiage:"商品/价格",
            creationTime:"创建时间",
            buchaoguoduoshaogezifu:"不超过255个字符",
            gaijia:"改价",
            kucun:"库存",
            fangwenliang:"访问量",
            liulanliang:"浏览量",
            quedingxiugaimorenfenzu:"确定修改商品的分组?",
            yixuanshangpin:"已选商品",
            gaifenzu:"改分组",
            tianjiashangpin:"添加商品",
            xiajia:'下架',
            shangjia:"上架",
            qingshurupinpainame:"请输入品牌名称",
            changduzai1dao32gezifu:"长度在 1 到 32 个字符",
            qingshurufenzuname:"请输入分组名称",
            changduzai2dao5gezifu:"长度在 2 到 5 个字符",
            qudingshanchushangpin:"确定删除商品?",
            changduzai1dao50gezifu:'长度在1 到50个字符',
            changduzai1dao200gezifu:'长度在1 到200个字符',
            qingshurujiage:'请输入价格',
            qingshurukucun:'请输入库存',
            commodity:"商品",
            brandName:"品牌名称",
            GroupName:"分组名称",
            p_quantity:"商品数量",
            xinjianshanpinfenzu:'新建商品分组',
            lijichuangjiang:"立即创建",
            gengxinshijian:'更新时间',
            shangpinmobanguanli:"商品模板管理",
            xinjianshangpinmob:'新建商品页模板',
            title:"标题",
            shangpinguanliorbianji:"商品管理/商品编辑",
            jibenxinxi:'基本信息',
            productName:"商品名称",
            shangpinbianma:'商品编码',
            fenxiangmiaoshu:"分享描述",
            shangpinmadian:'商品卖点',
            shangpintu:'商品图',
            jiagekucun:"价格库存",
            shangpinguige:"商品规格",
            tinajiaguige:"添加规格",
            chengbenjia:'成本价(元)',
            xaioshoujia:"销售价(元)",
            img:"图片",
            jaige:"价格(元)",
            huaxianjia:"划线价(元)",
            kucunkoujian:"库存扣减方式",
            paixiajiangkucun:"拍下减库存",
            zhifujiangkucun:"付款减库存",
            shipinxiangqingbuxiansh:"商品详情不显示剩余件数",
            huyuanzhekou:"会员折扣",
            canyuehuyanzhek:'参与会员折扣',
            yuyuefangsh:"预约方式",
            mianfenyuyue:"免费预约",
            fufeiyuyue:"付费预约",
            wuliu:'物流',
            peisongfangsh:"配送方式",
            daodianziti:"到店自提",
            kuaidifahua:'快递发货',
            qitashijian:'其他',
            shangjiashijian:"上架时间",
            lijishangjiashoumai:'立即上架售卖',
            zanbushoumaifangdaocanku:'暂不售卖，放入仓库',
            zidingyishangjiashijian:'自定义上架时间',
            xuanzeriqi:'选择日期',
            lijihoumaianniu:'立即购买按钮',
            morenmingcheng:'默认名称',
            zidingyimingcheng:"自定义名称",
            guigemingcheng:"规格名",
            guigezhi:"规格值",
            tianjiaguigezhi:'添加规格值',
            qingtianjiaguige:"请添加规格值",
            tips:"提示",
            qingshurushangpinmingcheng:'请输入商品名称',
            qingshurushangpinbianma:'请输入商品编码',
            qingshurushangpinmaidian:"请输入商品卖点",
            zhishaoxuanzeyizhangshangpinimg:"请至少选择一张商品图片",
            qingxuanzeshangpinfenzu:"选择商品分组",
            gaiming:"改名",
            tianjiaimg:"添加图片",
            buyNow: "立即购买"
        },
    //    模板
        template:{
            nameOrnum:'模板名称或编码',
            templateGroup: "模板分组",
            userVersion:'版本号',
            userDesc:"版本描述",
            templateType:"代码模版类型",
            createTime:"创建时间",
            fabu:"发布",
            fabuzhengshi:'发布到正式模板',
            edit:{
                name:"模板名称",
                code:'模板编码',
                span:'模板标签',
                hot:"模板卖点",
                fengmian:'模板封面图',
                yulantu:'模板预览图',
                jieshaotu:'模板介绍图',
                group:"模板分组",
                wxtemplateCode:"微信模板编码",
                shoumaiNum:'售卖数量',
                saleState:'售卖状态',
                rela:"模板星级",
                zhichipintai:"支持平台",
                isBlank:"是否空白模板",
                basePrice:"模板价格",
                mubanguige:'模板规格',
                banben:"版本",
                duration:"时长",
                price:'价格',
                wxID:"小程序ID",
            },
        },
    //    卡券
        coupon:{
            kaquanguanli:"卡券管理",
            voucher:"代金券",
            daijingquanmsg:'例:20元代金券',
            bianyuehelikongzhi:"便于合理控制活动成本",
            lijinxinjiang:'立即新建',
            guanjiangci:'关键词',
            youhuimingcheng:'优惠券名称',
            CheckFor:"查询",
            name:"名称",
            leixing:'类型',
            IssueNumber:"发放张数",
            alreadyReceived:"已领取",
            residue:"剩余",
            yihexiao:'已核销',
            state:"状态",
            valid:"有效",
            loseEfficacy:"失效",
            xiangqing:"详情",
            changtishi:'失效后，用户看不到此优惠券，无法领取。但是已经领取的优惠券可以继续使用。',
            quedingshanchuma:'确定删除吗？',
            fafang:"发放",
            fafangtip:"确定发放",
            beizhu:'备注:',
            jinge:'抵扣金额:',
            tiaojian:'使用条件:',
            fafangzongliang:'发放总量:',
            alluser:"全部企业",
            oneuser:"指定企业",
            userPlace:"请选择企业",
            modulePlace:"请选择模板",
            allmodule:"全部模板",
            onemodule:"指定模板",
            allchangjin:"全部场景",
            newUser:"新用户注册发放",
            xianlingshuliang:'限领数量:',
            xianlingyonghu:"限领企业:",
            xianlingmuban:"限领模板:",
            shiyongchangjin:"适用场景:",
            lingqushijian:'领取时间:',
            shiyongshijian:'使用时间:',
            shiyongshuoming:'使用说明:',
            duihuancode:"兑换码",
            zhi:'至',
            kaishishijian:'开始日期',
            jieshushijiang:'结束日期',
            couponEdit:"卡券编辑",
            couponAdd:"卡券新增",
            qingshezhiyouhuiquanname:"请设置优惠券名称",
            qingshezhijine:"请设置金额",
            qingshezhifafang:"请设置发放总量",
            qingshezhixianling:"请设置限领数量",
            qingxuanzeqiye:"请选择限领企业",
            qingxuanzechangjin:"请选择适用场景",
            qingxuanzemuban:"请选择限领模板",
            shezhilingqushijian:"请设置领取时间",
            qingshezhishiyongshijian:"请设置使用时间",
            hexiaojiru:'核销记录',
            hexiaofendian:"核销分店",
            youhuijiangmingcheng:'优惠券名称',
            shiyongyonghu:'使用企业',
            hexiaoshijian:'核销时间',
            fafangbiaoti:"发放标题",
            biaotiPlace:"请输入发放标题",
            fafangtips:"发放内容",
            neirongPlace:"请输入发放内容",
            fafangqiye:"发放企业",
            qiyePlace:"请选择发放企业",
        },
    //    客户
        customer:{
            all:"全部",
            kehuchaxun:"客户查询",
            guanjiangci:'关键词',
            qingshuruguanjianci:'请输入关键词',
            qudao:'渠道:',
            qingxuanze:"请选择",
            zhuceshijian:'注册时间',
            kaishishijian:'开始日期',
            jieshushijiang:'结束日期',
            youxiang:'头像',
            neicheng:'昵称',
            laiyuanqudao:"来源渠道",
            kehuxianqing:'客户详情',
            jibenxinxi:'基本信息',
            sex:'性别',
            chengshi:'城市',
            shengfen:'省份',
            guojia:"国家",
            pingtai:"平台",
            huzhaoxinxi:'护照信息',
            huzhaohao:'护照号',
            xingming:'姓名',
            qianfadi:'签发地',
            shengri:'生日',
            shoujuhao:"手机号码",
            youxian:'邮箱',
            zichengguanli:'资产管理',
            keyongquan:'可用优惠券',
            jiaoyixinxi:'交易信息',
            leijidingdan:'累计订单',
            leijixiaofei:"累计消费金额(元)",
            leijixaiofeidanshu:'累计消费订单数',
            kedanjia:'客单价(元)',
            zuijinxaidan:'最近下单时间',
            youhuijiangmingcheng:'优惠券名称',
            weishiyong:'未使用',
            gettime:'获取时间',
            state:"状态",
            gongsi:"公司/组织名称",
            tuandui:"团队规模",
            hangye:"所属行业",
            zhuceren:"注册人",
            chengyuan:"成员",
            xiaochengxu:"小程序",
            kehu:"客户",
            zhanghao:"账号",
            zhiye:"职业",
            qiyeyuzuzhi:"企业/组织",
            jiruqiye:"加入企业",
            xiezuo:"协作小程序",
            chengyuanxinxi:'成员信息',
            minixinxi:'小程序信息',
            jiarushijian:"加入时间",
            role:"角色",
            createdTime:"创建时间",
            createder:"创建人",
            suihoufabu:"最后发布时间",
            zuihoubanben:"最后版本",
            qiyemingcheng:"企业名称",
            jiruhuochaungjian:"加入/创建时间",
            jianjie:"个人简介",
            erweima:"二维码",
            fukuanxinxi:"最后付款信息",
            guishu:"归属企业",
            zhaungtai:"支付状态",
        },
        //订单
        order:{
            wxPay:"微信",
            zhifubao:"支付宝",
            chuangjian:"创建订单",
            yizhifu:"已支付",
            yiquxiao:"已取消",
            weishengqin:"未申请",
            yishenqin:"已申请",
            yifasong:"已发送",
            noData: "暂无数据",
            qingxuanzekuaidigongsi:'请选择快递公司',
            dingdanchaxun:'订单查询',
            xiadanshijian:"下单时间",
            dingdanzhuangtai:'订单状态',
            kaishishijian:'开始日期',
            jieshushijiang:'结束日期',
            kehusousuo:'客户搜索',
            qingshurukehuid:'请输入客户ID',
            yuyushijiang:"预约时间",
            dingdanssousuo:'订单搜索',
            qingshurudingdanbianhao:'订单编号/姓名/手机号',
            chongzhishaixuantiaojian:'重置筛选条件',
            dingdanno:'订单编号',
            maijia:"客户",
            template:'模板信息',
            miniProName:"小程序",
            couponName:'优惠券',
            payType:'支付方式',
            xaidanshijian:'下单时间',
            fasongfapiao:"发送发票",
            fahuo:'发货',
            fapiaozhuangtai:'发票状态',
            dingdanwangcheng:'完成订单',
            tuikuan:'退款',
            tishi:'提示',
            qingshurutihuoma:"请输入提货码",
            qingshurukuaididanhao:"请输入快递单号",
            quedingtuikuangma:"确定退款吗？",
            biaojiweiyifasong:"标记为已发送",
            didanhaowei:"订单号为",
            jintian:"今天",
            zuotian:'昨天',
            zuojinyizhou:"最近一周",
            zuijinyigeyue:'最近一个月',
            zuijinsangeyue:"最近三个月",
            qingxuanze:"请选择",
            quanbu:'全部',
            dingdanjine:"订单金额",
            daidukuang:"待付款",
            yifukuan:"已付款",
            daifahua:'待发货',
            daishouhuo:'待收货',
            yiwucheng:'已收货',
            yihuanbi:"已关闭",
            tunkuanzhong:"已完成",
            daiquhuo:"售后中",
            dingdanxinxi:"订单详情",
            dingdanxixin:'订单信息',
            dingdanhao:'订单号',
            zhifujine:"支付金额",
            zhifushijian:'支付时间',
            jieshushitime:'结束时间',
            kuaidigongsi:'快递公司',
            kuaidibianhao:"快递单号",
            kuaidibeizhu:'备注信息',
            maijiaxinxi:'买家信息',
            maijiamingcheng:'买家名称',
            shouhuorenxingming:'收货人姓名',
            shouhuorenlianxi:'联系方式',
            maijialiuyuan:"买家留言",
            shengfeiguojia:'省份/国家',
            shouhuodizhi:'收货地址',
            shangjiaxinxi:'商家信息',
            dianpu:'店铺',
            huopinxinxi:'货品信息',
            guige:'规格',
            danjia:'单价',
            shuliang:"数量",
            xaioji:"小计",
            shangpinzongjia:"商品总价",
            shishoujine:'实收金额',
            yunfeijine:'运费',
            youhuijine:'优惠金额',
            youhuijiangmingcheng:'优惠券名称',
            state:"状态",
            commodity:"商品",
            yuantong_kuaidi:'圆通快递',
            zhongtong_kuaidi:'中通快递',
            shentong_kuaidi:'申通快递',
            yunda_kuaidi:'韵达快递',
            baishi_kuaidi:'百世快递',
            shunfeng_kuaidi:'顺丰快递',
            tiantian_kuaidi:'天天快递',
            ems_kuaidi:'EMS',
            youzheng_kuaidi:'邮政物流',
            debang_kuaidi:'德邦物流',
            jingdong_kuaidi:'京东快递',
            qita_kuaidi:'其他',
            shengqinshijian:"申请时间",
            shouhouleixing:"售后类型",
            shouhouxinxi:"售后信息",
            kehumingcheng:"客户信息",
            dingdanbianhao:"订单编号",
            shurukehuname:"请输入客户信息",
            shurudingdanbianhao:"请输入订单编号",
            jintuikuan:"仅退款",
            tuikuantuihuo:"退货退款",
            huanhuo:"换货",
            daishangjiachuli:"待商家处理",
            daishangjiashouhuo:"待商家收货",
            diamaijiachuli:"待买家处理",
            shenqinSort:"按最近申请排序",
            chaoshiSort:"按临近超时排序",
            shouhoubinahao:"售后编号：",
            dingdanbinahao:"订单编号：",
            chuli:"处理",
            chakan:"查看详情",
            typeData:{
                yifahuo:"已收货",
                weishouhuo:"未收货",
            },
            aftersaleType:{
                jintuikuan:"仅退款",
                tuihuotuikuan:"退款退货",
                huanhuo:"换货"
            },
            refundSteps:{
                maijiaweiquan:"买家维权申请",
                shangjiashenhe:"商家审核",
                tianxiewuliu:"填写物流",
                shangjiatuikuan:"商家退款",
                shangjiafahuo:"商家发货",
                tuikuanwancheng:"退款完成",
                shouhouwancheng:"售后完成",
            },
            statusStr:{
                shangjiachuli:"待商家处理",
                maijiachuli:"待买家处理",
                shouhouwancheng:"售后完成",
            },
            refundStr:{
                xinshenqin:"新申请",
                daiqueren:"同意申请并等待商家确认退款",
                shangchuanwuliu:"同意申请并等待用户上传物流信息",
                xianshangtuikuan:"已经确认线上退款完成",
                xianxiatuikuan:"已经确认线下退款完成",
                tuikuanchenggong:"微信退款成功",
                huanhuochenggong:"换货成功",
                yonhudanhao:"用户已经填写快递单号",
                shangjiadanhao:"商家已经填写快递单号，待用户收货",
                jujueshenqin:"拒绝申请",
                zidongquxiao:"等待用户操作超时，已自动取消售后",
            },
            afterSaleClomn:{
                shangpin:"商品",
                fahuozhuangtai:"发货状态",
                dingdanjine:"订单金额(元)",
                tuikuanjine:"退款金额(元)",
                shengqinshijian:"申请时间",
                chaoshishijian:"超时时间",
                shouhouhzuangtai:"售后状态",
                caozuo:"操作"
            },
            bianhao:"编号",
            qiwangjieguo:"期望结果",
            tuikuanjine:"退款金额",
            weiquanyuanyi:"维权原因",
            weiquanmiaoshu:"维权描述",
            weiquanpinzheng:"维权凭证",
            fukuanshijian:"付款时间",
            maijian:"买家",
            wuliuxinxi:"物流信息",
            yunfei:"运费",
            hejiyouhui:"合计优惠",
            shifujine:"实付金额",
            maijietuikuantips:"收到买家退款申请，请尽快处理。",
            maijietkthtips:"收到买家退款退货申请，请尽快处理。",
            maijiehhtips:"收到买家换货申请，请尽快处理。",
            yesshenqing:"同意申请",
            noshenqing:"拒绝申请",
            tuihuoliyou:"本店不支持七天无理由退换货，谢谢！如有问题请咨询店铺客服。",
            kuaidijine:"快递费用",
            qurentuikuan:"如已收到退货，请确认退款。",
            tianxiewuliufahuo:"如已收到退货，请填写物流信息，并确认发货。",
            xianshangqueding:"线上确认",
            xianxiaqueding:"线下确认",
            tongyituikuanwuliu:"您已同意退款，等待买家填写物流信息。",
            tongyihuanhuowuliu:"您已同意换货，等待买家填写物流信息。",
            weixinchuli:"微信处理中...",
            yituikuan:"您已退款",
            yuan:"元",
            jujueshenqin:"您已拒绝买家申请。",
            jujueliyou:"拒绝理由",
            tixing:"提醒",
            qinshurujujue:"请输入拒绝退款理由",
            qingshurukdgongsi:"请选择快递公司",
            qingshurukddanhao:"请输入快递单号",
            quanyi:"如果您拒绝申请，请与买家进行协商，买家有权利再次发起申请。",
            yuqi:"如果您逾期未处理，视作同意买家申请，系统将自动将退款给买家。",
            quantui:"如果订单全额退款，优惠券也将退给买家。",

        },
    //    渠道
        channel:{
            qudaoguanli:"渠道管理",
            tianjiaqudao:'添加渠道',
            bieming:"渠道",
            yonghushu:'用户数',
            riweima:'二维码',
            shengchengshijian:'生成时间',
            qudaobieming:"渠道别名",
            qudaoName:'渠道名称',
            shurumingcheng:'请输入名称',
            chengdauyidaorishizifu:"长度在 1 到 20 个字符",
            qudaoxiangqing:"渠道详情",
            time:"时间:",
            kaishishijian:'开始日期',
            jieshushijiang:'结束日期',
            zhibiao:'指标:',
            qudao:'渠道:',
            shuliang:'数量:',
            benqudiaoxinyonghu:'本渠道新用户',
            diyicigaiqudaoyonghushu:"第一次在该渠道启动小程序并激活的用户数",
            yonghukenengqidtaqudao:"用户可能在其它渠道有过使用记录",
            xinyonghu:"新用户",
            bingqiezhiqiangweishiqitaxd:'并且之前从未在其它渠道使用过',
            qidongcishu:"启动次数",
            gaiqudaoqidingcishu:"该渠道的用户的启动次数",
            qidongyonghu:'启动用户',
            gaiqudaoqidongyonghu:'该渠道的启动用户',
            xinyonghushu:"新用户数",
        },
    //    互动
        communion:{
            pingjiaguanli:"评价管理",
            productName:"商品名称",
            shangpinmingdingdanbianhu:"商品名/订单编号",
            dingdanbianhao:'订单编号',
            yonghuneicheng:"用户昵称",
            nimingpinjia:"匿名评价",
            pingfen:'评分',
            pingrunneirong:"评论内容",
            pingruntupian:'评论图片',
            dianzanshu:"点赞数",
            yitongguo:'已通过',
            yijujue:"已拒绝",
            tongguo:"通过",
            jujue:'拒绝',
            time:"时间:",
            guanjiangci:'关键词',
            kaishishijian:'开始日期',
            jieshushijiang:'结束日期',
            qachaxun:"QA查询",
            tiwenshijian:'提问时间',
            tiwenyonghu:'提问用户',
            tiwenneirong:"问题内容",
            qudingshanchuqa:'确定删除QA？',
            xuhao:"序号",
            biaodanshuju:"表单数据",
            tioajiaoshijian:"提交时间",
            weixinnicheng:"微信昵称",
            chaxunguanli:'查询管理',
            tinajiachaxunxiangmu:'添加查询项目',
            qingshezhichaxuntiaojian:"请设置查询条件",
            shurumingcheng:'请输入名称',
            tinajia:'添加',
            chaxunxiangmu:'查询项目',
            jichuxinxi:'基础信息',
            kaishi:'开启',
            guanbi:'关闭',
            chauxnneirong:'查询内容',
            dianjishangchuang:'点击上传',
            wenjianxinwei:'文档首行为信息项，首行以下均可查询信息；',
            qingshangchuangwenjian:"请上传文件",
            qingzhishaoshuchuyixiang:"请至少输入一项",
            shangchuangwenjiang:"上传文档不可合并单元格",
            chakananli:'查看示例',
            chaxuntiaojian:"查询条件",
            xinxixiang:'信息项',
            sheweichaxuntiaojian:'设为查询条件',
            leixing:'类型',
            xinxi:'信息',
            shoudongshuru:'手动录入',
            name:"名称",
            state:"状态",
            tianjiaxinxi:'添加信息',
            qingshuruguanjianci:'请输入关键词',
            zuojinyizhou:"最近一周",
            zuijinyigeyue:'最近一个月',
            zuijinsangeyue:"最近三个月",
            qudingshanchu:"确定删除？",
            danhangwenben:'单行文本',
            xialaixuan:"下拉选择",
            form:{
                name:'表单名称',
                createTime:'创建时间',
                count:'填写数量',
                commitCount:'提交次数',
                commitCountMultiple:'可多次提交',
                commitCountSingle:'限一次提交',
                showDetail:'查看数据',
                showFormDetail:'查看表单数据',
                noForm:'暂无表单',
                noFormData:'暂无表单数据',
                noFormOptions:'暂无表单项',
                deleteConfirm:'确定删除表单吗?',
                deleteDataConfirm:'确定删除数据吗?',
                source:'提交来源',
                commitTime:'提交时间',
                consumer:'微信',
                content:'表单内容',
                preview:'预览',
                download:'下载',

                edit:{
                    title0:'编辑表单',
                    title1:'新增表单',
                    add:'添加表单项',
                    edit:'编辑表单项',
                    name:'表单项名称',
                    nameError:'请输入表单名称',
                    content:'表单项内容',
                    contentError:'请添加表单项',
                    require:'必填项',
                    sort:'排序',
                    save:'保存',
                    deleteConfirm:'确定删除表单项吗?',
                    titleError:'请输入标题',
                    target:'类型',
                    targetError:'请选择类型',
                }
            }
        },
    //    设置
        setting:{
            styleOne: "样式一",
            styleII: "样式二",
            example: "示例",
            posterExample: "海报示例",
            shareExamples: "分享示例",
            dianpushezhi:"店铺设置",
            shangpinshezhi:"商品设置",
            dingdanshezhi:"订单/交易设置",
            label:{
                gouwuche:"购物车：",
                lianxikefu:"联系客服：",
                dianpudibu:"店铺底部LOGO：",
                shoukongshangpin:"售罄商品：",
                jibenshezhi:"基本设置：",
                shangpinzhanshi:"商品展示页：",
                youfeishezhi:"邮费设置：",
                shouhuodizhi:"退货地址：",
                daifukuandingdan:"待付款订单：",
                zidongquerenshouhuo:"自动确认收货：",
                zidongpinlun:"自动评论时间：",
                tianxiewuliu:"等待客户填写物流信息：",
                shouhoutime:"自动关闭售后时间：",
            },
            form:{
                kaiqi:"开启",
                guanbi:"关闭",
                moren:"默认名称",
                morentupian:"默认",
                zidingyi:"自定义名称",
                tupianzidingyi:"自定义",
                zhanshi:"展示",
                shanpinxiangqin:"商品详情页",
                dingdanxiangqin:"订单详情页",
                bangzhu:"使用帮助",
                chakanshili:"查看示例",
                shangchuantupian:"上传图片",
                chongxinshezhi:"重新设置",
                dianpuanniu:"展示店铺按钮",
                tuijianshangpin:"展示推荐商品",
                shangpinpinjia:"展示商品评价",
                haopinlv:"好评率达到",
                lijigoumai:"展示立即购买按钮",
                xiaoliangxinxi:"展示销量信息",
                xiaoliang:"销量达到",
                shizhanshi:"件时显示",
                chengjiaojilv:"展示成交记录",
                minifenxiang:"显示小程序分享",
                zidingyiqianzhui:"显示自定义前缀",
                fenxiangxiaoguoshili:"查看分享效果示例",
                haibaofenxaing:"显示海报分享",
                haibaoyangshi:"海报样式",
                fenxiangrenxinxi:"海报显示分享人信息",
                haibaoxiaoguo:"查看海报效果示例",
                cuifutankuang:"开启催付弹窗",
                guanbicuifu:"关闭催付弹窗",
                weifukuan:"拍下未付款订单",
                weifukuanshijian:"分钟内未付款，自动取消订单",
                fahuo:"卖家发货后",
                fahuohou:"天，自动确认收货",
                tuihuo:"买家退货，卖家发货后",
                tuihuoshijian:"天，自动确认收货",
                gouwucheyemian:"购物车",
                mianyou:"免邮金额",
                bumianyou:"元，不满免邮金额收费",
                money:"元",
                maijishouhuo:"买家收货后",
                maijishouhuoafer:"天，自动关闭评论",
                tuihuanhuo:"售后填写物流",
                tuihuanhuoafter:"天，自动关闭流程",
                shouhuoname:"请输入收货人姓名",
                shouhuophone:"请输入收货人电话",
                shouhuoaddress:"请输入收货人地址",
                shouhou:"天，自动关闭售后",
            },
            formTips:{
                rule:"4字以内",
                gouwuhche:" 关闭购物车后，商品仅支持单独购买并结算，请谨慎操作。",
                peizhi:"页面配置。",
                lainxikefu:" 开启后，买家可以在你勾选的页面，通过客服入口跟你取得联系。你可以通过用微信公众平台网页版客服工具或者移动端小程序客服小助手进行客服消息回复。",
                zixunguwen:" 默认名称为客服，可自定义，如：咨询顾问。",
                shangchuantishi:" 建议：上传尺寸为330*90 的PNG格式图片，该设置在保存30分钟内生效。",
                shoukongtishi:"展示后，售罄商品会在店铺中展示，并显示「已售罄」标记。",
                kuaisufanhui:"买家点击该按钮快速返回到店铺主页",
                tuijianshangpin:"勾选后商品基本信息下会根据用户行为展示推荐商品，提高转化。",
                goumaianniu:"如果不勾选，商品详情页仅展示加入购物车",
                goumainame:"默认名称为立即购买，可自定义名称，中文最大6个字，英文10个字；自定义后，对全店商品有效，若需设置单个商品，可前往商品管理页单独编辑设置。",
                daifukuan:"开启后，当买家存在待付款订单时，进入店铺首页、商品详情页、微页面时展示付款弹窗。",
                zidongfukuan:"修改后将对新产生的订单立即生效，已产生的订单自动确认收货时间将在以发货时的设置为准。",
                zidongpinjia:"修改后将对新产生的订单立即生效，已产生的订单自动评论时间将在以收货时的设置为准。",
                zidongguanbiliucheng:"修改后将对新产生的订单立即生效，已产生的订单等待客户填写物流信息时间将在以商家同意退换货时的设置为准。",
                zidongguanbishouhou:"修改后将对新产生的订单立即生效，已产生的订单自动关闭售后时间将在以发起审核时的设置为准。",
            }
        }
    }
}
