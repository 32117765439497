<template>
    <div
            class="cz-material-selector-file"
            :class="{'cz-material-selector-file-select': data._checked}"
            @click="checked"
    >
        <div :class="view?'complex':'simple'">
            <div class="progress" :style="{width: progress + '%'}"></div>
            <div :class="view?'complex-thumb':'simple-thumb'" @dblclick="showView">
                <el-image
                        fit="contain"
                        :alt="data.materialName"
                        :src="this.$imageUrl + data.url">
                    <div slot="error" class="image-slot">
                        <i-damage-map size="40" :strokeWidth="1.5"/>
                    </div>
                </el-image>
            </div>
            <div :class="view?'complex-info': 'simple-info'">
                <div :class="view?'complex-info-title':'simple-info-title'">
                    <span>{{data.materialName}}</span>
                </div>
                <!--<div v-terms="view" class="complex-info-buttom">
                   <span>{{getMemory(data.size)}}</span>
                </div>-->
            </div>
            <div v-if="view" class="complex-time">
                <span>{{data.createTime}}</span>
            </div>
            <div v-if="view" class="complex-author">
                <span>{{i18n.t("materialSelector.creatorSystem")}}</span>
            </div>
        </div>
        <el-image-viewer
                :z-index="9999"
                :initial-index="index"
                v-if="showViewer"
                :on-close="closeViewer"
                :url-list="getFiles"
        />
    </div>
</template>

<script>
    // 导入组件
    import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
    import CzVideoViewer from "../../cz-video/cz-video-viewer";
    import MemoryTool from "../../../../utils/MemoryTool.js";
    import CzInputPopconfirm from "./cz-input-popconfirm";
    import CzSelectPopconfirm from "./cz-select-popconfirm";
    import i18n from "../../../../locale";

    export default {
        name: "cz-material-palt-selector-file",
        components: {CzSelectPopconfirm, CzInputPopconfirm, CzVideoViewer, ElImageViewer},
        computed: {
            getFiles() {
                return this.files.map(data => this.$imageUrl + data.url);
            },
        },
        props: {
            index: Number,
            files: Array,
            data: {
                type: Object,
                default: {},
            },
            view: {
                type: Boolean,
                default: false,
            },
            shiftStatus: Boolean
        },
        data() {
            return {
                i18n: i18n,
                showViewer: false,
                progress: 0,
                progressDisplay: false
            }
        },
        methods: {
            showView() {
                if (this.shiftStatus !== true) {
                    this.showViewer = true;
                }
            },
            getMemory(kb) {
                return MemoryTool.getMemory(kb);
            },
            closeViewer() {
                this.showViewer = false;
            },
            checked() {
                this.$emit('checked', !this.data._checked);
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";
    .cz-material-selector-file-select {
        & /deep/ .complex, .simple {
            background-color: fade(@color-primary, 15%);
            color: @color-primary;

            & .complex-thumb, .simple-thumb {
                cursor: pointer;
                position: relative;
                border: 2px solid @color-primary;
                box-shadow: fade(@color-primary, 65%) 0px 0px 4px 0px;

                &:after {
                    display: block;
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: fade(@color-primary, 10%);
                    z-index: 2;
                    border-radius: 3px;
                }
            }

            & .complex-info {
                & .complex-info-buttom {
                    color: @color-primary;
                }
            }
        }
    }

    .cz-material-selector-file {
        user-select: none;
        cursor: pointer;
        font-size: 14px;

        &:not(.cz-material-selector-file-select):hover {
            & /deep/ .complex:hover {
                background-color: fade(@color-primary, 5%);
            }
        }

        &:not(.cz-material-selector-file-select):hover {
            & /deep/ :hover .simple-thumb, .complex-thumb {
                border: solid 2px @color-primary;
                cursor: pointer;
                background-color: white;
            }
        }
    }

    .simple {
        width: 62px;
        height: 95px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
        border-radius: 3px;
        margin-right: 5px;
        float: left;
        padding: 10px 10px 0 10px;

        .simple-thumb {
            border: solid 2px white;
            width: 100%;
            height: 62px;
            position: relative;
            text-align: center;
            overflow: hidden;
            .flex-center;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAIAAABLbSncAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDkzNzZFN0RDODhFMTFFOEExOUJFNkQ0MTNFM0Q2OTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDkzNzZFN0VDODhFMTFFOEExOUJFNkQ0MTNFM0Q2OTYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEOTM3NkU3QkM4OEUxMUU4QTE5QkU2RDQxM0UzRDY5NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEOTM3NkU3Q0M4OEUxMUU4QTE5QkU2RDQxM0UzRDY5NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PktroGEAAAAoSURBVHjaYvz06RMDDPDy8sLZTAw4AOkSjP///4dzPn/+TAs7AAIMAG56COJosoI3AAAAAElFTkSuQmCC");
        }

        .simple-info {
            width: 100%;
            flex: 1;
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: self-end;


            .simple-info-title {
                width: 100%;
                height: 15px;
                text-align: center;
                display: -webkit-box;
                /*! autoprefixer: ignore next */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                word-wrap: break-word;
                overflow: hidden;
                font-size: 12px;
            }
        }
    }

    .complex {
        width: 100%;
        height: 64px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #595959;
        cursor: pointer;
        padding: 10px 10px 10px 0;
        position: relative;
        box-sizing: border-box;

        .progress {
            position: absolute;
            height: 100%;
            background-color: fade(@color-primary, 10%);
            transition: all 1s;
        }

        .complex-thumb {
            border: solid 2px white;
            width: 40px;
            height: 40px;
            margin-left: 10px;
            border-radius: 3px;
            position: relative;
            .flex-center;
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAIAAABLbSncAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RDkzNzZFN0RDODhFMTFFOEExOUJFNkQ0MTNFM0Q2OTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RDkzNzZFN0VDODhFMTFFOEExOUJFNkQ0MTNFM0Q2OTYiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpEOTM3NkU3QkM4OEUxMUU4QTE5QkU2RDQxM0UzRDY5NiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpEOTM3NkU3Q0M4OEUxMUU4QTE5QkU2RDQxM0UzRDY5NiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PktroGEAAAAoSURBVHjaYvz06RMDDPDy8sLZTAw4AOkSjP///4dzPn/+TAs7AAIMAG56COJosoI3AAAAAElFTkSuQmCC");

            & /deep/ .el-image {
                width: 100%;
                height: 100%;
            }
        }

        .complex-info {
            flex: 1;
            height: 100%;
            margin-left: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .complex-info-title {
                width: 100%;
                font-size: 14px;
                display: -webkit-box;
                /*! autoprefixer: ignore next */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                word-wrap: break-word;
                overflow: hidden;
                margin-top: 3px;
            }

            .complex-info-buttom {
                width: 100%;
                flex: 1;
                color: #A0A0A0;
                font-size: 12px;
            }

        }

        .complex-time {
            width: 40%;
            height: 100%;
            margin-left: 10px;
            .flex-center;
            text-align: center;
            font-size: 12px;
        }

        .complex-author {
            width: 15%;
            height: 100%;
            margin-left: 30px;
            text-align: right;
            .flex-center;
            justify-content: flex-end;
            margin-right: 20px;
        }

        &/deep/.image-slot {
            width: 100%;
            height: 100%;
            .flex-center;
        }
    }
</style>
