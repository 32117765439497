<template>
    <div class="qrcode-wrapper">
        <div style="text-align: center;">
            <slot name="title" style="font-size: 18px;font-weight: bold;"></slot>
        </div>
        <div class="qrcode" style="margin-top: 10px; margin-bottom: 10px;">
            <el-image :src="weChatQRCode.src">
                <div slot="placeholder">
                    <div>
                        <i class="el-icon-loading"></i>
                    </div>
                </div>
                <div slot="error" class="image-slot">
                    <div>
                        <i class="el-icon-refresh-left"></i>
                        <span class="pointer"
                              @click="getQrcode">{{i18n.t('login.clicktheRefresh')}}</span>
                    </div>
                </div>
            </el-image>
        </div>
        <div style="text-align: center;">
            <span style="font-size: 12px;">
                <slot name="buttom"></slot>
            </span>
        </div>
        <div class="others">
            <slot name="link"></slot>
        </div>
    </div>
</template>

<script>
    import {getQrcode, pingWechat} from "../../request/api/qrcode.js";
    import CommonLogin from "../../utils/CommonLogin";
    import i18n from "../../locale";

    export default {
        name: "WeChatQRCodeForm",
        props: {
            type: {
                type: String,
                default: 'login',
            },
        },
        data() {
            return {
                i18n: i18n,
                weChatQRCode: {
                    src: "",
                    scene_str: "",
                },
                emailAndMobile: '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])|^(?:0|86|\\+86)?1[3-9]\\d{9}$',
            }
        },
        beforeDestroy() {
            this.weChatQRCode = {
                src: '',
                scene_str: '',
            }
        },
        mounted() {
            this.getQrcode();
        },
        methods: {
            getQrcode() {
                let that = this;
                getQrcode().then((result) => {
                    if (result.code === 200) {
                        that.weChatQRCode = {
                            scene_str: result.data.scene_str,
                            src: result.data.url,
                        };
                        that.ping();
                    } else {
                        this.$notify.error({
                            title: i18n.t('login.error'),
                            message: i18n.t('login.errorGettingQRCode')
                        });
                    }
                })
            },
            ping() {
                let _this = this;
                let data = {
                    'ticket': _this.weChatQRCode.scene_str,
                    'type': _this.type,
                };
                if (!_this.weChatQRCode.scene_str) {
                    return;
                }
                pingWechat(data).then(result => {
                    if (result.code === 200) {
                        _this.login();
                    } else if (result.code === 404) {
                        console.error("ping error !");
                        setTimeout(function () {
                            _this.ping();
                        }, 1000)
                    } else {
                        _this.$notify.error({
                            title: i18n.t('login.error'),
                            message: result.message
                        });
                    }
                })
            },
            login() {
                if (this.weChatQRCode.scene_str && this.weChatQRCode.scene_str.match("^MP_TICKET_\\d{7,}\\w{10,}")) {
                    let data = {
                        'type': 'ticket',
                        'ticket': this.weChatQRCode.scene_str,
                    };
                    let _this = this;
                    CommonLogin.login(data, (result) => {
                        _this.$emit("callback", result);
                    });
                } else {
                    console.log('error submit!!');
                }
            },
        }
    }
</script>

<style scoped lang="less">
    .qrcode-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        width: 400px;
        height: 380px;

        .pointer {
            cursor: pointer;
        }

        .qrcode {
            border-radius: 3px;
            width: 200px;
            height: 200px;
            text-align: center;
            line-height: 200px;
            font-size: 20px;
        }
        .others {
            width: 250px;
        }
    }

</style>
