import {
    getMiniProColors,
    saveHistoryColors,
    setPrimaryColor,
    getCollectColorList,
    deleteCollectColor,
    addCollectColor} from "../../request/api/colors";

const MAX_COUNT = 10;
export default{
    namespaced:true,
    state:{
        //当前主题色
        primary:'',
        //系统固定的主题颜色
        themesColors:[],
        //颜色使用记录,最多10个
        historyColors:[],
        //收藏的颜色
        collectColors:[],
    //    主辅背景色
        styleColor:{},
    },

    mutations:{

        primary(state,primary){
            state.primary = primary;
        },
        themesColors(state,themesColors){
            state.themesColors = themesColors;
        },
        historyColors(state,historyColors){
            state.historyColors = historyColors;
        },
        collectColors(state,collectColors){
            state.collectColors = collectColors;
        },
        styleColor(state,styleColor){
            state.styleColor = styleColor;
        },

        /**
         * 设置主题色,
         * @param state
         * @param color
         */
        setPrimaryColor(state,color){
            state.primary = color;
            this.dispatch('color/setPrimaryColor');
        },

        /**
         * 添加一个历史颜色
         * @param state
         * @param color
         */
        setColor(state,color){
            //已经包含此颜色,移动到第一个
            let idx = state.historyColors.indexOf(color)
            if(idx > -1){
                state.historyColors.splice(idx,1);
            }

            state.historyColors.unshift(color);
            //最多放十个
            if (state.historyColors.length > MAX_COUNT){
                //如果当前颜色被选中，则放到最后且不可以被删除
                let deleteColor = state.historyColors.pop();
                if (deleteColor === state.primary){
                    state.historyColors.pop();
                    state.historyColors.push(deleteColor);
                }
            }

            this.dispatch('color/saveHistoryColors');
        },

        /**
         * 删除收藏颜色
         * @param state
         * @param color
         */
        deleteCollectColor(state,color){
            let idx = state.collectColors.indexOf(color)
            if(idx > -1){
                state.collectColors.splice(idx,1);
                deleteCollectColor({color:color});
            }
        },

        /**
         * 添加收藏颜色
         */
        addCollectColor(state,color){
            state.collectColors.push(color);
            addCollectColor({color:color});
        },

        /**
         * 在适当的时候需要清空state里面的数据，否则会数据混乱
         * @param state
         */
        clear(state){
            state.primary = '';
            state.themesColors = [];
            state.historyColors = [];
            state.collectColors = [];
            state.styleColor = {};
        }
    },

    getters:{
        //计算取值
    },
    actions:{

        /**
         * 获取历史记录的颜色
         * @param state
         * @param
         */
        getMiniProHistoryColors({commit}){
            getMiniProColors({}).then(res=>{
                commit("primary",res.data.primary);
                commit("themesColors",res.data.themeColors);
                commit("historyColors",res.data.historyColors);
            })
        },

        /**
         * 获取收藏的颜色
         * @param state
         * @param
         */
        getCollectColors({commit}){
            getCollectColorList({}).then(res=>{
                commit("collectColors",res.data.map(c=>c.color));
            })
        },

        /**
         * 保存主题色
         * @param state
         * @param
         */
        setPrimaryColor({state}){
            let data = {
                color:state.primary
            };
            setPrimaryColor(data);
        },

        /**
         * 保存常用颜色
         * @param state
         * @param miniProId
         */
        saveHistoryColors({state}){
            let data = {
                colors:state.historyColors.join(",")
            };
            saveHistoryColors(data);
        },
    }
}
