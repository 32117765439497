<template>
    <div class="cz-material-folder" :class="{'checked':checked, 'padding': !make}">
        <template v-if="!make">
            <div class="cz-material-folder-left" @click="selected">
                <div>
                    <i-folder-close :strokeWidth="3"/>
                </div>
                <div style="margin-left: 10px;">
                    {{item.groupName}}
                </div>
            </div>
            <div v-if="item.isDefault !== 1" class="cz-material-folder-centre" @click="make = true">
                <div slot="reference" class="cz-material-folder-edit">
                    <i-edit :strokeWidth="3"/>
                </div>
            </div>
            <div v-if="item.isDefault !== 1" class="cz-material-folder-right">
                <el-popconfirm
                        :confirm-button-text="i18n.t('materialSelector.confirm')"
                        :cancel-button-text="i18n.t('materialSelector.cancel')"
                        :title="i18n.t('materialSelector.confirmDel')"
                        @confirm="delFolder"
                >
                    <div slot="reference" class="cz-material-folder-del">
                        <i-close :strokeWidth="3"/>
                    </div>
                </el-popconfirm>
            </div>
        </template>
        <template v-else>
            <el-input
                    @blur="blurFolder"
                    v-model="item.groupName"
                    :placeholder="i18n.t('materialSelector.pleaseEnterName')"
                    ref="inputText"
            >
                <template slot="prefix">
                    <i-folder-close :strokeWidth="3"/>
                </template>
            </el-input>
        </template>
    </div>
</template>

<script>
    import i18n from "../../../../locale";

    export default {
        name: "cz-material-folder",
        props: {
            item: Object,
            index: Number,
            checked: Boolean,
            isMake: {
                type: Boolean,
                default: false,
            }
        },
        data() {
            return {
                i18n: i18n,
                make: false,
            }
        },
        watch: {
            'make': function (value) {
                let _this = this;
                if (value && value === true) {
                    setTimeout(function () {
                        _this.$refs.inputText.focus();
                        _this.$refs.inputText.select();
                    }, 300)
                }
            },
        },
        methods: {
            selected() {
                this.$emit("selected", this.item);
            },
            blurFolder() {
                this.make = false;
                if (this.item.id) {
                    this.$emit("renameFolder", this.item);
                } else {
                    this.$emit("createFolder", this.item);
                }
            },
            delFolder() {
                this.$emit("delFolder", this.item);
            },
        },
        created() {
            this.make = this.isMake;
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";

    .cz-material-folder {
        height: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 12px;
        cursor: pointer;
        user-select: none;

        .cz-material-folder-left {
            flex: 1;
            height: 32px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
        }

        .cz-material-folder-centre {
            width: 20px;
            height: 32px;
            position: relative;

            .cz-material-folder-edit {
                position: absolute;
                top: 10px;
                right: 5px;
                font-weight: bold;
                display: none;
            }
        }

        .cz-material-folder-right {
            width: 20px;
            height: 32px;
            position: relative;

            .cz-material-folder-del {
                position: absolute;
                top: 10px;
                right: 5px;
                display: none;
            }
        }


        &:not(.checked):hover {
            background: rgba(255, 111, 51, 0.03);

            & /deep/ .cz-material-folder-del {
                display: block;
            }
        }

        &:hover {
            & /deep/ .cz-material-folder-edit {
                display: block;
            }
        }

        & /deep/ .el-input__inner {
            width: 100% !important;
            height: 31px !important;
        }

        & /deep/ .el-input__prefix {
            .flex-center;
            color: #333333;
        }
    }

    .padding {
        padding: 7px;
    }

    .checked {
        background-color: rgba(255, 111, 51, 0.05);
        color: #FF6F33;
    }
</style>
