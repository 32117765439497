<template>
    <div class="no-files-wrapper">
        <div class="no-files" ondragstart="return false">
            <el-image  :src="require('@/assets/edit-imgs/material/blank.png')"/>
            <div>{{title}}</div>
            <div v-if="isUpload" class="upload" @click="handleClick">
                {{i18n.t("material.dragFileHereOr")}}<em>{{i18n.t("material.clickUpload")}}</em>
            </div>
            <div>{{description}}</div>
        </div>
    </div>
</template>

<script>
    import i18n from "../../../locale";
    export default {
        name: "cz-empty",
        props: {
            title: String,
            description: String,
            isUpload: {
                type: Boolean,
                default: true,
            }
        },
        data() {
            return {
                i18n: i18n,
            }
        },
        methods: {
            handleClick() {
                this.$emit("handleClick")
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index.less";

    .no-files-wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .no-files {
            width: 30%;
            height: 40%;
            color: #A0A0A0;
            text-align: center;
            margin-top: 9%;
            user-select: none;

            .upload {
                border: dashed 1px #CCCCCC;
                width: 100%;
                padding: 12px;
                border-radius: 100px;
                font-size: 14px;
                cursor: pointer;
                font-weight: bold;
                color: #595959;
                box-sizing: border-box;

                &:hover {
                    border: dotted 1px #CCCCCC;
                }
            }

            & div {
                margin-bottom: 10px;
            }
        }
    }
</style>
