<template>
    <div class="cz-material-selector-files">
            <CzMaterialSelectorFile
                    v-for="(item,idx) in value.files"
                    :files = "value.files"
                    :index="idx"
                    @checked="onCheckedItem($event,idx)"
                    @delMaterialFile="delMaterialFile"
                    @renameMaterialFile="renameMaterialFile"
                    @moveMaterialFile="moveMaterialFile"
                    :folders="value.folders"
                    :active-folder="value.activeFolder"
                    :shift-status="value.activeFile.shiftStatus"
                    :view="view"
                    :data="item"
            />
            <cz-empty
                    :title="value.activeFolder && value.activeFolder.groupName"
                    :description="i18n.t('materialSelector.ihidDocuments')"
                    @handleClick="clickUpload"
                    v-if="value.files.length <= 0"
            />
    </div>
</template>

<script>
    import CzMaterialSelectorFile from "./cz-material-selector-file";
    import {
        addMaterialFile,
        delMaterialFile,
        delMaterialFiles, getMaterialFilePage,
        getMaterialFiles, getMaterialSearchFiles, moveMaterialFiles, renameMaterialFile
    } from "../../../../request/api/material";
    import CzEmpty from "../../cz-empty/cz-empty";
    import _ from "lodash";
    import i18n from "../../../../locale";
    import store from "../../../../store"

    export default {
        name: "cz-material-selector-files",
        components: {CzEmpty, CzMaterialSelectorFile},
        props: {
            value: Object,
            view: Boolean,
        },
        data() {
            return {
                i18n: i18n,
                pageSize: 15
            }
        },
        watch: {
            "value.files": {
                handler(key, old) {
                    this.watchChecked();
                },
                deep: true,
                immediate: true,
            },
            "value.activeFolder": {
                deep: true,
                handler(key, old) {
                    this.getMaterialFiles();
                }
            },
            "value.search": {
                deep: true,
                handler() {
                    this.getMaterialSearchFiles();
                }
            }
        },
        methods: {
            watchChecked() {
                let filters = this.value.files.filter(
                    item => item["_checked"] && item["_checked"] === true
                );
                this.value.activeFile.checkedNumber = filters.length;
                this.value.activeFile.checked = this.value.files.length !== 0 && this.value.files.length === this.value.activeFile.checkedNumber;
                this.value.activeFile.activeFiles = filters;
            },
            allCheckedChanged(e) {
                let _this = this;
                let allUrl = this.value.files.map(item => item.url);
                if (e && (this.value.allActiveUrls.length + allUrl.length) > _this.value.selectNumber) {
                    _this.$notify({
                        title: _this.i18n.t("materialSelector.hint"),
                        message: _this.i18n.t("materialSelector.maximumOfFilesSelected", {selectNumber: _this.value.selectNumber}),
                        type: 'warning'
                    });
                    this.value.activeFile.checked = false;
                    return;
                }
                _this.value.files.forEach(f => {
                    _this.$set(f, '_checked', e)
                });
                if (e) {
                    this.value.allActiveUrls = _.without(this.value.allActiveUrls, ...allUrl);
                    this.value.allActiveUrls.push(...allUrl);
                } else {
                    this.value.allActiveUrls = _.without(this.value.allActiveUrls, ...allUrl);
                }
            },
            onCheckedItem(checked, idx) {
                let _this = this;
                if (this.value.activeFile.shiftStatus !== true) {
                    this.value.files.forEach(item => _this.$set(item, '_checked', false));
                    this.$set(_this.value.files[idx], '_checked', true);
                    let allUrl = this.value.files.map(item => item.url);
                    if (allUrl && allUrl.length > 0) {
                        this.value.allActiveUrls = _.without(this.value.allActiveUrls, ...allUrl);
                    }
                    if (_this.value.selectNumber === 1) {
                        this.value.allActiveUrls = [];
                    }
                    this.value.allActiveUrls.push(_this.value.files[idx].url);
                } else {
                    if (!checked) {
                        this.value.allActiveUrls = _.without(
                            this.value.allActiveUrls, _this.value.files[idx].url);
                        this.$set(_this.value.files[idx], '_checked', checked);
                        return;
                    }
                    if (checked && this.value.allActiveUrls.length >= _this.value.selectNumber) {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: _this.i18n.t("materialSelector.maximumOfFilesSelected", {selectNumber: _this.value.selectNumber}),
                            type: 'warning'
                        });
                        return;
                    }
                    this.$set(_this.value.files[idx], '_checked', checked);
                    this.value.allActiveUrls.push(_this.value.files[idx].url);
                }
            },
            getChecked() {
                return this.value.files.filter(item => item["_checked"] && item["_checked"] === true)
            },
            getMaterialSearchFiles() {
                let _this = this;
                if (this.value.search && this.value.searchType === '0') {
                    let data = {
                        publicId: _this.getPublicId(),
                        fileType: _this.value.activeFileType.code,
                        fileName: _this.value.search,
                        type: _this.value.activeImageType.code,
                        pageNum: _this.value.pagination.pageNum ? _this.value.pagination.pageNum : 1,
                        pageSize: _this.pageSize,
                    };
                    getMaterialSearchFiles(data).then(result => {
                        if (result.code === 200) {
                            _this.value.pagination = result.data;
                            _this.value.files = result.data.list;
                        } else {
                            console.log(result);
                        }
                    })
                } else {
                    _this.getMaterialFiles();
                }
            },
            getMaterialFiles() {
                let _this = this;
                if (this.value.searchType === '0'){
                    _this.value.search = '';
                }
                if (_this.value.activeFolder)
                    getMaterialFilePage({
                        "groupId": _this.value.activeFolder.id,
                        'pageNum': _this.value.pagination.pageNum,
                        'pageSize': _this.pageSize,
                    }).then((result) => {
                        if (result.code === 200) {
                            _this.value.pagination = result.data;
                            _this.value.files = result.data.list;
                        } else {
                            console.log(result);
                        }
                    })
            },
            addMaterialFile(data) {
                let _this = this;
                addMaterialFile(data).then((result) => {
                    if (result.code === 200) {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'success'
                        });
                        _this.getMaterialFiles();
                    } else {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'warning'
                        });
                    }
                })
            },
            moveMaterialFile(data) {
                let _this = this;
                if (!data.id) {
                    _this.$notify({
                        title: _this.i18n.t("materialSelector.hint"),
                        message: _this.i18n.t("materialSelector.folderNotSelected"),
                        type: 'warning'
                    });
                    return;
                }
                if (_this.value.activeFile.checkedNumber < 0) {
                    _this.$notify({
                        title: _this.i18n.t("materialSelector.hint"),
                        message: _this.i18n.t("materialSelector.folderNotSelected"),
                        type: 'warning'
                    });
                    return;
                }
                let materialIds = this.value.activeFile.activeFiles.map(item => item["id"]).join(",");
                moveMaterialFiles({
                    "groupId": data.id,
                    "materialIds": materialIds
                }).then((result) => {
                    if (result.code === 200) {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'success'
                        });
                        _this.getMaterialFiles();
                    } else {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'warning'
                        });
                    }
                })
            },
            delMaterialFiles() {
                let _this = this;
                //let ids = this.value.activeFile.activeFiles.map(item => item["file"]["id"]).join(",");
                let groupData = _this.groupBy(this.value.activeFile.activeFiles, "groupId");
                groupData.forEach(it => {
                    let groupId = it["groupId"];
                    let ids = it.values.map(item => item["id"]).join(",");
                    if (groupId && ids) {
                        delMaterialFiles({
                            "ids": ids,
                            "groupId": groupId
                        }).then((result) => {
                            console.log(result);
                            if (result.code === 200) {
                                _this.$notify({
                                    title: _this.i18n.t("materialSelector.hint"),
                                    message: result.message,
                                    type: 'success'
                                });
                                _this.getMaterialFiles();
                            } else {
                                _this.$notify({
                                    title: _this.i18n.t("materialSelector.hint"),
                                    message: result.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }
                });
            },
            renameMaterialFile(data) {
                let _this = this;
                if (!(/^[A-Za-z0-9\u4e00-\u9fa5]{1,12}.[a-z0-9]{3,5}$/.test(data.materialName))) {
                    _this.$notify({
                        title: _this.i18n.t("materialSelector.hint"),
                        message: _this.i18n.t("materialSelector.lengthRangesFrom1To20Characters"),
                        type: 'warning'
                    });
                    return;
                }
                renameMaterialFile({
                    "id": data.id,
                    "groupId": _this.value.activeFolder.id,
                    "materialName": data.materialName
                }).then((result) => {
                    console.log(result);
                    if (result.code === 200) {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'success'
                        });
                        _this.$emit("reload");
                    } else {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'warning'
                        });
                    }
                })
            },
            delMaterialFile(data) {
                let _this = this;
                delMaterialFile({
                    "id": data.id,
                    "groupId": data.groupId
                }).then((result) => {
                    console.log(result);
                    if (result.code === 200) {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'success'
                        });
                        _this.getMaterialFiles();
                    } else {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'warning'
                        });
                    }
                })
            },
            clickUpload() {
                this.$emit("clickUpload");
            },
            getPublicId() {
                if (this.value.activeImageType.code === 1) {
                    return store.state.miniPro.miniProId;
                } else if (this.value.activeImageType.code === 0) {
                    return this.$MERCHANT().merchantId
                } else {
                    console.log("没有这个PublicId")
                }
            },
            groupBy(groupData, key) {
                return groupData.reduce((prevValue, currentValue) => {
                    let index = -1;
                    prevValue.find((item, i) => {
                        if (item[key] === currentValue[key]) {
                            index = i;
                            return true;
                        }
                        return false;
                    });
                    if (index > -1) {
                        prevValue[index].values.push(currentValue)
                    } else {
                        prevValue.push({
                            [key]: currentValue[key],
                            values: [currentValue]
                        })
                    }
                    return prevValue;
                }, []);
            },
        },
        mounted() {
            let _this = this;
            this.$bus.$on(this.$BUS_EVENT.PAGINATION_CHANGE,(e) => {
                _this.value.pagination.pageNum = e;
                _this.getMaterialSearchFiles()
            });
        },
        beforeDestroy() {
            this.$bus.$off(this.$BUS_EVENT.PAGINATION_CHANGE);
        },
        created() {

            this.groupBy([{id: 1, name: '1'}, {id: 2, name: '1'}, {id: 3, name: '0'}], 'name');

            let _this = this;
            window.addEventListener('keydown', code => {
                if (code.keyCode === 16 && code.shiftKey) { // 判断是否按住shift键，是就把pin赋值为true
                    _this.value.activeFile.shiftStatus = true
                }
            });
            window.addEventListener('keyup', code => {
                if (code.keyCode === 16) { // 判断是否松开shift键，是就把pin赋值为false
                    _this.value.activeFile.shiftStatus = false
                }
            })
        }
    }
</script>

<style scoped lang="less">

</style>
