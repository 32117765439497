import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex);
import page from './module/page'
import cache from './module/cache'
import color from './module/color'
import miniPro from './module/minipro'
import historyStack from './module/history-stack'
export default new Vuex.Store({
    modules: {
        page,
        cache,
        color,
        miniPro,
        historyStack
    },
    plugins: [createPersistedState({
        paths:[
            'cache.composeList',
            'cache.composeMap',
            'miniPro.miniProId',
            'miniPro.miniProInfo',
            'color.collectColors',
            //指定需要持久化的路径
        ]
    })]
})
