import {request,POST} from '../request.js'

/**
 * 获取微信小程序的历史操作颜色
 * @param data
 * @returns {AxiosPromise}
 */
export const getMiniProColors = (data)=> {
    return POST('/merchant/color/getMiniProColors',data);
};

/**
 * 保存小程序的历史记录颜色
 * @param data
 * @returns {AxiosPromise}
 */
export const saveHistoryColors = (data)=> {
    // return POST('/merchant/color/saveHistoryColors',data);
};

/**
 * 保存小程序的主题色
 * @param data
 * @returns {AxiosPromise}
 */
export const setPrimaryColor = (data)=> {
    return POST('/merchant/color/setPrimaryColor',data);
};

/**
 * 获取收藏的颜色
 * @param data
 * @returns {AxiosPromise}
 */
export const getCollectColorList = (data)=> {
    return POST('/merchant/accountColor/getColorList',data);
};


/**
 * 删除收藏的颜色
 * @param data
 * @returns {AxiosPromise}
 */
export const deleteCollectColor = (data)=> {
    return POST('/merchant/accountColor/deleteColor',data);
};


/**
 * 添加收藏的颜色
 * @param data
 * @returns {AxiosPromise}
 */
export const addCollectColor = (data)=> {
    return POST('/merchant/accountColor/addColor',data);
};


/**
 * 获取系统预设颜色
 * @param data
 * @returns {*}
 */
export const getMiniProDefaultStyleList = (data)=> {
    return POST('/merchant/minipro/default/style/getMiniProDefaultStyleList',data);
};

/**
 * 获取当前小程序的主题颜色
 * @param data
 * @returns {*}
 */
export const getCurrentMiniProColor = (data)=> {
    return POST('/merchant/minipro/style/getCurrentMiniProColor',data);
};


/**
 * 更新当前小程序的主题颜色
 * @param data
 * @returns {*}
 */
export const updateCurrentMiniProColor = (data)=> {
    return POST('/merchant/minipro/style/updateCurrentMiniProColor',data);
};