<template>
    <div class="cz-material-selector-default-table">
        <div class="cz-material-selector-table-header">
            <div class="cz-material-selector-table-header-left">
                <CzMaterialSelectorTableHeaderCheckbox
                        @allChecked="allChecked"
                        v-model="value"
                />
                <div class="cz-material-selector-table-header-left-tool">
                    <el-popover
                            placement="right-end"
                            width="200"
                            trigger="click"
                    >
                        <template>
                            <div class="cz-material-selector-table-header-left-tool-control">
                                <SliderInput v-model="IconProp.size" :title="i18n.t('making.material.icon.size')"
                                             :min="12" :max="50"/>
                                <SliderInput v-model="IconProp.strokeWidth"
                                             :title="i18n.t('making.material.icon.strokeWidth')" :min="1" :max="4"/>
                                <div class="color-style-wrapper">
                                    <div class="title">{{i18n.t('making.material.icon.theme')}}</div>
                                    <div class="button-wrapper">
                                        <div :class="['button',{'active':op.theme === IconProp.theme}]"
                                             v-for="op in ThemeOptions"
                                             :key="op.theme"
                                             @click="clickThemeOptions(op)">{{op.title}}
                                        </div>
                                    </div>
                                </div>
                                <div class="color-row" v-if="IconProp.theme === 'outline'">
                                    <ColorInputPicker class="color-col" v-model="IconProp.fill[IconProp.theme]"
                                                      :title="i18n.t('making.material.icon.colors.outline')"/>
                                </div>
                                <div class="color-row" v-if="IconProp.theme === 'filled'">
                                    <ColorInputPicker class="color-col" v-model="IconProp.fill[IconProp.theme]"
                                                      :title="i18n.t('making.material.icon.colors.filled')"/>
                                </div>
                                <div class="color-row" v-if="IconProp.theme === 'two-tone'">
                                    <ColorInputPicker class="color-col" v-model="IconProp.fill[IconProp.theme][0]"
                                                      :title="i18n.t('making.material.icon.colors.twoToneOutline')"/>
                                    <ColorInputPicker class="color-col" v-model="IconProp.fill[IconProp.theme][1]"
                                                      :title="i18n.t('making.material.icon.colors.twoToneFilled')"/>
                                </div>
                                <div class="color-row" v-if="IconProp.theme === 'multi-color'">
                                    <ColorInputPicker class="color-col" v-model="IconProp.fill[IconProp.theme][0]"
                                                      :title="i18n.t('making.material.icon.colors.multiColorOutlineOuter')"/>
                                    <ColorInputPicker class="color-col" v-model="IconProp.fill[IconProp.theme][1]"
                                                      :title="i18n.t('making.material.icon.colors.multiColorFilledOuter')"/>
                                </div>
                                <div class="color-row" v-if="IconProp.theme === 'multi-color'">
                                    <ColorInputPicker class="color-col" v-model="IconProp.fill[IconProp.theme][2]"
                                                      :title="i18n.t('making.material.icon.colors.multiColorOutlineInner')"/>
                                    <ColorInputPicker class="color-col" v-model="IconProp.fill[IconProp.theme][3]"
                                                      :title="i18n.t('making.material.icon.colors.multiColorFilledInner')"/>
                                </div>
                            </div>
                        </template>
                        <el-button size="mini" slot="reference">设置样式</el-button>
                    </el-popover>
                </div>
            </div>
            <div class="cz-material-selector-table-header-right">
                <CzMaterialSelectorTableHeaderRight v-model="value"/>
            </div>
        </div>
        <div class="cz-material-selector-table-content">
            <CzMaterialPaltSelectorIcons
                    ref="materialPaltSelectorIcons"
                    :icon-prop="IconProp"
                    v-model="value"
                    :view="value.viewSwitch"
            />
        </div>
    </div>
</template>

<script>
    import store from "../../../../store"
    import CzUpload from "../../cz-upload/cz-upload";
    // import ColorInputPicker from "../../../../views/making-page/container/aside-pages/components/color-input-picker"
    import CzMaterialPaltSelectorIcons from "./cz-material-palt-selector-icons";
    import CzMaterialSelectorTableHeaderCheckbox from "./cz-material-selector-table-header-checkbox";
    import CzMaterialSelectorTableHeaderRight from "./cz-material-selector-table-header-right";
    import i18n from "../../../../locale";
    // import SliderInput from "../../../../views/making-page/container/aside-pages/components/slider-input"

    export default {
        name: "cz-material-selector-table-system-icon",
        components: {
            // SliderInput,
            // ColorInputPicker,
            CzMaterialSelectorTableHeaderRight,
            CzMaterialSelectorTableHeaderCheckbox,
            CzMaterialPaltSelectorIcons,
            CzUpload
        },
        props: {
            value: Object
        },
        data() {
            return {
                i18n: i18n,
                IconProp: {
                    size: 24,
                    strokeWidth: 3,
                    theme: 'outline',
                    fill: {
                        'outline': '#333',
                        'filled': '#333',
                        'two-tone': ['#333', '#2F88FF'],
                        'multi-color': ['#333', '#2F88FF', '#FFF', '#43CCF8']
                    },
                },
                ThemeOptions: [
                    {
                        theme: 'outline',
                        title: i18n.t('making.material.icon.themeOptions.outline'),
                        handler: function (that) {
                            let styleColor = store.state.color.styleColor;
                            if (styleColor.principalColor) {
                                that.IconProp.fill['outline'] = styleColor.principalColor;
                            }
                        }
                    },
                    {
                        theme: 'filled',
                        title: i18n.t('making.material.icon.themeOptions.filled'),
                        handler: function (that) {
                            let styleColor = store.state.color.styleColor;
                            if (styleColor.principalColor) {
                                that.IconProp.fill['filled'] = styleColor.principalColor;
                            }
                        }
                    },
                    {
                        theme: 'two-tone',
                        title: i18n.t('making.material.icon.themeOptions.twoTone'),
                        handler: function (that) {
                            let styleColor = store.state.color.styleColor;
                            if (styleColor.principalColor && styleColor.complementaryColor) {
                                that.IconProp.fill['two-tone'] = [styleColor.principalColor, styleColor.complementaryColor];
                            }
                        }
                    },
                    {
                        theme: 'multi-color',
                        title: i18n.t('making.material.icon.themeOptions.multiColor'),
                        handler: function (that) {
                            let styleColor = store.state.color.styleColor;
                            if (styleColor.principalColor && styleColor.complementaryColor && styleColor.backgroundColor) {
                                that.IconProp.fill['multi-color'] = ['#333', styleColor.principalColor, styleColor.backgroundColor, styleColor.complementaryColor];
                            }
                        }
                    },
                ],
            }
        },
        mounted() {
            this.ThemeOptions[0].handler(this)
        },
        methods: {
            allChecked(checked) {
                this.$refs.materialPaltSelectorIcons.allCheckedChanged(checked);
            },
            clickThemeOptions(op) {
                op.handler(this);
                this.IconProp.theme = op.theme
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";

    .cz-material-selector-default-table {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .pointer {
            cursor: pointer;
        }

        .cz-material-selector-table-header {
            width: 100%;
            height: 52px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .cz-material-selector-table-header-left {
                flex: 1;
                height: 100%;
                box-sizing: border-box;
                padding-left: 17px;
                color: #415058;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;

                .cz-material-selector-table-header-left-checkbox {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    & div {
                        .flex-center;
                    }

                    & div:nth-child(1) {
                        width: 80px;
                        margin-left: -20px;
                    }

                    & div:nth-child(2) {
                        width: 10px;
                    }

                    & div:nth-child(3) {
                        width: 80px;
                    }
                }

                .cz-material-selector-table-header-left-tool {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                }

            }

            .cz-material-selector-table-header-right {
                width: 120px;
                height: 100%;
                box-sizing: border-box;
                padding-right: 17px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }

        .cz-material-selector-table-content {
            width: 100%;
            flex: 1;
            box-sizing: border-box;
            overflow: scroll;
            .hidden-scroll-bar;
        }
    }
</style>
<style lang="less">
    @import "../../../../assets/style/index.less";

    .cz-material-selector-table-header-left-tool-control {
        width: 196px;
        margin-top: 12px;

        .color-row {
            display: flex;
            margin-top: 12px;

            .color-col {
                margin-right: 4px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }

        .color-style-wrapper {
            display: flex;
            flex-direction: row;
            justify-items: flex-start;
            margin: 14px 0;

            .title {
                color: #999;
                width: 50px;
                flex-shrink: 0;
                font-size: 12px;
                margin-right: 10px;
            }

            .button-wrapper {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .button {
                    width: 50px;
                    height: 23px;
                    .flex-center;
                    cursor: pointer;
                    border: 1px solid #dcdfe6;
                    border-radius: @border-radius-sm;
                    transition: .1s;
                    margin-bottom: 6px;
                    margin-right: 16px;

                    &:hover {
                        color: @color-primary;
                        border-color: #ffd4c2;
                        background: #fff1eb;
                    }

                    &:active {
                        border-color: @color-primary;
                    }

                    &.active {
                        color: @color-primary;
                        border-color: #ffd4c2;
                        background: #fff1eb;
                    }
                }
            }
        }
    }
</style>
