import Modal from './index.vue'
const obj = {};

obj.install = (Vue) => {

  const Constructor = Vue.extend(Modal);

  const modal = new Constructor();

  Vue.prototype.$tokenExpireModal = {
      show(options){
          document.body.appendChild(modal.$mount().$el);
      }
  }
};

export default obj
