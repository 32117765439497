import {getCurrentUser} from "../request/api/merchant";
import {login} from "../request/api/account";
import Vue from 'vue'
import router from '../router'
import Cache from "../utils/Cache"

let vue = new Vue();
let accessToken = "access_token";
let refreshToken = "refresh_token";
let currentUser = "currentUser";
export default {
    login: function(data, func){
        let _this = this;
        login(data).then((result) =>{
            if (result.code === 200){
                let access_token = result.data.access_token;
                let refresh_token = result.data.refresh_token;
                Cache.cache.set(accessToken,access_token);
                Cache.cache.set(refreshToken,refresh_token);
                _this.setCurrentUser(func);
            }else {
                vue.$notify({
                    title: '提示',
                    message: result.message,
                    type: 'warning',
                });
            }
        })
    },
    logout: function(){
        this.clear();
        router.replace("/login");
    },
    clear: function () {
        Cache.cache.clear()
    },
    setCurrentUser: function (func) {
        getCurrentUser().then((result) =>{
           if (result && result.code === 200){
               vue.$USER(result.data);
               func(true)
           }else {
               func(false)
           }
        })
    },
    getCurrentUser: function () {
        return vue.$USER();
    },
    getToken: function(){
        return Cache.cache.get(accessToken);
    },
}
