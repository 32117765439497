<template>
    <div class="cz-material-folders">
        <CzMaterialPLatFolder
                v-for="item in value.folders"
                @selected="selected(item)"
                v-if="!(value.searchType === '1' && item.groupName && !item.groupName.match(value.search))"
                :item="item"
                :checked="value.activeFolder && value.activeFolder.groupId === item.groupId"
        />
    </div>
</template>

<script>
    import CzMaterialPLatFolder from "./cz-material-plat-folder";
    import {getGroupByparentIdsStr} from "../../../../request/api/material";

    export default {
        name: "cz-material-plat-folders",
        components: {CzMaterialPLatFolder},
        props: {
            value: Object,
        },
        watch: {
            "value.folders": {
                deep: true,
                handler(item) {
                    //默认选中第一个
                    this.selected(item[0])
                }
            },
            "value.activeImageType": {
                immediate: true,
                deep: true,
                handler: function () {
                    this.getFolder();
                }
            },
            "value.activeFileType": {
                immediate: true,
                deep: true,
                handler: function () {
                    this.getFolder();
                }
            },
        },
        methods: {
            selected(item) {
                this.$set(this.value, "pagination", {pageNum: 1});
                this.value.activeFolder = item;
            },
            getFolder() {
                let _this = this;
                let data = {
                    "parentIdsStr": this.value.activeImageType.parentIdsStr,
                };
                let exclude = this.value.activeImageType.exclude;
                getGroupByparentIdsStr(data).then(result => {
                    if (result.code === 200){
                        _this.value.folders = result.data.filter(item => {
                            if (exclude && exclude.length > 0) {
                                return exclude.indexOf(item.groupId) === -1
                            } else {
                                return true;
                            }
                        });
                    }else {
                        console.log("getGroupByparentIdsStr",result)
                    }
                })
            },
        },
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";
</style>
