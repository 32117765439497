<template>
    <div class="cz-material-selector-table-header-dropdown">
        <el-dropdown @command="sortFile" class="pointer">
                  <span class="el-dropdown-link">
                     {{dropdownMenu.label?dropdownMenu.label: i18n.t("materialSelector.filteren")}}<i
                          class="el-icon-arrow-down el-icon--right"></i>
                  </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in options" :command="item">{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
    import i18n from "../../../../locale";

    export default {
        name: "cz-material-selector-table-header-dropdown",
        props: {
            value: Object
        },
        data() {
            return {
                i18n: i18n,
                dropdownMenu: "",
                options: [
                    {
                        value: 'materialName',
                        label: i18n.t("materialSelector.fileName")
                    },
                    {
                        value: 'size',
                        label: i18n.t("materialSelector.fileSize")
                    },
                    {
                        value: 'createTime',
                        label: i18n.t("materialSelector.createTime"),
                    }
                ],
            }
        },
        methods: {
            /*排序相关逻辑*/
            sortFile(item) {
                this.dropdownMenu = item;
                let value = item.value;
                if (value === 'size') {
                    this.value.files.sort((a, b) => {
                        return +b.size - +a.size;
                    })
                } else {
                    this.value.files.sort(this.compare(value))
                }
            },
            compare(key) {
                return function (a, b) {
                    return a[key] < b[key] ? 1 : -1
                }
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";
    .cz-material-selector-table-header-dropdown {
        width: 100px;

        .el-dropdown-link {
            .flex-center;
        }
    }
</style>
