<template>
    <div>
        <el-form onsubmit="return false" :model="usernamePasswordForm" :rules="rules" ref="usernamePasswordForm">
            <el-form-item>
                <slot name="title"></slot>
            </el-form-item>
            <el-form-item prop="account">
                <el-input
                        class="input"
                        v-model="usernamePasswordForm.account"
                        :placeholder="i18n.t('login.pleaseInputPhoneNumberOrEmail')"
                />
            </el-form-item>
            <el-form-item prop="password">
                <el-row>
                    <el-col>
                        <el-input
                                class="input"
                                v-model="usernamePasswordForm.password"
                                :placeholder="i18n.t('login.enterPin')"
                                :show-password="true"
                        />
                    </el-col>
                </el-row>
            </el-form-item>
            <div class="forget-password-wrapper">
                <slot name="forgetPassword"></slot>
            </div>
            <el-form-item>
                <el-button @click="login" type="primary" class="login">{{i18n.t('login.text')}}</el-button>
            </el-form-item>
            <el-form-item style="text-align: center;">
                <slot name="bottom"></slot>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import rules from "../../views/login/js/rules"
    import ThirdPartyLogin from "./ThirdPartyLogin.vue";
    import CommonLogin from "../../utils/CommonLogin";
    import Language from "./Language.vue";
    import i18n from "../../locale";

    export default {
        name: "UsernamePasswordForm",
        components: {Language, ThirdPartyLogin},
        data(){
            return {
                i18n:i18n,
                usernamePasswordForm: {
                    account: "",
                    password: ""
                },
                rules: rules.rules,
            }
        },
        mounted() {
            let _this = this;
            document.onkeydown = function(e) {
                if (e.key === "Enter" || e.code === "Enter") {
                    if (_this.usernamePasswordForm.account && _this.usernamePasswordForm.password){
                        _this.login()
                    }
                }
            }
        },
        beforeDestroy() {
            document.onkeydown = undefined;
        },
        methods: {
            login(){
                let _this = this;
                this.$refs["usernamePasswordForm"].validate((val) =>{
                    if (val){
                        let data = {
                            // 'type':'simple',
                            'account':this.usernamePasswordForm.account,
                            'password':this.usernamePasswordForm.password,
                        };
                        CommonLogin.login(data, (result) =>{
                            _this.$emit("callback", result);
                        });
                    }else {
                        console.log('error submit!!');
                        return false;
                    }
                })
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index.less";
    @input-width: 308px;
    .wrapper {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top {
            width: 100%;
            height: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding-right: 50px;
            padding-top: 7px
        }

        .bottom {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: stretch;

            .forget-password-wrapper {
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }
    }
    .input {
        width: 308px;
        height: 40px;
    }
    .login {
        width: @input-width;
    }
    .pointer {
        cursor: pointer;
    }
    .text-color {
        .pointer;
        color:  @color-primary;
    }
    .forget-password{
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        color: #666666;
    }
</style>
