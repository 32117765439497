import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./router.js";
import Cache from "../utils/Cache";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
//捕捉路由跳转错误
VueRouter.prototype.push = function (location) {
    return originalPush.call(this,location).catch(err=>console.log("路由跳转错误"));
};
VueRouter.prototype.replace = function (location) {
    return originalReplace.call(this,location).catch(err=>console.log("路由跳转错误"));
};

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes:routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
     }
});

router.beforeEach((to, from, next) => {
    const token = Cache.cache.get('access_token');
    // console.log("token:", token)
    // console.log("from/to:", from.path, "=>", to.path)
    if (to.path.startsWith("/upload")){
        next();
        return;
    }
    if (from.path.startsWith("/enterpriseInformation")) {
        let vue = new Vue();
        if ((vue.$MERCHANT() && vue.$MERCHANT().merchantId) || to.path.startsWith("/createEnterpriseInformation")) {
            next()
        } else {
            vue.$MESSAGE({code: 500, message: "请先选择要进入的企业或者创建自己的企业"});
            next('/enterpriseInformation')
        }
    } else if (token || to.path.startsWith("/login") || to.path.startsWith("/agreement")) {
        next();
    } else {
        next('/')
    }
});


export default router
