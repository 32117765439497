<template>
    <div class="cz-material-selector-table-search">
        <div class="cz-material-selector-table-search-left">
            <span style="color: #FAAD14; margin-right: 10px;">
                <i-attention size="16"/>
            </span>
            {{i18n.t("materialSelector.findKeywordRelatedResultsInMyUpload",
            {number: value.files.length, key: value.search, target: value.activeImageType.rootTitle})}}
        </div>
        <div @click="value.search = ''" class="cz-material-selector-table-search-right">
            {{i18n.t("materialSelector.clear")}}
        </div>
    </div>
</template>

<script>
    import i18n from "../../../../locale";

    export default {
        name: "cz-material-selector-table-search",
        props: {
            value: Object
        },
        data(){
            return {
                i18n: i18n,
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";

    .cz-material-selector-table-search {
        width: 100%;
        height: 40px;
        border-radius: 2px;
        background-color: #FFFBE6;
        border: 1px solid #FFE58F;
        margin-top: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 17px;


        .cz-material-selector-table-search-left {
            .flex-center;
        }

        .cz-material-selector-table-search-right {
            cursor: pointer;
            color: #1890FF;
        }
    }
</style>
