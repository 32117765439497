<template>
    <div>
        <el-form :model="securityCodeForm" ref="securityCodeForm" :rules="rules">
            <el-form-item>
                <slot name="title"></slot>
            </el-form-item>
            <el-form-item prop="account">
                <el-input class="input" v-model="securityCodeForm.account" :placeholder="i18n.t('login.pleaseInputPhoneNumberOrEmail')" round/>
            </el-form-item>
            <el-form-item prop="code">
                <el-row>
                    <el-input class="input2" v-model="securityCodeForm.code" :placeholder="i18n.t('login.pleaseEnterVerificationCode')" round>
                        <template slot="suffix">
                            <div class="pointer"
                                 v-if="securityCodeTimeOut === 0"
                                 @click="checkUser"
                            >
                                {{i18n.t('login.sendVerificationCode')}}
                            </div>
                            <div class="pointer" style="color: #CCCCCC;" v-if="securityCodeTimeOut !== 0">
                                {{securityCodeTimeOut}}s{{i18n.t('login.retry')}}
                            </div>
                        </template>
                    </el-input>
                </el-row>
            </el-form-item>
            <div class="forget-password-wrapper">
                <slot name="forgetPassword"></slot>
            </div>
            <el-form-item>
                <el-button @click="login" type="primary" class="login">{{butContent ? butContent : i18n.t('login.text')}}</el-button>
            </el-form-item>
            <el-form-item style="text-align: center;">
                <slot name="bottom"></slot>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    import {commitMsg} from "../../request/api/smsc.js";
    import rules from "../../views/login/js/rules";
    import ThirdPartyLogin from "./ThirdPartyLogin.vue";
    import CommonLogin from "../../utils/CommonLogin";
    import Language from "./Language.vue";
    import {checkUser} from "../../request/api/merchant.js";
    import i18n from "../../locale";

    export default {
        name: "SecurityCodeForm",
        components: {Language, ThirdPartyLogin},
        props :{
            butContent: String,
        },
        data(){
            return {
                i18n: i18n,
                rules: rules.rules,
                securityCodeTimeOut: 0,
                securityCodeForm: {
                    account:'',
                    code: '',
                },
                emailAndMobile: '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])|^(?:0|86|\\+86)?1[3-9]\\d{9}$',
            }
        },
        mounted() {
            let _this = this;
            document.onkeydown = function(e) {
                if (e.key === "Enter" || e.code === "Enter") {
                    if (_this.securityCodeForm.account && _this.securityCodeForm.code){
                        _this.login()
                    }
                }
            }
        },
        beforeDestroy() {
            document.onkeydown = undefined;
        },
        methods: {
            checkUser() {
                let account = this.securityCodeForm.account;
                let _this = this;
                if (account && account.match(this.emailAndMobile)) {
                    let data = {
                        'account': account,
                        'exist': false,
                    };
                    checkUser(data).then((result) => {
                        if (result.code === 200){
                            _this.commitMsg()
                        }else {
                            _this.$notify({
                                title: i18n.t('login.hint'),
                                message: result.message,
                                type: 'warning'
                            });
                        }
                    })
                }
            },
            commitMsg() {
                let data = {
                    'toFrom': this.securityCodeForm.account,
                    'typeNum': 0,
                };
                let _this = this;
                commitMsg(data).then(result => {
                    if (result.code === 200){
                        _this.securityCodeTimeOut = 60;
                        let interval = setInterval(() => {
                            if (_this.securityCodeTimeOut > 0){
                                _this.securityCodeTimeOut -= 1;
                            }else {
                                clearInterval(interval)
                            }
                        },1100);
                        _this.$notify({
                            title: i18n.t('login.succeed'),
                            message: i18n.t('login.captchaCodeSentSuccessfully'),
                            type: 'success'
                        });
                    }else {
                        _this.$notify.error({
                            title: i18n.t('login.error'),
                            message: result.message
                        });
                    }
                })
            },
            login(){
                this.$refs["securityCodeForm"].validate((val) => {
                    if (val) {
                        let data = {
                            'type':'joint',
                            'account':this.securityCodeForm.account,
                            'code':this.securityCodeForm.code,
                        };
                        let _this = this;
                        CommonLogin.login(data, (result) => {
                            _this.$emit("callback", result);
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index.less";
    @input-width: 308px;
    .input {
        width: 308px;
        height: 40px;
    }
    .login {
        width: @input-width;
        text-align: center;
        cursor: pointer;
        color: white;
        background-color: @color-primary;
    }
    .pointer {
        cursor: pointer;
        color: @color-primary;
        margin-right: 16px;
    }
    .forget-password-wrapper {
        padding-top: 14px;
        padding-bottom: 14px;
    }
</style>
