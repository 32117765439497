
export default {
    HEAD_ASIDE_TRIGGER:'HEAD_ASIDE_TRIGGER',    //制作页面顶部的切换开关状态aside事件
    HEAD_ASIDE_OPEN:'HEAD_ASIDE_OPEN',    //制作页面顶部的开启aside事件 arg:开启第n个菜单0：页面，依次下推
    HEAD_UNDO_STEP:'HEAD_UNDO_STEP',    //制作页面顶部的回退事件
    HEAD_REDO_STEP:'HEAD_REDO_STEP',    //制作页面顶部的重做事件
    HEAD_SHOW_GUIDE:'HEAD_SHOW_GUIDE',    //展示新手引导页 arg:Boolean 关闭后是否展开page
    HEAD_SHOW_PREVIEW:'HEAD_SHOW_PREVIEW',    //开始预览模式,无参数

    PROPERTY_ALIGN:"PROPERTY_ALIGN", //属性区域的排列方式事件
    PROPERTY_ALIGN_OPTIONS:{        //排列方式的参数
        HORIZONTAL_SPACE:'PROPERTY_ALIGN_HORIZONTAL_SPACE',  //水平等间距
        HORIZONTAL_CENTER:'PROPERTY_ALIGN_HORIZONTAL_CENTER',  //水平居中
        VERTICAL_SPACE:'PROPERTY_ALIGN_VERTICAL_SPACE',  //垂直等间距
        VERTICAL_CENTER:'PROPERTY_ALIGN_VERTICAL_CENTER',  //垂直居中
        LEFT:'PROPERTY_ALIGN_LEFT',  //
        RIGHT:'PROPERTY_ALIGN_RIGHT',  //右对齐左对齐
        TOP:'PROPERTY_ALIGN_TOP',  //上对齐
        BOTTOM:'PROPERTY_ALIGN_BOTTOM',  //下对齐
        MOVE_UP:'PROPERTY_ALIGN_MOVE_UP',  //上移一层
        MOVE_DOWN:'PROPERTY_ALIGN_MOVE_DOWN',  //下移一层
        MOVE_TOP:'PROPERTY_ALIGN_MOVE_TOP',  //置顶
        MOVE_BOTTOM:'PROPERTY_ALIGN_MOVE_BOTTOM',  //置底
    },

    PAGE_ACTIVE:'PAGE_ACTIVE', //点击某个微页面发出的通知,一般是phone区域更新到此页面
    PAGE_SAVE_BEGIN:'PAGE_SAVE_BEGIN', //通知phone区域保存页面到后台,arg:boolean 是否隐藏消息提示
    PAGE_SAVE_END:'PAGE_SAVE_END', //页面保存结束
    PAGE_SAVE_HISTORY:'PAGE_SAVE_HISTORY', //发送参数告知要保持历史记录,方便撤销、重做，无参数
    PAGE_SCALE:'PAGE_SCALE',//微页面画板缩放通话，arg 1放大一级,-1缩小一级
    PAGE_CHANGE:'PAGE_CHANGE',//切换微页面  args:PAGE_CHANGE_OPTIONS
    PAGE_CHANGE_OPTIONS:{//切换微页面 参数
        PAGEUP:"PAGEUP",//上一页
        PAGEDOWN:"PAGEDOWN",//下一页
        HOME:"HOME",//第一页
        END:"END",//最后一页
    },

    PROPERTY_CHANGE:'PROPERTY_CHANGE',//点击组件或者页面，切换编辑状态
    PROPERTY_WIDTH_CHANGE:'PROPERTY_WIDTH_CHANGE',//切换属性区域的整体宽度

    SWIPER_ITEM_CLICK:"SWIPER_ITEM_CLICK",//args:下标, 点击轮播图绘制区域的某个图片后，发出的通知，让编辑区域激活此图片

    MERCHANT_CHANGE:'MERCHANT_CHANGE',// $MERCHANT()内属性发生变化
    QUIT_MERCHANT:'QUIT_MERCHANT',// 退出企业
    MANAGE_TAG_CHANGE:'MANAGE_TAG_CHANGE',// 点击右上角头像切换管理页面Tag选项卡

    PAGINATION_CHANGE: 'PAGINATION_CHANGE' //分页变化
}
