<template>
    <div class="cz-material-selector-default-table">
        <div class="cz-material-selector-table-header">
            <div class="cz-material-selector-table-header-left">
                <CzMaterialSelectorTableHeaderCheckbox
                        @allChecked="allChecked"
                        v-model="value"
                />
                <div class="cz-material-selector-table-header-left-tool">
                    <cz-select-popconfirm @confirm="moveMaterialFile"
                                          :active-folder="value.activeFolder"
                                          :folders="value.folders">
                        <template slot="reference">
                            <div>
                                <i-folder-conversion-one :strokeWidth="3"/>
                                <span style="text-indent: 5px;">{{i18n.t("materialSelector.moveTo")}}</span>
                            </div>
                        </template>
                    </cz-select-popconfirm>
                    <div>
                        <el-popconfirm
                                :title="i18n.t('materialSelector.areDeleteSelectedContent')"
                                :confirm-button-text='i18n.t("materialSelector.confirm")'
                                :cancel-button-text='i18n.t("materialSelector.cancel")'
                                @confirm="delMaterialFiles"
                        >
                            <template slot="reference">
                                <div class="flex-center">
                                    <i-delete :strokeWidth="3"/>
                                    <span style="text-indent: 5px;">{{i18n.t("materialSelector.del")}}</span>
                                </div>
                            </template>
                        </el-popconfirm>

                    </div>
                    <div v-if="value.activeFile.checkedNumber > 0">|</div>
                    <div class="reset" v-if="value.activeFile.checkedNumber > 0" @click="allChecked(false)">
                        {{i18n.t("materialSelector.cancel")}}
                    </div>
                </div>
            </div>
            <CzMaterialSelectorTableHeaderRight v-model="value"/>
        </div>
        <div class="cz-material-selector-table-content">
            <cz-upload class="cz-material-selector-table-content"
                       ref="upload"
                       @change="uploadFile"
                       :accept="value.activeFileType.accept"
                       :size="value.activeFileType.size"
            >
                <CzMaterialSelectorFiles
                        ref="materialSelectorFiles"
                        v-model="value"
                        :view="value.viewSwitch"
                />
            </cz-upload>
        </div>
    </div>
</template>

<script>
    import i18n from "../../../../locale";
    import store from "../../../../store";
    import CzMaterialSelectorFiles from "./cz-material-selector-files";
    import CzUpload from "../../cz-upload/cz-upload";
    import CzMaterialSelectorTableHeaderCheckbox from "./cz-material-selector-table-header-checkbox";
    import CzMaterialSelectorTableHeaderRight from "./cz-material-selector-table-header-right";
    import CzSelectPopconfirm from "./cz-select-popconfirm";

    export default {
        name: "cz-material-selector-table-default",
        components: {
            CzSelectPopconfirm,
            CzMaterialSelectorTableHeaderRight,
            CzMaterialSelectorTableHeaderCheckbox, CzUpload, CzMaterialSelectorFiles
        },
        props: {
            value: Object
        },
        data() {
            return {
                i18n: i18n,
            }
        },
        methods: {
            allChecked(checked) {
                this.$refs.materialSelectorFiles.allCheckedChanged(checked);
            },
            moveMaterialFile(data) {
                this.$refs.materialSelectorFiles.moveMaterialFile(data);
            },
            delMaterialFiles() {
                this.$refs.materialSelectorFiles.delMaterialFiles();
            },
            clickUpload() {
                this.$refs.upload.handleClick();
            },
            uploadFile(result) {
                let _this = this;
                if (result.response.code === 200) {
                    let data = result.response.data;
                    this.$refs.materialSelectorFiles.addMaterialFile({
                        "materialName": data.name,
                        "url": data.url,
                        "groupId": _this.value.activeFolder.id,
                        "publicId": _this.getPublicId(),
                        "size": data.size,
                        "suffix": data.type,
                        "measure": data["measure"],
                        "coverUrl": data.url + _this.getUrlSuffix(data.type),
                    });
                }//
            },
            getUrlSuffix(type) {
                if (type === "svg" || type === "SVG") {
                    return '';
                }
                if (this.value.activeFileType.code === 0) {
                    return "?x-oss-process=image/resize,h_100,m_lfit";
                } else if (this.value.activeFileType.code === 1) {
                    return "?x-oss-process=video/snapshot,t_10000,m_fast,h_100,w_200";
                } else {
                    console.log("getUrlSuffix", "没有在这个后缀")
                }
            },
            getPublicId() {
                if (this.value.activeImageType.code === 1) {
                    return store.state.miniPro.miniProId;
                } else if (this.value.activeImageType.code === 0) {
                    return this.$MERCHANT().merchantId
                } else {
                    console.log("没有这个PublicId")
                }
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";

    .cz-material-selector-default-table {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .pointer {
            cursor: pointer;
        }

        .cz-material-selector-table-header {
            width: 100%;
            height: 52px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .cz-material-selector-table-header-left {
                flex: 1;
                height: 100%;
                box-sizing: border-box;
                padding-left: 17px;
                color: #415058;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;

                .cz-material-selector-table-header-left-tool {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    & div {
                        .flex-center;
                        cursor: pointer;
                    }

                    & div:nth-child(1) {
                        width: 80px;
                    }

                    & div:nth-child(2) {
                        width: 50px;
                    }

                    & div:nth-child(3) {
                        width: 50px;
                        cursor: initial;
                    }

                    & div:nth-child(4) {
                        color: @color-primary;
                    }
                }

            }
        }

        .cz-material-selector-table-content {
            width: 100%;
            flex: 1;
            box-sizing: border-box;
            overflow: scroll;
            .hidden-scroll-bar;
        }
    }
</style>
