import {POST,UPLOAD} from '../request.js'





/**
 * 获取手机上传的token
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMaterialToken = (data)=> {
    return POST('/material/mobile/material/getMaterialToken',data);
};

/**
 * 获取手机上传的token状态
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMaterialTokenState = (data)=> {
    return POST('/material/mobile/material/getMaterialTokenState',data);
};



/**
 * 检查商户默认文件夹
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const checkMerchantDefaultFolder = (data)=> {
    return POST('/material/group/checkMerchantDefaultFolder',data);
};


/**
 * 商户获取商户内存使用情况
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const geMerchantMemoryUsage = (data)=> {
    return POST('/material/group/geMerchantMemoryUsage', data);
};


/**
 * 创建商户下文件夹
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const createFolder = (data)=> {
    return POST('/material/group/createFolder',data);
};

/**
 * 获取商户下所有的文件夹
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getFolder = (data)=> {
    return POST('/material/group/getFolder',data);
};

/**
 * 重命名商户下某个文件夹
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const renameFolder = (data)=> {
    return POST('/material/group/renameFolder',data);
};


/**
 * 删除商户下某个文件夹
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const delFolder = (data)=> {
    return POST('/material/group/delFolder',data);
};



/**
 * 条件查询素材列表
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMaterialsByParams = (data)=> {
    return POST('/material/getMaterialsByParams',data);
};


/**
 * 获取文件具体信息
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMaterialFileInfo = (data)=> {
    return POST('/material/getFileInfo',data);
};


/**
 * 获取所有文件
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMaterialFiles = (data)=> {
    return POST('/material/getFiles',data);
};

/**
 * 获取所有文件有分页
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMaterialFilePage = (data)=> {
    return POST('/material/getFilePage',data);
};

/**
 * 模糊搜索获取所有文件
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMaterialSearchFiles = (data)=> {
    return POST('/material/searchFiles',data);
};

/**
 * 根据url查询文件基本信息
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMaterialFileInfoByUrl = (data)=> {
    return POST('/material/searchFileInfoByUrl',data);
};


/**
 * 添加素材
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const addMaterialFile = (data)=> {
    return POST('/material/addFile',data);
};


/**
 * 重命名素材
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const renameMaterialFile = (data)=> {
    return POST('/material/renameFile',data);
};


/**
 * 删除素材
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const delMaterialFile = (data)=> {
    return POST('/material/delFile',data);
};

/**
 * 批量删除素材
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const delMaterialFiles = (data)=> {
    return POST('/material/delFiles',data);
};

/**
 * 移动素材
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const moveMaterialFile = (data)=> {
    return POST('/material/moveFile',data);
};

/**
 * 批量移动素材
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const moveMaterialFiles = (data)=> {
    return POST('/material/moveFiles',data);
};

/**
 * 上传图片文件
 * @param data
 * @param onProgress 上传进度
 * @returns {*}
 * @author Mr.k
 */
export const upload = (formData,onProgress)=> {
    return UPLOAD('/material/upload',formData,onProgress);
};


/**
 * 平台下：根据分组ID获取分组下的图片
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const getTblMaterialPlat = (data)=> {
    return POST('/material/platform/getTblMaterialPlat',data);
};

/**
 * 平台下：根据名称模糊搜索的图片
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const searchFilesByName = (data)=> {
    return POST('/material/platform/searchFilesByName',data);
};

/**
 * 模糊搜索某些素材
 * @param data
 * @returns {AxiosPromise}
 * @author Mr.k
 */
export const searchMaterial = (data)=> {
    return POST('/material/platform/searchMaterial',data);
};

/**
 * 平台下：根据分组ID获取分组分组
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const getGroupByParentId = (data)=> {
    return POST('/material/platform/getGroupByParentId',data);
};

/**
 * 平台下：根据分组ID获取分组分组
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getGroupByparentIdsStr = (data)=> {
    return POST('/material/platform/getGroupByparentIdsStr',data);
};




/**
 * 通用获取文件夹列表
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMaterialCommonFolder = (data)=> {
    return POST('/material/common/getFolder',data);
};

/**
 * 通用创建文件夹
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const createMaterialCommonFolder= (data)=> {
    return POST('/material/common/createFolder',data);
};

/**
 * 通用删除文件夹
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const delFolderMaterialCommon= (data)=> {
    return POST('/material/common/delFolder',data);
};

/**
 * 通用重命名文件夹
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const renameFolderMaterialCommon= (data)=> {
    return POST('/material/common/renameFolder',data);
};



/**
 * 通用创建默认文件夹
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const checkMiniProDefaultFolderMaterialCommon= (data)=> {
    return POST('/material/common/checkMiniProDefaultFolder',data);
};
