var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"third-party-login-wrapper"},[_c('el-divider',[_vm._v(_vm._s(_vm.$t('login.otherLoginMethods')))]),_c('div',{staticClass:"third-party-login-body"},[(_vm.accountLogin)?_c('el-image',{staticClass:"third-party-login-icon",attrs:{"src":require('@/assets/edit-imgs/login/zhanghao.png')},on:{"click":function (){
                        this$1.$router.replace('/login')
                      }}}):_vm._e(),(_vm.mobileLogin)?_c('el-image',{staticClass:"third-party-login-icon",attrs:{"src":require('@/assets/edit-imgs/login/mobile.png')},on:{"click":function (){
                        this$1.$router.replace('/login/securityCode')
                      }}}):_vm._e(),(_vm.weChatLogin)?_c('el-image',{staticClass:"third-party-login-icon",attrs:{"src":require('@/assets/edit-imgs/login/wechat.png')},on:{"click":function (){
                        this$1.$router.replace('/login/weChatQRCode')
                      }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }