import {POST} from '../request.js'

/**
 * 获取二维码
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getQrcode = ()=> {
    return POST('/wechat/qrcode/getQrcode',null);
};

/**
 * 校验二维码
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const pingWechat = (data)=> {
    return POST('/merchant/wechat/ping',data);
};
