export default{
    namespaced:true,
    state:{
        //小程序id
        miniProId: null,
        miniProInfo: {},
    },

    mutations:{
        /**
         * 微信小程序id,
         * @param state
         * @param miniProId
         */
        miniProId(state,miniProId){
            state.miniProId = miniProId;
        },

        /**
         * 微信小程序信息,
         * @param state
         * @param miniProInfo
         */
        miniProInfo(state,miniProInfo){
            state.miniProInfo = miniProInfo;
            state.miniProId = miniProInfo.id;
        },

        /**
         * 在适当的时候需要清空state里面的数据，否则会数据混乱
         * @param state
         */
        clear(state){
            state.miniProId = 0;
            state.miniProInfo = {};
        }
    },

}
