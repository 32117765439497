import {getIndexPage, pageInfo} from "../../request/api/page";
import $bus from "../../bus";
export default{
    namespaced:true,
    state:{
        //当前激活的页面
        pageInfo:{},
        //数据
        componentList:[],
        //页面的底部导航
        tabBar:{},
    },

    mutations:{
        /**
         * 当前展示的页面信息,
         * @param state
         * @param page
         */
        pageInfo(state,page){
            state.pageInfo = page;
        },
        /**
         * 更新组件列表调用此方法,
         * @param state
         * @param list
         */
        componentList(state,list){
            state.componentList = list;
        },

        /**
         * 更新组件列表调用此方法,
         * @param state
         * @param list
         */
        tabBar(state,tabBar){
            state.tabBar = tabBar;
        },

        /**
         * 在适当的时候需要清空state里面的数据，否则会数据混乱
         * @param state
         */
        clear(state){
            state.pageInfo = {};
            state.componentList = [];
            state.tabBar = {};
        }
    },

    getters:{
        //计算取值
        //通过id获取当前页面的组件信息
        getPageComposeById(state){
            return (id)=>{
                if (state.tabBar && state.tabBar.id === id) return state.tabBar;
                return state.componentList.find(c=>c.id === id);
            }
        },
    },

    actions:{

        //加载首页信息 orOther 1：如果么有首页，则加载任意页面
        loadIndex({state,commit},orOther){
            getIndexPage({orOther}).then(res=>{
                let page = res.data;
                if (page){

                    let pageInfo = page.pageInfo;
                    let componentList = page.composeList;
                    let tabBar = page.tabBar;

                    commit('componentList',componentList);
                    commit('tabBar',tabBar);
                    commit('pageInfo',pageInfo);

                    //保存到历史栈中
                    $bus.$emit($bus.event.PAGE_SAVE_HISTORY);
                    //加载属性信息
                    $bus.$emit($bus.event.PROPERTY_CHANGE, pageInfo.target, pageInfo.property);
                }
            });
        },
        //异步操作修改值
        reloadPage({state,commit},pageId){
            let id = pageId || state.pageInfo.id;
            if (!id) return;
            pageInfo({pageId: id}).then(res => {
                let componentList = res.data.composeList;
                let tabBar = res.data.tabBar;
                let pageInfo = res.data.pageInfo;

                commit('componentList',componentList);
                commit('tabBar',tabBar);
                commit('pageInfo',pageInfo);

                //保存到历史栈中
                $bus.$emit($bus.event.PAGE_SAVE_HISTORY);
                //加载属性信息
                $bus.$emit($bus.event.PROPERTY_CHANGE, pageInfo.target, pageInfo.property);
            });
        }
    }
}
