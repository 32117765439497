<template>
    <div class="cz-material-selector-files">
        <CzMaterialPaltSelectorIcon
                v-for="(item,idx) in value.files"
                :index="idx"
                :files="value.files"
                @checked="onCheckedItem($event,idx)"
                :shift-status="value.activeFile.shiftStatus"
                :icon-prop="iconProp"
                :view="view"
                :data="item"
        />
        <cz-empty
                :title="value.activeFolder.groupName"
                :description="i18n.t('materialSelector.ihidDocuments')"
                :is-upload="false"
                v-if="value.files.length <= 0"
        />
    </div>
</template>

<script>
    import CzMaterialSelectorFile from "./cz-material-selector-file";
    import CzEmpty from "../../cz-empty/cz-empty";
    import CzMaterialPaltSelectorFile from "./cz-material-palt-selector-file";
    import _ from "lodash";
    import {getTblMaterialPlat, searchFilesByName} from "../../../../request/api/material";
    import i18n from "../../../../locale";
    import CzMaterialPaltSelectorIcon from "./cz-material-palt-selector-icon";

    export default {
        name: "cz-material-palt-selector-icons",
        components: {CzMaterialPaltSelectorIcon, CzEmpty},
        props: {
            value: Object,
            view: Boolean,
            iconProp: Object
        },
        data() {
            return {
                i18n: i18n,
                pageSize: 15
            }
        },
        watch: {
            "value.files": {
                handler() {
                    this.watchChecked();
                },
                deep: true,
                immediate: true,
            },
            "value.activeFolder": {
                deep: true,
                handler() {
                    this.getMaterialFiles();
                }
            },
            "value.search": {
                deep: true,
                handler() {
                    this.getMaterialSearchFiles();
                }
            }
        },
        methods: {
            watchChecked() {
                let filters = this.value.files.filter(
                    item => item["_checked"] && item["_checked"] === true
                );
                this.value.activeFile.checkedNumber = filters.length;
                this.value.activeFile.checked = this.value.files.length !== 0 && this.value.files.length === this.value.activeFile.checkedNumber;
                this.value.activeFile.activeFiles = filters;
            },
            allCheckedChanged(e) {
                let _this = this;
                let allUrl = this.value.files.map(item => item["base64"]);
                if (e && (this.value.allActiveUrls.length + allUrl.length) > _this.value.selectNumber) {
                    _this.$notify({
                        title: _this.i18n.t("materialSelector.hint"),
                        message: _this.i18n.t("materialSelector.maximumOfFilesSelected", {selectNumber: _this.value.selectNumber}),
                        type: 'warning'
                    });
                    this.value.activeFile.checked = false;
                    return;
                }
                _this.value.files.forEach(f => {
                    _this.$set(f, '_checked', e)
                });
                if (e) {
                    this.value.allActiveUrls = _.without(this.value.allActiveUrls, ...allUrl);
                    this.value.allActiveUrls.push(...allUrl);
                } else {
                    this.value.allActiveUrls = _.without(this.value.allActiveUrls, ...allUrl);
                }
            },
            onCheckedItem(checked, idx) {
                let _this = this;
                if (this.value.activeFile.shiftStatus !== true) {
                    this.value.files.forEach(item => _this.$set(item, '_checked', false));
                    this.$set(_this.value.files[idx], '_checked', true);
                    let allUrl = this.value.files.map(item => item["base64"]);
                    if (allUrl && allUrl.length > 0) {
                        this.value.allActiveUrls = _.without(this.value.allActiveUrls, ...allUrl);
                    }
                    if (_this.value.selectNumber === 1) {
                        this.value.allActiveUrls = [];
                    }
                    this.value.allActiveUrls.push(_this.value.files[idx]["base64"]);
                } else {
                    if (!checked) {
                        this.value.allActiveUrls = _.without(
                            this.value.allActiveUrls, _this.value.files[idx]["base64"]);
                        this.$set(_this.value.files[idx], '_checked', checked);
                        return;
                    }
                    if (checked && this.value.allActiveUrls.length >= _this.value.selectNumber) {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: _this.i18n.t("materialSelector.maximumOfFilesSelected", {selectNumber: _this.value.selectNumber}),
                            type: 'warning'
                        });
                        return;
                    }
                    this.$set(_this.value.files[idx], '_checked', checked);
                    this.value.allActiveUrls.push(_this.value.files[idx]["base64"]);
                }
            },
            getChecked() {
                return this.value.files.filter(item => item["_checked"] && item["_checked"] === true)
            },
            getMaterialSearchFiles() {
                let _this = this;
                console.log(_this.value.activeImageType)
                if (this.value.search && this.value.searchType === '0') {
                    searchFilesByName({
                        parentId: _this.value.activeImageType.parentIdsStr,
                        fileName: _this.value.search,
                        pageNum: _this.value.pagination.pageNum,
                        pageSize: _this.pageSize,
                    }).then(result => {
                        if (result.code === 200) {
                            _this.value.pagination = result.data;
                            _this.value.files = result.data.list;
                        } else {
                            console.log("getMaterialSearchFiles", result)
                        }
                    })
                } else {
                    _this.getMaterialFiles();
                }
            },
            getMaterialFiles() {
                let _this = this;
                if (this.value.searchType === '0') {
                    _this.value.search = '';
                }
                let data = {
                    groupId: _this.value.activeFolder.groupId,
                    pageNum: _this.value.pagination.pageNum,
                    pageSize: _this.pageSize,
                };
                getTblMaterialPlat(data).then(result => {
                    if (result.code === 200) {
                        _this.total = result.data.total;
                        _this.value.pagination = result.data;
                        _this.value.files = result.data.list;
                    } else {
                        console.log(getTblMaterialPlat, result)
                    }
                })
            },
        },
        mounted() {
            let _this = this;
            this.$bus.$on(this.$BUS_EVENT.PAGINATION_CHANGE,(e) => {
                _this.value.pagination.pageNum = e;
                _this.getMaterialSearchFiles();
            });
        },
        beforeDestroy() {
            this.$bus.$off(this.$BUS_EVENT.PAGINATION_CHANGE);
        },
        created() {
            let _this = this;
            window.addEventListener('keydown', code => {
                if (code.keyCode === 16 && code.shiftKey) { // 判断是否按住shift键，是就把pin赋值为true
                    _this.value.activeFile.shiftStatus = true
                }
            });
            window.addEventListener('keyup', code => {
                if (code.keyCode === 16) { // 判断是否松开shift键，是就把pin赋值为false
                    _this.value.activeFile.shiftStatus = false
                }
            })
        },
    }
</script>

<style scoped lang="less">

</style>
