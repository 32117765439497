export default{
    namespaced:true,
    state:{
        //最大回退步数，越多越耗性能,最少具有1条初始值
        maxStack:50,
        //当前指向的索引
        currentStack:-1,
        //当前激活的页面
        //{data,uuid}
        historyStack:[],
    },

    mutations:{
        //设置当前索引
        currentStack(state,currentStack){
            state.currentStack = currentStack;
        },

        //压栈
        push(state,item){
            state.historyStack.push(item);
            if (state.historyStack.length > state.maxStack){
                state.historyStack.shift();
            }
        },

        //缩小栈到当前索引
        reduce(state){
            if (state.currentStack > -1 && state.currentStack <= state.historyStack.length - 1){
                state.historyStack.length = state.currentStack + 1;
            }
        },

        clearHistory(state){
            state.currentStack = -1;
            state.historyStack = [];
        }
    },

    getters:{
        //计算取值
        historyLength:state=>{
            return state.historyStack.length;
        },

        //获取某个下标的历史记录
        getHistoryItem(state){
            return (index)=>{
                return state.historyStack[index];
            }
        },
    },
    actions:{
        //异步操作修改值
    }
}
