import {POST} from '../request.js'



/**
 * 获取商户所以通知
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMerchantNoticeList = (data)=> {
    return POST('/merchant/notice/getMerchantNoticeList',data);
};

/**
 * 全部设置已读
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const setAllToRead = (data)=> {
    return POST('/merchant/notice/setAllToRead',data);
};

/**
 * 删除通知
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const deleteNotice = (data)=> {
    return POST('/merchant/notice/deleteNotice',data);
};


/**
 * 检查验证码
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const checkRegisterCode = (data)=> {
    return POST('/merchant/checkRegisterCode',data);
};

/**
 * 注册
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const register = (data)=> {
    return POST('/merchant/register',data);
};


/**
 * 当前登录用户
 * @param data
 * @returns {*}
 * @author Bcheng
 */
// export const getCurrentUser = ()=> {
//     return POST('/merchant/getCurrentUser',null);
// };
export const getCurrentUser = ()=> {
    return POST('/admin/user/getCurrentUser',null);
};
/**
 * 更新用户信息
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const updateMerchantAccount = (data)=> {
    return POST('/merchant/updateMerchantAccount', data);
};

/**
 * 获取登录人员已加入企业列表
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getMerchantAccountCards = ()=> {
    return POST('/merchant/getMerchantAccountCards', null);
};

/**
 * 微信解绑
 * @param data
 * @returns {*}
 * @author TreeJohn
 */
export const weChatUnBinding = ()=> {
    return POST('/merchant/weChatUnBinding', null);
};

/**
 * 更新上次进入商户时间
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const updateLastEntryTime = (data)=> {
    return POST('/merchant/updateLastEntryTime', data);
};




/**
 * 退出企业
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const exitMerchant = (data)=> {
    return POST('/merchant/exitMerchant', data);
};

/**
 * 当前登录用户
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const checkUser = (data)=> {
    return POST('/merchant/checkUser',data);
};


/**
 * 创建商家详情
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const addTblMerchant = (data)=> {
    return POST('/merchant/addTblMerchant',data);
};

/**
 * 通过商家id获取当前商家
 * @param data
 * @returns {AxiosPromise}
 */
export const getMerchantByMerchantId = (data)=> {
    return POST('/merchant/getMerchantByMerchantId',data);
};

/**
 * 更新商家详情
 * @param data
 * @returns {AxiosPromise}
 */
export const updateTblMerchant = (data)=> {
    return POST('/merchant/updateTblMerchant',data);
};

/**
 * 获取商家的创建者
 * @param data
 * @returns {AxiosPromise}
 */
export const getMerchantCreatorByMerchantId = (data)=> {
    return POST('/merchant/getMerchantCreatorByMerchantId',data);
};

/**
 * 重置密码
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const resetPasswords = (data)=> {
    return POST('/merchant/resetPasswords',data);
};



/**
 * 检查是否有创建重复的
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const checkMerchantByName = (data)=> {
    return POST('/merchant/checkMerchantByName',data);
};


/**
 * 登录更新基本信息
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const updateBasicAccount = (data)=> {
    return POST('/merchant/updateBasicAccount',data);
};

/**
 * 更新企业名片
 * @param data
 * @returns {*}
 * @author TreeJohn
 */
export const updateMerchantCard = (data)=> {
    return POST('/merchant/updateMerchantCard',data);
};


/**
 * 通用绑定账号
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const commonBinding = (data)=> {
    return POST('/merchant/commonBinding',data);
};

/**
 * 获取商家详情
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const getTblMerchant = (data)=> {
    return POST('/merchant/getTblMerchant',data);
};

/**
 * 获取商家订单列表
 * @param data
 * @returns {AxiosPromise}
 */
export const tempOrderList = (data)=> {
    return POST('/merchant/tempOrder/tempOrderList',data);
};


/**
 * 获取商家成员列表
 * @param data
 * @returns {AxiosPromise}
 */
export const getMerchantAccount = (data)=> {
    return POST('/merchant/getMerchantAccount',data);
};

/**
 * 更新企业成员角色
 * @param data
 * @returns {AxiosPromise}
 */
export const updateAccountRole = (data)=> {
    return POST('/merchant/updateAccountRole',data);
};

/**
 * 邀请企业成员
 * @param data
 * @returns {AxiosPromise}
 */
export const addInvitation = (data)=> {
    return POST('/merchant/invitation/addInvitation',data);
};

/**
 * 申请发票
 * @param data
 * @returns {AxiosPromise}
 */
export const applyInvoice = (data)=> {
    return POST('/merchant/tempOrder/applyInvoice',data);
};

/**
 * 检查是否需要弹框
 * @param data
 * @returns {AxiosPromise}
 */
export const examineInvitation = (data)=> {
    return POST('/merchant/invitation/examineInvitation',data);
};

/**
 * 获取邀请商户
 * @param data
 * @returns {AxiosPromise}
 */
export const getInvitationList = (data)=> {
    return POST('/merchant/invitation/getInvitationList',data);
};

/**
 * 删除商户成员
 * @param data
 * @returns {AxiosPromise}
 */
export const delMerchantMembers = (data)=> {
    return POST('/merchant/delMerchantMembers',data);
};

/**
 * 同意进入商户
 * @param data
 * @returns {AxiosPromise}
 */
export const ConsentToEnterMerchant = (data)=> {
    return POST('/merchant/invitation/consentToEnterMerchant',data);
};


/**
 * 拒绝进入商户
 * @param data
 * @returns {AxiosPromise}
 */
export const DisagreeToJoinMerchant = (data)=> {
    return POST('/merchant/invitation/disagreeToJoinMerchant',data);
};

/**
 * 更新弹框状态
 * @param data
 * @returns {AxiosPromise}
 */
export const updateInvitationStatus = (data)=> {
    return POST('/merchant/invitation/updateInvitationStatus',data);
};

/**
 * 获取页面模板分组
 * @param data
 * @returns {AxiosPromise}
 */
export const getTemplateGroupList = (data)=> {
    return POST('/merchant/template/getGroupList',data);
};


/**
 * 获取页面模板页面列表
 * @param data
 * @returns {AxiosPromise}
 */
export const getPageListWithGroupId = (data)=> {
    return POST('/merchant/template/getPageListWithGroupId',data);
};



/**
 * 获取表单组件时间选择器选项
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const getTimeList = (data)=> {
    return POST('/merchant/common/getTimeList',data);
};

/**
 * 获取表单组件日期选择器选项
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const getDateList = (data)=> {
    return POST('/merchant/common/getDateList',data);
};

/**
 * 获取表单省市区选择器选项
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const getAreaList = (data)=> {
    return POST('/merchant/common/getAreaList',data);
};

/**
 * 更新阅读新手引导状态
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const updateGuideStatus = (data)=> {
    return POST('/merchant/updateGuideStatus',data);
};

export const supplementarySettingItem = (data)=> {
    return POST('/merchant/admin/supplementarySettingItem',data);
};