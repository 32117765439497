export default {
    rules:{
        account: [
            { required: true, message: '请输入手机号或邮箱', trigger: 'blur' },
            { pattern: "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])|^(?:0|86|\\+86)?1[3-9]\\d{9}$", message: '请输入正确的手机号或者邮箱' }
        ],
        password: [
            { required: true, message: '请输入密码', trigger: 'blur' },
            { min: 8, max: 16, message: '密码长度在 8 到 16 个字符', trigger: 'blur' }
        ],
        name: [
            { required: true, message: '用户名', trigger: 'blur' },
        ],
        code:[
            { required: true, message: '请输入验证码', trigger: 'blur' },
            { pattern: "^\\d{5}$", message: '请输入正确的验证码' }
        ]
    },
}
