<template>
    <div class="cz-material-folders">
        <CzMaterialFolder
                v-for="(item, index) in newFolder"
                :index="index"
                @createFolder="createFolder"
                :item="item"
                :is-make="true"
                :checked="item.checked"
        />
        <CzMaterialFolder
                v-for="item in value.folders"
                v-if="!(value.searchType === '1' && item.groupName && !item.groupName.match(value.search))"
                @selected="selected(item)"
                @delFolder="delFolder"
                @renameFolder="renameFolder"
                :item="item"
                :checked="value.activeFolder && value.activeFolder.id === item.id"
        />
    </div>
</template>

<script>
    import CzMaterialFolder from "./cz-material-folder";
    import {
        createMaterialCommonFolder, delFolderMaterialCommon,
        getMaterialCommonFolder,
        renameFolderMaterialCommon
    } from "../../../../request/api/material";
    import i18n from "../../../../locale";
    import store from "../../../../store/index"

    export default {
        name: "cz-material-folders",
        components: {CzMaterialFolder},
        props: {
            value: Object,
        },
        data() {
            return {
                i18n: i18n,
                newFolder: [],
            }
        },
        watch: {
            "value.folders": {
                deep: true,
                handler(item) {
                    //默认选中第一个
                    this.selected(item[0])
                }
            },
            "value.activeImageType": {
                immediate: true,
                deep: true,
                handler: function () {
                    this.getFolder();
                }
            },
            "value.activeFileType": {
                immediate: true,
                deep: true,
                handler: function () {
                    this.getFolder();
                }
            },
        },
        methods: {
            selected(item) {
                this.$set(this.value, "pagination", {pageNum: 1});
                this.value.activeFolder = item;
            },
            plusFolder() {
                let _this = this;
                if (_this.newFolder.length === 0){
                    _this.folderNumber += 1;
                    this.newFolder.push({
                        id: this.newFolder.length,
                        groupName: "",
                        make: true,
                        checked: false,
                    })
                }
            },
            getFolder() {
                let _this = this;
                let code = _this.value.activeImageType.code;
                if (code === 0 || code === 1) {
                    let data = {
                        groupType: _this.value.activeFileType.code,
                        type: _this.value.activeImageType.code,
                        publicId: _this.getPublicId(),
                    };
                    getMaterialCommonFolder(data).then(result => {
                        if (result.code === 200) {
                            _this.value.folders = result.data;
                            if (_this.folderNumber === 0){
                                _this.folderNumber = _this.getFolderNumber();
                            }
                        } else {
                            console.error("getFolder", result);
                        }
                    })
                }
            },
            createFolder(item) {
                let _this = this;
                //删除已经添加的内容
                _this.newFolder = _this.newFolder.filter(it => it.id !== item.id);
                if (!item.groupName)
                    return;
                if (!(/^[A-Za-z0-9\u4e00-\u9fa5]{1,12}$/.test(item.groupName))) {
                    _this.$notify({
                        title: _this.i18n.t("materialSelector.hint"),
                        message: _this.i18n.t("materialSelector.lengthRangesFrom1To12Characters"),
                        type: 'warning'
                    });
                    _this.getFolder();
                    return;
                }
                let data = {
                    'type': _this.value.activeImageType.code,
                    "groupName": item.groupName,
                    "groupType": _this.value.activeFileType.code,
                    "publicId": _this.getPublicId(),
                };
                createMaterialCommonFolder(data).then(result => {
                    if (result.code === 200) {
                        _this.getFolder();
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'success'
                        });
                    } else {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'warning'
                        });
                        console.error("createFolder", result)
                    }
                    _this.getFolder();
                })
            },
            renameFolder(item) {
                let _this = this;
                if (item.groupName === "默认文件夹") {
                    _this.$notify({
                        title: _this.i18n.t("materialSelector.hint"),
                        message: _this.i18n.t("materialSelector.defaultFolderCannotModified"),
                        type: 'warning'
                    });
                    return;
                }
                if (!(/^[A-Za-z0-9\u4e00-\u9fa5]{1,12}$/.test(item.groupName))) {
                    _this.$notify({
                        title: _this.i18n.t("materialSelector.hint"),
                        message: _this.i18n.t("materialSelector.lengthRangesFrom1To12Characters"),
                        type: 'warning'
                    });
                    _this.getFolder();
                    return;
                }
                let data = {
                    'type': _this.value.activeImageType.code,
                    'id': item.id,
                    'groupName': item.groupName,
                    'publicId': _this.getPublicId(),
                    "groupType": _this.value.activeFileType.code,
                };
                renameFolderMaterialCommon(data).then((result) => {
                    if (result.code === 200) {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'success'
                        });
                    } else {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'warning'
                        });
                    }
                    _this.getFolder();
                })
            },
            delFolder(item) {
                let _this = this;
                if (item.groupName === "默认文件夹") {
                    _this.$notify({
                        title: _this.i18n.t("materialSelector.hint"),
                        message: _this.i18n.t("materialSelector.defaultFolderFileCannotDeleted"),
                        type: 'warning'
                    });
                    return;
                }
                let data = {
                    'type': _this.value.activeImageType.code,
                    'id': item.id,
                    'publicId': _this.getPublicId(),
                    "groupType": _this.value.activeFileType.code,
                };
                delFolderMaterialCommon(data).then((result) => {
                    if (result.code === 200) {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'success'
                        });
                    } else {
                        _this.$notify({
                            title: _this.i18n.t("materialSelector.hint"),
                            message: result.message,
                            type: 'warning'
                        });
                    }
                    _this.getFolder();
                });
            },
            getPublicId() {
                if (this.value.activeImageType.code === 1) {
                    return store.state.miniPro.miniProId;
                } else if (this.value.activeImageType.code === 0) {
                    return this.$MERCHANT().merchantId
                } else {
                    console.log("没有这个PublicId")
                }
            },
        },
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";
</style>
