<template>
    <div class="cz-material-folder" :class="{'checked':checked}">
        <template>
            <div class="cz-material-folder-left" @click="selected">
                <div>
                    <i-folder-close :strokeWidth="3"/>
                </div>
                <div style="margin-left: 10px;">
                    {{item.groupName}}
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: "cz-material-plat-folder",
        props: {
            item: Object,
            checked: Boolean,
        },
        methods: {
            selected() {
                this.$emit("selected", this.item);
            },
        },
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";

    .cz-material-folder {
        height: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        box-sizing: border-box;
        font-size: 12px;
        cursor: pointer;
        user-select: none;

        .cz-material-folder-left {
            flex: 1;
            height: 32px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-left: 7px;
            box-sizing: border-box;
        }
    }

    .checked {
        background-color: rgba(255, 111, 51, 0.05);
        color: #FF6F33;
    }
</style>
