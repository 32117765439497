<template>
    <transition name="viewer-fade">
        <div tabindex="-1" ref="el-image-viewer__wrapper" class="el-image-viewer__wrapper"
             :style="{ 'z-index': zIndex }">
            <div class="el-image-viewer__mask" @click.self="handleMaskClick"></div>
            <!-- CLOSE -->
            <span class="el-image-viewer__btn el-image-viewer__close" @click="hide">
                <i class="el-icon-close"></i>
            </span>
            <!-- CANVAS -->
            <div class="el-image-viewer__canvas">
                <d-player ref="player" class="dplayer" :options="player"/>
            </div>
        </div>
    </transition>
</template>

<script>
    import {on, off} from 'element-ui/src/utils/dom';
    import {rafThrottle, isFirefox} from 'element-ui/src/utils/util';

    const mousewheelEventName = isFirefox() ? 'DOMMouseScroll' : 'mousewheel';
    export default {
        name: 'cz-video-viewer',
        props: {
            url: String,
            zIndex: {
                type: Number,
                default: 2000
            },
            onClose: {
                type: Function,
                default: () => {
                }
            }
        },
        data() {
            return {
                isShow: false,
                loading: false,
                player: {
                    theme: "#b7daff",
                    lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
                    screenshot: true,
                    hotkey: true,
                    preload: 'auto',
                    autoplay: true,
                    video: {
                        url: 'http://static.smartisanos.cn/common/video/t1-ui.mp4',
                        pic: 'http://static.smartisanos.cn/pr/img/video/video_03_cc87ce5bdb.jpg',
                        type: 'auto',
                    },
                    contextmenu: [
                        {
                            text: "超赞",
                            click: () => {
                                this.$router.replace("/")
                            },
                        },
                    ],
                },
            };
        },
        watch: {
            "url": {
                handler(newKey){
                    this.player.video.url = newKey;
                },
                deep: true,
                immediate: true,
            }
        },
        methods: {
            hide() {
                this.deviceSupportUninstall();
                this.onClose();
            },
            deviceSupportInstall() {
                this._keyDownHandler = rafThrottle(e => {
                    const keyCode = e.keyCode;
                    switch (keyCode) {
                        // ESC
                        case 27:
                            this.hide();
                            break;
                    }
                });
                on(document, 'keydown', this._keyDownHandler);
                on(document, mousewheelEventName, this._mouseWheelHandler);
            },
            deviceSupportUninstall() {
                off(document, 'keydown', this._keyDownHandler);
                off(document, mousewheelEventName, this._mouseWheelHandler);
                this._keyDownHandler = null;
                this._mouseWheelHandler = null;
            },
            handleMaskClick() {
                if (this.maskClosable) {
                    this.hide();
                }
            },
        },
        mounted() {
            this.deviceSupportInstall();
            this.$refs['el-image-viewer__wrapper'].focus();
        },
        destroyed() {
            if (this.$el && this.$el.parentNode) {
                this.$el.parentNode.removeChild(this.$el);
            }
        }
    };
</script>
<style scoped lang="less">
    .dplayer {
        width: 60%;
    }
</style>
