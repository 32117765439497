<template>
    <div class="third-party-login-wrapper">
        <el-divider>{{$t('login.otherLoginMethods')}}</el-divider>
        <div class="third-party-login-body">
            <el-image v-if="accountLogin" class="third-party-login-icon"
                      :src="require('@/assets/edit-imgs/login/zhanghao.png')"
                      @click="()=>{
                            this.$router.replace('/login')
                          }"
            />
            <el-image v-if="mobileLogin" class="third-party-login-icon"
                      :src="require('@/assets/edit-imgs/login/mobile.png')"
                      @click="()=>{
                            this.$router.replace('/login/securityCode')
                          }"
            />
            <el-image v-if="weChatLogin" class="third-party-login-icon"
                      :src="require('@/assets/edit-imgs/login/wechat.png')"
                      @click="()=>{
                            this.$router.replace('/login/weChatQRCode')
                          }"
            />
        </div>
    </div>
</template>

<script>
    export default {
        name: "ThirdPartyLogin",
        props: {
            accountLogin: {
                type: Boolean,
                default: false,
            },
            mobileLogin: {
                type: Boolean,
                default: false,
            },
            weChatLogin: {
                type: Boolean,
                default: false,
            },
        }
    }
</script>

<style scoped lang="less">

    .third-party-login-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .third-party-login-body {
            .third-party-login-icon {
                cursor: pointer;
                width: 32px;
                height: 32px;
                line-height: 32px;
                border-radius: 32px;
                color: white;
                margin-right: 10px;

                &:hover {
                    transition: 0.3s;
                    opacity: 0.8;
                }
            }
        }


    }


</style>
