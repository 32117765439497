<template>
    <transition name="modal"
                @after-enter="afterEnter"
                @after-leave="afterLeave">
        <div v-if="visible"
             class="modal-wrapper">
            <div class="modal-body">
                <slot/>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        name: "apply-receipt-modal",
        props:{
            visible:Boolean,
        },
        watch:{
            visible(val){
                let filter = "";
                if (val){
                    filter = "blur(8px)";
                    document.body.appendChild(this.$el);
                }
                document.getElementById("app").style.filter = filter;
            }
        },
        methods:{
            afterEnter(){
                this.$emit("opened",null);
            },
            afterLeave(){
                this.$emit("closed",null);
            },
        }
    }
</script>

<style scoped lang="less">

    @import "../../../assets/style/index";

    @base-zIndex:2002;

    .modal-wrapper{
        z-index: @base-zIndex;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .flex-center;
        .modal-body{
            background-color: white;
            border-radius: @border-radius-lg;
            min-width: 100px;
            min-height: 100px;
            z-index: calc(@base-zIndex + 1);
        }

        &:before{
            content: " ";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.5);
        }
    }
</style>
<style lang="less">
    .modal-enter,
    .modal-leave-to{
        opacity: 0;
        margin-top: -20px;
    }

    .modal-enter-active,
    .modal-leave-active{
        transition: all .2s ease;
    }

</style>
