import {request,POST} from '../request.js'


/**
 * 获取小程序所有的可用组件
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const allComponentsGroupList = (data)=> {
    return POST('/making/components/allGroupList',data);
};



/**
 * 添加一个历史版本
 * @param data
 * @returns {*}
 * @author byan
 */
export const createPageHistory = (data)=> {
    return POST('/making/pageHistory/create',data);
};

/**
 * 删除一个历史版本
 * @param data
 * @returns {*}
 * @author byan
 */
export const deletePageHistory = (data)=> {
    return POST('/making/pageHistory/delete',data);
};

/**
 * 编辑一个历史版本
 * @param data
 * @returns {*}
 * @author byan
 */
export const editPageHistory = (data)=> {
    return POST('making/pageHistory/edit',data);
};

/**
 * 获取历史版本列表
 * @param data
 * @returns {*}
 * @author byan
 */
export const getPageHistoryList = (data)=> {
    return POST('/making/pageHistory/list',data);
};

/**
 * 获取历史版本列表
 * @param data
 * @returns {*}
 * @author byan
 */
export const resetPageHistory = (data)=> {
    return POST('/making/pageHistory/redo',data);
};
