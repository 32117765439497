<template>
    <div class="router-view-wrapper" ref="routerViewWrapper">
        <template v-if="$route.meta && $route.meta.cached">
            <keep-alive>
               <router-view/>
            </keep-alive>
        </template>
        <template v-else>
            <router-view/>
        </template>
    </div>
</template>

<script>
    export default {
        name: "index",
        watch: {
            $route:{
                handler:function(){
                    let routerViewWrapper = this.$refs.routerViewWrapper;
                    routerViewWrapper.scrollTop = 0;
                    routerViewWrapper.scrollLeft = 0;
                }
            }
        },
    }
</script>

<style scoped lang="less">
    @import "../assets/style/index";

    .router-view-wrapper{
        height: 100%;
        width: 100%;
        overflow: scroll;
        .hidden-scroll-bar;
    }
</style>
