import i18n from "../locale";
import RouterLayout from "../layout/router"

/**
 * 在container.children中的一级页面下，如果在左侧导航栏显示，需要配置meta:{isNav:true,icon:'',title:''},
 * meta:{cached:true}  cached是否需要缓存页面
 */
export default [

    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        name: 'login',
        redirect: '/',
        component: () => import("../views/login/login.vue"),
        children: [
            {
                path: '/',
                name: 'sernamePassword',
                component: () => import("../views/login/view/UsernamePassword"),
            },
            {
                path: '/login/securityCode',
                name: 'securityCodeForm',
                component: () => import("../views/login/view/SecurityCode"),
            },
            {
                path: '/login/weChatQRCode',
                name: 'weChatQRCode',
                component: () => import("../views/login/view/WeChatQRCode"),
            },
            {
                path: '/login/register',
                name: 'register',
                component: () => import("../views/login/view/Register"),
            },
            {
                path: '/login/completeInformation',
                name: 'completeInformation',
                component: () => import("../views/login/view/CompleteInformation"),
            },
            {
                path: '/login/forgetPasswordSecurityCode',
                name: 'forgetPasswordSecurityCode',
                component: () => import("../views/login/view/ForgetPasswordSecurityCode")
            },
            {
                path: '/login/registerWeChatQRCode',
                name: 'registerWeChatQRCode',
                component: () => import("../views/login/view/RegisterWeChatQRCode")
            },
            {
                path: '/enterpriseInformation',
                name: 'enterpriseInformation',
                component: () => import("../views/login/view/EnterpriseInformation")
            },
            {
                path: '/createEnterpriseInformation',
                name: 'createEnterpriseInformation',
                component: () => import("../views/login/view/CreateEnterpriseInformation")
            },
            {
                path: '/bindThirdPartyAccount',
                name: 'bindThirdPartyAccount',
                component: () => import("../views/login/view/BindThirdPartyAccount")
            },
            {
                path: '/forgetPassword',
                name: 'forgetPassword',
                component: () => import("../views/login/view/ForgetPassword")
            }

        ]
    },
    {
        path: '/manager',
        redirect: '/manager/dashboard',
        component: () => import("../views-manager/index/index"),
        children: [
            {
                path: '/manager/dashboard',
                meta: {
                    isNav: true,
                    icon: 'm-menu-01',
                    title: i18n.t('m.menu.dashboard'),
                },
                component: () => import("../views-manager/dashboard/dashboard")
            },
            {
                path: '/manager/template',
                meta: {
                    isNav: true,
                    icon: 'm-menu-03',
                    title: i18n.t('m.menu.template'),
                },
                component: RouterLayout,
                redirect: '/manager/template/allTemplate/index',
                children:[
                    {
                        path: '/manager/template/allTemplate/index',
                        meta: {
                            isNav: true,
                            title: i18n.t('m.menu.templateManage'),
                        },
                        component: RouterLayout,
                        redirect: '/manager/template/allTemplate/index',
                        children: [
                            {
                                //所有模板
                                path: '/manager/template/allTemplate/index',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.allTemplate'),
                                },
                                component: () => import("../views-manager/template/allTemplate/index"),

                            },
                            // 所有模板=>编辑模板
                            {
                                path: '/manager/template/allTemplate/editor_template',
                                component: () => import("../views-manager/template/allTemplate/editTemplate"),
                            },{
                                //模板分组
                                path: '/manager/template/templateGroup/index',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.templateGroup'),
                                },
                                component: () => import("../views-manager/template/templateGroup/index"),
                            },
                        ]
                    }
                ],
            },
            {
                path: '/manager/customer',
                meta: {
                    isNav: true,
                    icon: 'm-menu-05',
                    title: i18n.t('m.menu.customer'),
                },
                component: RouterLayout,
                redirect: '/manager/customer/enterprise',
                children: [
                    {
                        //管理
                        path: '/manager/customer/enterprise',
                        meta: {
                            isNav: true,
                            title: i18n.t('m.menu.customerManage'),
                        },
                        component: RouterLayout,
                        redirect: '/manager/customer/enterprise',
                        children: [
                            {
                                //企业查询
                                path: '/manager/customer/enterprise',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.enterpriseSearch'),
                                },
                                component: () => import("../views-manager/customer/enterprise/index"),
                            }, {
                                //客户详情
                                path: '/manager/customer/enterpriseDetails',
                                meta: {
                                    isNav: false,
                                    title: i18n.t('m.menu.enterpriseSearch'),
                                },
                                component: () => import("../views-manager/customer/enterprise/details"),
                            },
                            {
                                //客户查询
                                path: '/manager/customer/list',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.customerSearch'),
                                },
                                component: () => import("../views-manager/customer/list/index"),
                            }, {
                                //客户详情
                                path: '/manager/customer/details',
                                meta: {
                                    isNav: false,
                                    title: i18n.t('m.menu.customerSearch'),
                                },
                                component: () => import("../views-manager/customer/list/details"),
                            },
                        ]
                    }
                ],
            },
            {
                path: '/manager/shop',
                meta: {
                    isNav: true,
                    icon: 'm-menu-02',
                    title: i18n.t('m.menu.shop'),
                },
                redirect: '/manager/shop/mini-pro',
                component: RouterLayout,
                children: [
                    {
                        //小程序管理
                        path: '/manager/shop/mini-pro',
                        meta: {
                            isNav: true,
                            title: i18n.t('m.menu.miniPro'),
                        },
                        component: RouterLayout,
                        redirect: '/manager/shop/mini-pro/wechat',
                        children: [
                            {
                                //小程序管路
                                path: '/manager/shop/mini-pro/wechat',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.miniPro'),
                                },
                                component: () => import("../views-manager/shop/mini-pro/wechat"),
                            },{
                                //微信代码模板
                                path: '/manager/shop/mini-pro/codeTemplate',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.codeTemplate'),
                                },
                                component: () => import("../views-manager/shop/mini-pro/codeTemplate"),
                            }
                        ]
                    },
                ]
            },
            {
                path: '/manager/order',
                meta: {
                    isNav: true,
                    icon: 'm-menu-06',
                    title: i18n.t('m.menu.order'),
                },
                component: RouterLayout,
                redirect: '/manager/order/list',
                children: [
                    {
                        //管理
                        path: '/manager/order/list',
                        meta: {
                            isNav: true,
                            title: i18n.t('m.menu.orderManage'),
                        },
                        component: RouterLayout,
                        redirect: '/manager/order/list',
                        children: [
                            {
                                //订单查询
                                path: '/manager/order/list',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.orderSearch'),
                                },
                                component: () => import("../views-manager/order/list/index"),
                            }
                        ]
                    }
                ],
            },
            {
                path: '/manager/channel',
                meta: {
                    isNav: false,
                    icon: 'm-menu-07',
                    title: i18n.t('m.menu.channel'),
                },
                component: RouterLayout,
                redirect: '/manager/channel/list',
                children: [
                    {
                        //管理
                        path: '/manager/channel/list',
                        meta: {
                            isNav: true,
                            title: i18n.t('m.menu.channelManage'),
                        },
                        component: RouterLayout,
                        redirect: '/manager/channel/list',
                        children: [
                            {
                                //所有渠道
                                path: '/manager/channel/list',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.channelAll'),
                                },
                                component: () => import("../views-manager/channel/list/index"),
                            }, {
                                //统计
                                path: '/manager/channel/statistic',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.statistic'),
                                },
                                component: () => import("../views-manager/channel/statistic/index"),
                            },
                        ]
                    }
                ]
            },
            //通知
            {
                path: '/manager/communion',
                meta: {
                    isNav: true,
                    icon: 'm-menu-08',
                    title: i18n.t('m.menu.communion'),
                },
                component: RouterLayout,
                redirect: '/manager/communion/list',
                children: [
                    {
                        //管理
                        path: '/manager/communion/list',
                        meta: {
                            isNav: true,
                            title: i18n.t('m.menu.communionManage'),
                        },
                        component: RouterLayout,
                        redirect: '/manager/communion/list',
                        children: [
                            {
                                // 通知
                                path: '/manager/communion/list',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.valuation'),
                                },
                                component: () => import("../views-manager/communion/list/index"),
                            }
                        ]
                    },
                ]

            },
            {
                path: '/manager/coupon',
                meta: {
                    isNav: true,
                    icon: 'm-menu-09',
                    title: i18n.t('m.menu.coupon'),
                },
                component: RouterLayout,
                redirect: '/manager/coupon/coupon_list',
                children: [
                    {
                        //管理
                        path: '/manager/article/coupon_list',
                        meta: {
                            isNav: true,
                            title: i18n.t('m.menu.couponManage'),
                        },
                        component: RouterLayout,
                        redirect: '/manager/coupon/coupon_list',
                        children: [
                            {
                                //卡券列表
                                path: '/manager/coupon/coupon_list',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.couponList'),
                                },
                                component: () => import("../views-manager/coupon/list/index"),
                            }, {
                                //核销记录
                                path: '/manager/coupon/coupon_history',
                                meta: {
                                    isNav: true,
                                    title: i18n.t('m.menu.couponHistory'),
                                },
                                component: () => import("../views-manager/coupon/history/index"),
                            }
                        ]
                    }

                ]
            },
            {
                path: '/manager/setting',
                meta: {
                    isNav: false,
                    icon: 'm-menu-10',
                    title: i18n.t('m.menu.setting'),
                },
                component: () => import("../views-manager/setting/index")
            },
            {
                path: '/manager/tool',
                meta: {
                    isNav: true,
                    icon: 'm-menu-10',
                    title: i18n.t('m.menu.tool'),
                },
                component: RouterLayout,
                redirect: '/manager/tool/dcoker',
                children: [
                    {
                        //管理
                        path: '/manager/tool/dcoker',
                        meta: {
                            isNav: true,
                            title: i18n.t('m.menu.toolManage'),
                        },
                        component: RouterLayout,
                        redirect: '/manager/tool/dcoker',
                        children: [
                            {
                                // dcoker
                                path: '/manager/tool/dcoker',
                                meta: {
                                    isNav: true,
                                    title: 'dcoker',
                                },
                                component: () => import("../views-manager/tool/dcoker"),
                            },{
                                // nacos
                                path: '/manager/tool/nacos',
                                meta: {
                                    isNav: true,
                                    title: 'nacos',
                                },
                                component: () => import("../views-manager/tool/nacos"),
                            },{
                                // loki
                                path: '/manager/tool/loki',
                                meta: {
                                    isNav: true,
                                    title: 'loki统计',
                                },
                                component: () => import("../views-manager/tool/loki"),
                            },
                            {
                            //    sentinel
                                path: '/manager/tool/sentinel',
                                meta: {
                                    isNav: true,
                                    title: 'sentinel',
                                },
                                component: () => import("../views-manager/tool/sentinel"),
                            },{
                                //    rabbitMq
                                path: '/manager/tool/rabbitMq',
                                meta: {
                                    isNav: true,
                                    title: 'rabbitMQ',
                                },
                                component: () => import("../views-manager/tool/rabbitMq"),
                            }
                        ]
                    },
                ]

            }
        ]
    },
    {
        path: '/agreement/serviceAgreement',
        name: 'agreement',
        meta: {
            title: "超赞平台服务协议",
        },
        component: () => import("../views/agreement/ServiceAgreement.vue")
    },
    {

        path: '/upload',
        name: 'upload',
        meta: {
            title: "手机上传文件",
        },
        component: () => import("../views/upload/index")
    },
    {
        path: '/agreement/PrivacyAgreement',
        name: 'agreement',
        meta: {
            title: "超赞平台隐私协议",
        },
        component: () => import("../views/agreement/PrivacyAgreement.vue")
    },
    {
        path: '/agreement/CopyrightAgreement',
        name: 'agreement',
        meta: {
            title: "超赞平台版权协议",
        },
        component: () => import("../views/agreement/CopyrightAgreement.vue")
    },
    {
        path: '/manager/preview',
        meta: {
            isNav: false,
            icon: 'm-menu-10',
            title: i18n.t('m.menu.setting'),
        },
        component: () => import("../views-manager/preview/index")
    },
];

