<template>
    <div class="pointer" @click="input">
        <i-list-checkbox :strokeWidth="3" v-if="value"/>
        <i-waterfalls-h :strokeWidth="3" v-else/>
    </div>
</template>

<script>
    export default {
        name: "cz-material-selector-table-header-view-switch",
        props: {
            value: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            input() {
                this.$emit("input", !this.value)
            },
        }
    }
</script>

<style scoped>
    .pointer{
        cursor: pointer;
    }
</style>
