// import cookies from 'vue-cookies'
let domain = document.domain;
const CHAO_ZAN = "chaozan.cc";

if(domain.indexOf(CHAO_ZAN) > -1){
	domain = CHAO_ZAN;
}

let cache = {
	set(key,value){
		localStorage.setItem(key,value)
		// cookies.set(key,value,null,"/",domain);
	},
	get(key){
		// return cookies.get(key);
		return localStorage.getItem(key)

	},
	remove(key){
		localStorage.removeItem(key)
		// cookies.remove(key,"/",domain)
	},
	clear(){
		localStorage.clear()
		// cookies.keys().forEach(key => this.remove(key))
	},
};

export default {
    install:function (vue) {
        vue.prototype.$Cache = cache;
    },
	cache,
}
