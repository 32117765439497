<template>
    <el-dialog
            :visible.sync="visible_sync"
            :show-close="false"
            :append-to-body="true"
            @close="close"
            custom-class="cz-material-selector"
    >
        <template>
            <CzMaterialSelectorMenus v-model="globalData"/>
            <CzMaterialSelectorContent
                    @confirm="confirm"
                    @close="cancel"
                    v-model="globalData"
            />
        </template>
    </el-dialog>
</template>

<script>
    import CzMaterialFolder from "./components/cz-material-folder";
    import CzMaterialMemory from "./components/cz-material-memory";
    import CzMaterialSelectorMenus from "./components/cz-material-selector-menus";
    import CzMaterialSelectorContent from "./components/cz-material-selector-content";
    import i18n from "../../../locale";
    import store from "../../../store"

    export default {
        name: "cz-material-selector",
        components: {CzMaterialSelectorContent, CzMaterialSelectorMenus, CzMaterialMemory, CzMaterialFolder},
        data() {
            return {
                i18n: i18n,
                visible_sync: false,
                globalData: {
                    viewSwitch: true,
                    pagination: {
                        pageNum: 1,
                    },
                    activeCode: "",
                    selectNumber: 999,
                    search: '',
                    searchType: "0",
                    folders: [],
                    files: [],
                    activeFile: {
                        checked: false,
                        shiftStatus: false,
                        checkedNumber: 0,
                        activeFiles: []
                    },
                    activeFolder: {},
                    activeFileType: {},
                    activeImageType: {},
                    allActiveUrls: [],
                    allActiveFiles: []
                },
                fileTypes: [
                    {
                        title: i18n.t("materialSelector.image"),
                        accept: "image/png,.PNG,image/gif,.GIF,.jpg,.JPG,.jpeg,.JPEG,.svg,.SVG",
                        size: 1048576,
                        code: 0,
                        children: [
                            {
                                index: "1",
                                code: 1,
                                rootTitle: i18n.t("materialSelector.myUpload"),
                                title: i18n.t("materialSelector.myUpload"),
                                router: "CzMaterialSelectorTableDefault",
                                children: []
                            }, {
                                index: "2",
                                code: 0,
                                rootTitle: i18n.t("materialSelector.groupUpload"),
                                title: i18n.t("materialSelector.groupUpload"),
                                router: "CzMaterialSelectorTableDefault",
                                children: []
                            }, {
                                index: "3",
                                code: 2,
                                rootTitle: i18n.t("materialSelector.sys.title"),
                                title: i18n.t("materialSelector.sys.title"),
                                router: "CzMaterialSelectorTableSystem",
                                children: [
                                    {
                                        index: "3-4",
                                        code: 2,
                                        rootTitle: i18n.t("materialSelector.sys.title") + "-" + i18n.t("materialSelector.sys.bigImage"),
                                        title: i18n.t("materialSelector.sys.bigImage"),
                                        router: "CzMaterialSelectorTableSystem",
                                        parentIdsStr: "1,6",
                                        exclude: ["6"]
                                    }, {
                                        index: "3-5",
                                        code: 2,
                                        rootTitle: i18n.t("materialSelector.sys.title") + "-" + i18n.t("materialSelector.sys.littleImage"),
                                        title: i18n.t("materialSelector.sys.littleImage"),
                                        router: "CzMaterialSelectorTableSystem",
                                        parentIdsStr: "2,8",
                                        exclude: ["8"]
                                    }, {
                                        index: "3-6",
                                        code: 2,
                                        rootTitle: i18n.t("materialSelector.sys.title") + "-" + i18n.t("materialSelector.sys.bgImage"),
                                        title: i18n.t("materialSelector.sys.bgImage"),
                                        router: "CzMaterialSelectorTableSystem",
                                        parentIdsStr: "4",
                                        exclude: []
                                    }, {
                                        index: "3-7",
                                        code: 2,
                                        rootTitle: i18n.t("materialSelector.sys.title") + "-" + i18n.t("materialSelector.sys.icon"),
                                        title: i18n.t("materialSelector.sys.icon"),
                                        router: "CzMaterialSelectorTableSystemIcon",
                                        parentIdsStr: "3",
                                        exclude: []
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        rootTitle: i18n.t("materialSelector.video"),
                        title: i18n.t("materialSelector.video"),
                        size: 52428800,
                        accept: ".mp4,.MP4",
                        code: 1,
                        router: "CzMaterialSelectorTableDefault",
                        children: []
                    }
                ],
                callback: {
                    confirm: function () {
                    },
                    close: function () {
                    },
                }
            }
        },
        watch: {
            "globalData.allActiveUrls": {
                deep: true,
                immediate: true,
                handler() {
                    this.setActiveFile();
                }
            }
        },
        methods: {
            show(options) {
                this.callback.confirm = options.confirm;
                this.callback.close = options.close;
                switch (options.command) {
                    case "MY_UPLOAD":
                        this.globalData.activeCode = "1";
                        this.setFileType(0);
                        break;
                    case "GROUP_UPLOAD":
                        this.globalData.activeCode = "2";
                        this.setFileType(0);
                        break;
                    case "SYS_BIG_IMG":
                        this.globalData.activeCode = "3-4";
                        this.setFileType(0);
                        break;
                    case "SYS_LITTLE_IMG":
                        this.globalData.activeCode = "3-5";
                        this.setFileType(0);
                        break;
                    case "SYS_BG_IMG":
                        this.globalData.activeCode = "3-6";
                        this.setFileType(0);
                        break;
                    case "GROUP_VIDEO":
                        this.reset();
                        this.globalData.activeCode = "3-6";
                        this.setFileType(1);
                        break;
                    default:
                        this.globalData.activeCode = "1";
                        this.setFileType(0);
                        break;
                }
                this.globalData.selectNumber = options.selectNumber ? options.selectNumber : 1;
                this.globalData.allActiveUrls = options.value ? options.value.filter(value => (value)) : [];
                this.visible_sync = true;
            },
            setFileType(index) {
                this.globalData.activeFileType = this.fileTypes[index];
            },
            setActiveFile() {
                let _this = this;
                if (this.globalData.allActiveUrls && this.globalData.allActiveUrls.length > 0) {
                    _this.$watch("globalData.files", function (key, old) {
                        key.forEach(value => {
                            this.globalData.allActiveUrls.forEach(val => {
                                if (value.url && value.url === val) {
                                    _this.$set(value, "_checked", true);
                                }
                                if (value.file && value.file.url && value.file.url === val) {
                                    _this.$set(value, "_checked", true);
                                }
                            });
                        });
                    }, {
                        deep: true,
                        immediate: true,
                    });
                }
            },
            confirm() {
                this.visible_sync = false;
                this.$data.callback.confirm(this.globalData.allActiveUrls);
            },
            cancel() {
                console.log("cancel关闭");
                this.unSelectAll();
                this.visible_sync = false;
            },
            close() {
                this.unSelectAll();
                if (this.$data.callback.close) {
                    this.$data.callback.close();
                }
            },
            unSelectAll() {
                let _this = this;
                this.globalData.allActiveUrls = [];
                this.globalData.allActiveFiles = [];
                this.globalData.files.forEach(value => {
                    if (value.url) {
                        _this.$set(value, "_checked", false);
                    }
                    if (value.file && value.file.url) {
                        _this.$set(value, "_checked", false);
                    }
                });
            },
            reset() {
                this.globalData = {
                    viewSwitch: true,
                    pagination: {
                        pageNum: 1,
                    },
                    activeCode: "",
                    selectNumber: 1,
                    search: '',
                    searchType: "0",
                    folders: [],
                    files: [],
                    activeFile: {
                        checked: false,
                        shiftStatus: false,
                        checkedNumber: 0,
                        activeFiles: []
                    },
                    activeFolder: {},
                    activeFileType: {},
                    activeImageType: {},
                    allActiveUrls: [],
                    allActiveFiles: []
                };
            },
        },
        computed: {
            dataCache: {
                get() {
                    return store.state.cache.czMaterialSelector;
                },
                set(value) {
                    store.commit('cache/czMaterialSelector', value);
                }
            },
        }
    }
</script>

<style lang="less">
    @import "../../../assets/style/index.less";

    .cz-material-selector {
        width: 980px;
        user-select: none;

        .el-dialog__header {
            display: none !important;
        }

        .el-dialog__body {
            padding: 0;
            width: 100%;
            height: 560px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
    }
</style>
