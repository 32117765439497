import {POST} from '../request.js'

/**
 * 发送验证码
 * @param data
 * @returns {*}
 * @author Bcheng
 */
export const commitMsg = (data)=> {
    return POST('/smsc/commonAuthCode/getCaptcha',data);
};
