<template>
    <div class="cz-material-selector-menus">
        <div class="cz-material-selector-menus-header">
            <div class="cz-material-selector-menus-header-title">
                {{value.activeFileType.title}}
            </div>
            <div class="cz-material-selector-menus-header-search">
                <el-input @keyup.enter.native="change" size="small"
                          v-model="search"
                          :placeholder="i18n.t('materialSelector.pleaseEnterTitleName')">
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    <el-select @visible-change="visibleChange"
                               class="search-search"
                               slot="append"
                               v-model="value.searchType"
                    >
                        <el-option key="0" :label="getCurrentFileType" value="0"/>
                        <el-option key="1" label="文件夹" value="1"/>
                    </el-select>
                </el-input>
            </div>
        </div>
        <div class="cz-material-selector-menus-body">
            <div class="cz-material-selector-menus-body-new">
                <div v-if="!sysMaterial">{{i18n.t("materialSelector.folder")}}</div>
                <div v-else>{{value.activeImageType.title}}</div>
                <div v-if="!sysMaterial" @click="plusFolder" class="cz-material-selector-menus-body-new-plus">
                    <i-plus size="15"/>
                </div>
            </div>
            <div class="cz-material-selector-menus-body-folder">
                <czMaterialPlatFolders v-if="sysMaterial" v-model="value"/>
                <CzMaterialFolders v-else v-model="value" ref="materialFolders"/>
            </div>
        </div>
        <div class="cz-material-selector-menus-footer">
            <CzMaterialMemory v-model="value"/>
        </div>
    </div>
</template>

<script>
    import CzMaterialFolder from "./cz-material-folder";
    import CzMaterialMemory from "./cz-material-memory";
    import CzMaterialFolders from "./cz-material-folders";
    import CzMaterialPlatFolders from "./cz-material-plat-folders";
    import i18n from "../../../../locale";

    export default {
        name: "cz-material-selector-menus",
        components: {CzMaterialPlatFolders, CzMaterialFolders, CzMaterialMemory, CzMaterialFolder},
        props: {
            value: Object,
        },
        data() {
            return {
                i18n: i18n,
                search: '',
            }
        },
        watch: {
            "value.searchType": function () {
                this.value.search = "";
            },
            "value.search": function (val) {
                this.search = val;
            }
        },
        methods: {
            plusFolder() {
                this.$refs.materialFolders.plusFolder();
            },
            visibleChange(e) {
                if (e === false) {
                    this.change();
                }
            },
            change() {
                this.value.search = this.search;
            }
        },
        computed: {
            sysMaterial() {
                return this.value.activeImageType.code === 2;
            },
            getCurrentFileType() {
                return this.value.activeFileType.code === 1 ? ' 视频' : ' 图片';
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";

    .cz-material-selector-menus {
        border-right: solid 1px @color-border;
        width: 238px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .cz-material-selector-menus-header {
            height: 112px;
            width: 100%;
            border-bottom: solid 1px @color-border;
            padding: 16px 24px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .cz-material-selector-menus-header-title {
                width: 100%;
                height: 22px;
                font-size: 16px;
                color: #333333;
                font-weight: bold;
            }

            .cz-material-selector-menus-header-search {
                width: 100%;
                height: 32px;
                margin-top: 18px;

                .search-search {
                    width: 100px;
                    box-sizing: content-box;
                }

                & /deep/ .el-input-group__append {
                    padding: 0 !important;

                    .el-input__inner {

                        position: relative;
                        left: 38px;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                    }

                    .el-input--suffix {
                        right: 15px !important;
                    }
                }
            }
        }

        .cz-material-selector-menus-body {
            width: 100%;
            flex: 1;
            padding: 16px 24px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: hidden;


            .cz-material-selector-menus-body-new {
                width: 100%;
                height: 24px;
                font-size: 12px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;

                .cz-material-selector-menus-body-new-plus {
                    cursor: pointer;
                }
            }

            .cz-material-selector-menus-body-folder {
                width: 100%;
                flex: 1px;
                overflow: scroll;
                .hidden-scroll-bar;
            }
        }

        .cz-material-selector-menus-footer {
            padding: 16px 24px;
            height: 50px;
            width: 100%;
            box-sizing: border-box;
        }
    }
</style>
