<template>
    <div class="cz-material-selector-table-header-right">
        <CzMaterialSelectorTableHeaderDropdown v-model="value"/>
        <CzMaterialSelectorTableHeaderViewSwitch v-model="value.viewSwitch"/>
    </div>
</template>

<script>
    import i18n from "../../../../locale";
    import CzMaterialSelectorTableHeaderViewSwitch from "./cz-material-selector-table-header-view-switch";
    import CzMaterialSelectorTableHeaderDropdown from "./cz-material-selector-table-header-dropdown";

    export default {
        name: "cz-material-selector-table-header-right",
        components: {CzMaterialSelectorTableHeaderDropdown, CzMaterialSelectorTableHeaderViewSwitch},
        props: {
            value: Object
        },
        data() {
            return {
                i18n: i18n,
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";
    .cz-material-selector-table-header-right {
        width: 120px;
        height: 100%;
        box-sizing: border-box;
        padding-right: 17px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
</style>
