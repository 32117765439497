import {request,POST} from '../request.js'

/**
 * 获取微页面列表
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const getPageList = (data)=> {
    return POST('/making/page/getPageList',data);
};

/**
 * 拷贝微页面
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const copyPage = (data)=> {
    return POST('/making/page/copyPage',data);
};

/**
 * 创建微页面
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const createPage = (data)=> {
    return POST('/making/page/createPage',data);
};

/**
 * 删除微页面
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const delPage = (data)=> {
    return POST('/making/page/delPage',data);
};

/**
 * 设置为主页
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const makeIndexPage = (data)=> {
    return POST('/making/page/makeIndexPage',data);
};

/**
 * 查询主页
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const getIndexPage = (data)=> {
    return POST('/making/page/getIndex',data);
};

/**
 * 移动微页面
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const movePage = (data)=> {
    return POST('/making/page/movePage',data);
};

/**
 * 上架-下架
 * @param data
 * @returns {*}
 * @author Wuxr
 */
export const onOffPage = (data)=> {
    return POST('/making/page/onOffPage',data);
};

/**
 * 获取微页面详情
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const pageInfo = (data)=> {
    return POST('/making/page/pageInfo',data);
};

/**
 * 保存微页面信息
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const saveComposes = (data)=> {
    return POST('/making/page/saveComposes',data);
};
