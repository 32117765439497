import {POST} from '../request.js'


/**
 * 账户登录
 * @param data
 * @returns {*}
 * @author Mr.k
 */
// export const login = (data)=> {
//     return POST('/ac/token/accountLogin',data);
// };
export const login = (data)=> {
    return POST('/ac/token/adminLogin',data);
};

/**
 * 刷新token
 * @param data
 * @returns {*}
 * @author Mr.k
 */
export const refresh = (data)=> {
    return POST('/ac/token/refresh', data);
}

/**
 * 账户退出登录
 * @param data
 * @returns {*}
 * @author TreeJohn
 */
export const logout = ()=> {
    return POST('/ac/token/logout',null);
};
