import axios from 'axios'
import qs from 'qs'
import config from "../config/index"
import Vue from "vue";
import store from "../store/index";

let vue = new Vue();
import {refresh} from "./api/account";
import Cache from "../utils/Cache";

const UAParse = require('ua-parser-js')();

export const baseUrl = config.baseUrl;//在config目录下配置
export const imageUrl = config.imageUrl; //推荐使用$imageUrl

let isRefreshing = false;

export function req(headers, config) {
    let token = Cache.cache.get('access_token');
    let ip = localStorage.getItem('ip');

    headers['ip'] = ip;

    if (token) {
        headers = {
            ...headers,
            'Authorization': 'bearer ' + token,
            // 'miniProId': store.state.miniPro.miniProId,
        };
    }

    let browser = UAParse.browser;
    if (browser) {
        headers['browserName'] = browser.name;
        headers['browserVersion'] = browser.version;
    }


    const req = axios.create({
        baseURL: baseUrl,
        headers: headers,
        timeout: 60000,
    });

    req.interceptors.response.use(function (response) {
        let respData = response.data;

        if (respData.code === 301) {
            if (isRefreshing) {
                console.log("请求丢弃", req);
                return;
            }
            isRefreshing = true;
            //刷新token
            let data = {
                refreshToken: Cache.cache.get('refresh_token'),
                token: Cache.cache.get('access_token'),
            };
            return refresh(data).then(res => {
                if (res.code !== 200) {
                    //刷新token失败，直接退出
                    window.location.href = "/";
                }

                let access_token = res.data.access_token;
                let refresh_token = res.data.refresh_token;
                Cache.cache.set("access_token", access_token);
                Cache.cache.set("refresh_token", refresh_token);

                let newConfig = response.config;
                newConfig.headers['Authorization'] = `bearer ${access_token}`;
                return req(newConfig);

            }).catch(err => {
                // window.location.href = "/";
            }).finally(er => {
                isRefreshing = false;
            })
        }
        if (respData.code === 303 || respData.code === 304) {
            //强制重新登录
            vue.$alert('登录状态已失效，请重新登录', '提示', {
                confirmButtonText: '确定',
                showClose: false,
                closeOnClickModal: false,
                closeOnPressEscape: false,
                closeOnHashChange: false,
                callback: action => {
                    window.location.href = "/";
                }
            });
            return;
        }

        if (respData.code === 401) {
            vue.$message.error(respData.message);
            return;
        }
        return Promise.resolve(respData);
    }, function (error) {
        vue.$message.error("系统出错了..");
        return Promise.reject(error.response);
    });
    return req(config)
}

//post get 请求
export function request(config) {
    let headers = {
        'Content-type': 'application/x-www-form-urlencoded;',
    };

    if (config.method === 'post') {
        config.data = qs.stringify(config.data)
    }
    return req(headers, config);
}

export function requestJSON(config) {
    let headers = {
        'Content-type': 'application/json;',
    };
    return req(headers, config);
}

//上传文件
export function upload(headers, config) {
    return req(headers, config);
}

//上传文件
export function uploadFile(config) {
    let headers = config.headers || {};
    headers['Content-type'] = 'multipart/form-data';
    delete config.headers;
    return upload(headers, config);
}

export function POST(url, data) {
    return request({
        method: 'post',
        url: url,
        data: data
    })
}

//从服务器下载文件
export function DOWNLOAD_SERVER(url, data) {
    return request({
        method: 'get',
        url: url,
        params: data,
        responseType: 'blob'
    })
}

//从阿里云下载文件
export function DOWNLOAD(config) {
    const req = axios.create({
        baseURL: imageUrl,
        headers: {},
        timeout: 60000,
    });
    req.interceptors.response.use(function (response) {
        return Promise.resolve(response.data);
    }, function (error) {
        vue.$message.error("下载出错误...");
        return Promise.reject(error.response);
    });
    return req(config);
}

export function UPLOAD(url, formData, onProgress,headers) {
    return uploadFile({
        method: 'post',
        url: url,
        data: formData,
        headers:headers,
        onUploadProgress: onProgress
    })
}

export function UPLOAD_SET_HEADERS(headers, url, formData, onProgress) {
    return upload(headers, {
        method: 'post',
        url: url,
        data: formData,
        onUploadProgress: onProgress
    })
}
