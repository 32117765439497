<template>
    <div :class="['upload-wrapper',{'dragover':dragover}]"
         @drop.prevent="onDrop"
         @dragover.prevent="dragover = true"
         @dragleave.prevent="dragover = false">
        <input class="input" type="file" ref="input" multiple :accept="accept" @change="handleChange"/>
        <div class="process-container" v-if="tasks.length > 0">
            <div class="close-process" @click="clear()">清空</div>
            <div class="tasks">
                <div v-for="r in tasks">
                    <div class="single-line">
                        {{r.file.name}}
                        <span v-if="r.status === STATUS.EXCEPTION" class="exception">({{r.message}})</span>
                    </div>
                    <el-progress :percentage="r.percentage"
                                 :status="r.status"/>
                </div>
            </div>
        </div>
        <slot/>
        <div class="upload-action" @click="handleClick">
            <slot name="trigger"/>
        </div>
    </div>
</template>

<script>
    import {UPLOAD} from "../../../request/request";
    import i18n from "../../../locale";

    const  STATUS = {WAIT:'warning',EXCEPTION:'exception',SUCCESS:'success',UPLOADING:null};

    export default {
        name: "cz-upload",
        data() {
            return {
                STATUS:STATUS,
                i18n: i18n,
                dragover: false,
                Requests:[],
                Errors:[],
                uploading:false,
            }
        },
        computed:{
            tasks(){
                return [...this.Errors,...this.Requests];
            }
        },
        props:{
            //文件大小限制
            size:{
                type:Number,
                default:-1
            },
            //后缀名限制,多个用逗号分隔
            accept:String,
            //上传文件的接口
            action:{
                type:String,
                default:'/material/upload'
            },
        },
        methods: {
            onDrop(e) {
                this.dragover = false;
                let files = e.dataTransfer.files;
                this.beforeUpload(files);
            },

            handleClick(){
                this.$refs.input.value = null;
                this.$refs.input.click();
            },
            handleChange(e){
                const files = e.target.files;
                this.beforeUpload(files);
            },

            beforeUpload(files){
                if (files.length === 0) return;
                files.forEach(f=>{
                    if (!this.checkAccept(f)){
                        this.Errors.push({
                            file:f,
                            status:STATUS.EXCEPTION,
                            message:this.i18n.t('fileUpload.typeUnsupported',{type:this.accept}),
                            percentage:100,
                        });
                    }else if (!this.checkSize(f)){
                        let size = `${(this.size / 1024 / 1024).toFixed(1)}M`;
                        this.Errors.push({
                            file:f,
                            status:STATUS.EXCEPTION,
                            message:this.i18n.t('fileUpload.sizeUnsupported',{size:size}),
                            percentage:100,
                        });
                    }else {
                        this.Requests.push({
                            file:f,
                            status:STATUS.WAIT,
                            message:this.i18n.t('fileUpload.wait'),
                            percentage:0,
                        });
                    }
                });
                this.startUpload();
            },

            checkSize(f){
                if (this.size < 0) return true;
                return f.size <= this.size;

            },
            checkAccept(f){
                if (!this.accept || this.accept.length === 0) return true;

                const extension = f.name.indexOf('.') > -1 ? `.${ f.name.split('.').pop() }`: '';
                const baseType = f.type.replace(/\/.*$/, '');
                return this.accept.split(',').map(type => type.trim()).some(acceptedType => {
                    if (/\..+$/.test(acceptedType)) {
                        return extension === acceptedType;
                    }
                    if (/\/\*$/.test(acceptedType)) {
                        return baseType === acceptedType.replace(/\/\*$/, '');
                    }
                    if (/^[^\/]+\/[^\/]+$/.test(acceptedType)) {
                        return f.type === acceptedType;
                    }
                    return false;
                });

            },

            startUpload(){
                if (this.uploading) return;
                if (this.Requests.length === 0) return;

                let waitReq = this.Requests.find(r=>r.status === STATUS.WAIT);
                if (!waitReq) {
                    this.completeUpload();
                    return;
                }

                waitReq.status = STATUS.UPLOADING;
                this.uploadFile(waitReq);
            },

            completeUpload(){
                this.Requests = [];
                this.$emit('complete');
            },

            clear(){
                this.Errors = [];
                this.Requests = [];
            },

            uploadFile(req){
                let formData = new FormData();
                formData.append("file",req.file);
                let _this = this;

                return UPLOAD(this.action,formData,(res)=>{
                    req.percentage = Math.round(100.0 * res.loaded / res.total);
                }).then(res=>{
                    req.percentage = 100;
                    req.status = STATUS.SUCCESS;
                    _this.$emit('change',{file:req.file, response:res});
                }).catch(err=>{
                    req.percentage = 100;
                    req.status = STATUS.EXCEPTION;
                    req.message = err.message;
                }).finally(res=>{
                    _this.uploading = false;
                    _this.startUpload();
                })
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";

    .upload-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        border: 2px dashed transparent;

        &.dragover {
            border: 2px dashed @color-primary;
        }

        .input{
            display: none;
        }

        .upload-action{
            cursor: pointer;
        }

        .process-container{
            z-index: 20;
            background-color: white;
            padding: 10px;
            box-sizing: border-box;
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            box-shadow:  0 -2px 4px @color-border;
            .tasks{
                max-height: 200px;
                .hidden-scroll-bar;
                overflow-y: scroll;
            }
            .exception{
                color: #f56c6c;
            }
            .close-process{
                position: absolute;
                top: 0;
                right: 10px;
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                user-select: none;
                background: white;
                padding: 2px;
            }
        }
    }
</style>
