import Vue from "vue";
let vue = new Vue();

export default {
    install:function (v) {
        v.prototype.$MESSAGE = function(resp,
                                        success=()=>{},
                                        error=()=>{},
                                        onClose=()=>{}){

            resp.code === 200 ? success() : error();

            if (!resp.message) return;
            vue.$message({
                message:resp.message,
                offset: 45,
                type:resp.code === 200?'success':'error',
                onClose:onClose
            })
        }
    }
}


