//图片请求地址
let imageUrl = "https://youpinimg.oss-cn-beijing.aliyuncs.com/";

//api请求地址
let baseUrls = {
    'admin.chaozan.cc':'https://api.chaozan.cc/',
    '192.168.100.200':'http://192.168.100.200:9527/',
    '127.0.0.1':'http://192.168.100.100:9527/',
    '192.168.100.100':'https://api.chaozan.cc/',
    '192.168.100.101':'http://192.168.100.101:9527/',
    'localhost':'http://192.168.100.200:9527/',
    '192.168.100.106':'http://192.168.100.138:9527/',
    '192.168.100.138':'http://192.168.100.138:9527/',
};

//预览端地址
let previewUrls = {
    'admin.chaozan.cc':'https://preview.chaozan.cc',
    '192.168.100.200':'http://192.168.100.200:8081',
    '127.0.0.1':'http://192.168.100.200:8081',
    'localhost':'http://192.168.100.200:8081',
};


//官网地址
let officialUrl = "https://chaozan.cc";
//帮助中心
let helpUrl = "https://www.yuque.com/u22654009/chaozan/";
//反馈地址
let feedbackUrl = "https://support.qq.com/products/368263";

let previewUrl = previewUrls[document.domain] || 'https://preview.chaozan.cc';

let baseUrl = baseUrls[document.domain] || "https://api.chaozan.cc/";

let uploadPageUrl = `${window.location.protocol}//${window.location.host}`;

export default {

    install:function (vue) {
        vue.prototype.$baseUrl = baseUrl;
        vue.prototype.$uploadPageUrl = uploadPageUrl;
        vue.prototype.$imageUrl = imageUrl;
        vue.prototype.$previewUrl = previewUrl;
        vue.prototype.$officialUrl = officialUrl;
        vue.prototype.$remScale = 2;   //px=>rem的倍率 1px = 2rem
    },
    baseUrl,
    imageUrl,
    previewUrl,
    officialUrl,
    helpUrl,
    feedbackUrl,
}
