<template>
    <div class="cz-material-selector-table-wrapper">
        <div class="cz-material-selector-table-search-wrapper"
             v-if="value.search && value.searchType === '0'"
        >
            <CzMaterialSelectorTableSearch
                    v-model="value"
            />
        </div>
        <div class="cz-material-selector-table-component-wrapper">
            <component
                    :is="value.activeImageType.router"
                    v-model="value"
                    ref="materialSelectorTableComponent"
            />
        </div>
    </div>
</template>

<script>
    import CzMaterialSelectorTableSearch from "./cz-material-selector-table-search";
    import CzMaterialSelectorTableDefault from "./cz-material-selector-table-default";
    import CzMaterialSelectorTableSystem from "./cz-material-selector-table-system";
    import CzMaterialSelectorTableSystemIcon from "./cz-material-selector-table-system-icon";

    export default {
        name: "cz-material-selector-table",
        components: {
            CzMaterialSelectorTableSystemIcon,
            CzMaterialSelectorTableSystem,
            CzMaterialSelectorTableDefault,
            CzMaterialSelectorTableSearch,
        },
        props: {
            value: Object,
        },
        methods: {
            clickUpload() {
                return this.$refs.materialSelectorTableComponent.clickUpload();
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";

    .cz-material-selector-table-wrapper {
        width: 100%;
        flex: 1;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;
        box-sizing: border-box;

        .cz-material-selector-table-search-wrapper {
            width: 100%;
            height: 40px;
        }

        .cz-material-selector-table-component-wrapper {
            width: 100%;
            flex: 1;
            overflow: hidden;
        }
    }
</style>
