<template>
    <div class="cz-material-selector-default-table">
        <div class="cz-material-selector-table-header">
            <div class="cz-material-selector-table-header-left">
                <CzMaterialSelectorTableHeaderCheckbox
                        @allChecked="allChecked"
                        v-model="value"
                />
            </div>
            <div class="cz-material-selector-table-header-right">
                <CzMaterialSelectorTableHeaderRight v-model="value"/>
            </div>
        </div>
        <div class="cz-material-selector-table-content">
            <CzMaterialPaltSelectorFiles
                    ref="materialPaltSelectorFiles"
                    v-model="value"
                    :view="value.viewSwitch"
            />
        </div>
    </div>
</template>

<script>
    import i18n from "../../../../locale";
    import store from "../../../../store";
    import CzMaterialSelectorFiles from "./cz-material-selector-files";
    import CzUpload from "../../cz-upload/cz-upload";
    import CzMaterialPaltSelectorFiles from "./cz-material-palt-selector-files";
    import CzMaterialSelectorTableHeaderCheckbox from "./cz-material-selector-table-header-checkbox";
    import CzMaterialSelectorTableHeaderRight from "./cz-material-selector-table-header-right";

    export default {
        name: "cz-material-selector-table-system",
        components: {
            CzMaterialSelectorTableHeaderRight,
            CzMaterialSelectorTableHeaderCheckbox,
            CzMaterialPaltSelectorFiles, CzUpload
        },
        props: {
            value: Object
        },
        methods: {
            allChecked(checked) {
                this.$refs.materialPaltSelectorFiles.allCheckedChanged(checked);
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";

    .cz-material-selector-default-table {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .pointer {
            cursor: pointer;
        }

        .cz-material-selector-table-header {
            width: 100%;
            height: 52px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .cz-material-selector-table-header-left {
                flex: 1;
                height: 100%;
                box-sizing: border-box;
                padding-left: 17px;
                color: #415058;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                font-size: 14px;

                .cz-material-selector-table-header-left-checkbox {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    & div {
                        .flex-center;
                    }

                    & div:nth-child(1) {
                        width: 80px;
                        margin-left: -20px;
                    }

                    & div:nth-child(2) {
                        width: 10px;
                    }

                    & div:nth-child(3) {
                        width: 80px;
                    }
                }

                .cz-material-selector-table-header-left-tool {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    & div {
                        .flex-center;
                        cursor: pointer;
                    }

                    & div:nth-child(1) {
                        width: 80px;
                    }

                    & div:nth-child(2) {
                        width: 50px;
                    }

                    & div:nth-child(3) {
                        width: 50px;
                        cursor: initial;
                    }

                    & div:nth-child(4) {
                        color: @color-primary;
                    }
                }

            }

            .cz-material-selector-table-header-right {
                width: 120px;
                height: 100%;
                box-sizing: border-box;
                padding-right: 17px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }

        .cz-material-selector-table-content {
            width: 100%;
            flex: 1;
            box-sizing: border-box;
            overflow: scroll;
            .hidden-scroll-bar;
        }
    }
</style>
