<template>
    <div id="app">
        <keep-alive>
           <router-view v-if="$route.meta && $route.meta.cached"/>
        </keep-alive>
        <router-view v-if="!$route.meta || !$route.meta.cached"/>
    </div>
</template>

<script>
    export default {
        data() {
            return {}
        },
        components: {},
    }
</script>

<style>
    *{
        padding: 0;
        margin: 0;
    }

    html,body{
        -webkit-text-size-adjust:none;
        padding: 0;
        margin: 0;
        background: white;
        font-size: 12px;
        width: 100vw;
        height: 100vh;
        min-width: 1200px;
        min-height: 700px;
        color:#333333;
        font-family: PingFang SC,"PingFangSC-Regular","Arial","Microsoft YaHei","黑体","宋体",sans-serif;
    }

    #app {
        width: 100%;
        height: 100%;
    }

    * {
      scrollbar-width: thin;
    }
    ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #d2d2d2;
        background-clip: padding-box;
        border-radius: 2px;
    }

    ::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #F5F5F5;
    }
    .el-popover{
        min-width: 100px!important;
    }
</style>
