<template>
    <div class="cz-material-memory">
        <div class="cz-material-memory-info">
            <span>{{getKBMemory(usedMemory)}}/{{totalMemory}}G</span>
            <!--<span class="renew">{{i18n.t("materialSelector.vtRenew")}}</span>-->
        </div>
        <div class="cz-material-memory-progress">
            <el-progress
                    :show-text="false"
                    :percentage="Math.round(((usedMemory ? usedMemory : 0) / ( totalMemory * 1024 * 1024)) * 100)"
            />
        </div>
    </div>
</template>

<script>
    import {geMerchantMemoryUsage} from "../../../../request/api/material";
    import i18n from "../../../../locale";
    export default {
        name: "cz-material-memory",
        props: {
            value: Object,
        },
        data(){
          return {
              i18n: i18n,
              totalMemory: 2,
              usedMemory: 500000,
              setInterval: undefined,
          }
        },
        mounted() {
            let _this = this;
            _this.geMerchantMemoryUsageInfo();
            _this.setInterval = setInterval(function () {
                _this.geMerchantMemoryUsageInfo();
            }, 1000 * 60 * 5);
        },
        methods: {
            geMerchantMemoryUsageInfo() {
                let _this = this;
                geMerchantMemoryUsage({
                    "merchantId": _this.$MERCHANT().merchantId,
                }).then((result) => {
                    if (result.code === 200){
                        _this.usedMemory = result.data.size;
                    }
                })
            },
            getKBMemory(size) {
                let memoryUnits = ["KB", "MB", "GB"];
                for (let memoryUnit of memoryUnits) {
                    if (size > 1024){
                        size = (size / 1024);
                    }else {
                        if (memoryUnit === memoryUnits[2]){
                            return Math.round(size ? size : 0).toFixed(2) + memoryUnit;
                        }else {
                            return Math.round(size ? size : 0) + memoryUnit;
                        }
                    }
                }
            },
        },
        beforeDestroy() {
            if (this.setInterval){
                clearInterval(this.setInterval);
                this.setInterval = undefined;
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";
    .cz-material-memory {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .cz-material-memory-info {
            width: 89%;
            height: 20px;
            .renew {
                color: @color-primary;
                float: right;
                margin-right: 10px;
                cursor: pointer;
            }
            margin-bottom: 10px;
        }

        .cz-material-memory-progress {
            width: 100%;
            flex: 1;
            margin-bottom: 15px;
        }
    }
</style>
