import Cache from "../utils/Cache"
export default {
    install:function (vue) {
        vue.prototype.$USER = function () {
            if (arguments.length === 0){
                //getter
                return Cache.cache.get("currentUser");
            }else {
                //setter
                Cache.cache.set('currentUser',JSON.stringify(arguments[0]));
            }
        };

        vue.prototype.$MERCHANT = function () {
            if (arguments.length === 0){
                //getter
                return Cache.cache.get("currentMerchant");
            }else {
                //setter
                Cache.cache.set('currentMerchant',JSON.stringify(arguments[0]));
            }
        }
    }
}
