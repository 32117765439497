<template>
    <el-popover placement="bottom" width="160" v-model="visible" trigger="click">
        <div style="margin-bottom: 5px;">{{i18n.t("materialSelector.rename")}}</div>
        <el-input size="mini" :placeholder="placeholder" v-model="data.materialName" />
        <div style="text-align: right; margin-top: 10px;">
            <el-button size="mini" type="text" @click="visible = false">{{i18n.t("materialSelector.cancel")}}</el-button>
            <el-button type="primary" size="mini" @click="onClick">{{i18n.t("materialSelector.confirm")}}</el-button>
        </div>
        <template slot="reference">
            <slot name="reference"/>
        </template>
    </el-popover>
</template>

<script>
    import i18n from "../../../../locale";
    export default {
        name: "cz-input-popconfirm",
        props: {
            data: Object,
            placeholder: String,
        },
        data() {
            return {
                i18n: i18n,
                visible: false
            }
        },
        methods: {
            onClick() {
                this.visible = false;
                this.$emit("confirm", this.data)
            },
        }
    }
</script>

<style scoped>

</style>
