import _ from "lodash"
import {allComponentsGroupList} from "../../request/api/making"

export default {
    namespaced: true,
    state: {
        //系统中的组件列表
        composeList: [],
        //缓存的composeList key:target,value:compose,为了能通过target快速查询
        composeMap: {},

        //手机屏幕缩放的比例
        phoneScale: 1,

        //phone区域当前激活的组件id
        activeComposeId: null,
        //自由容器内部激活的组件ids
        freedomActiveIds: [],

        //粘贴板,ctrl + c 后放到里面,结构为作用域+数据
        //{scope,data}
        clipboard: {},

        czMaterialSelector: {
            activeCode: "",
            selectNumber: 999,
            search: '',
            folders: [],
            files: [],
            activeFile: {
                checked: false,
                shiftStatus: false,
                checkedNumber: 0,
                activeFiles: []
            },
            activeFolder: {},
            activeFileType: {},
            activeImageType: {},
            allActiveUrls: [],
            allActiveFiles: []
        },
    },
    mutations: {
        czMaterialSelector(state, data) {
            state.czMaterialSelector = data;
        },
        composeList(state, list) {
            let map = {};
            list.forEach(g => {
                g.componentInfos.forEach(c => {
                    map[c.target] = c;
                });
            });
            state.composeList = list;
            state.composeMap = map;
        },

        activeComposeId(state, activeComposeId) {
            state.activeComposeId = activeComposeId;
        },

        clipboard(state, clipboard) {
            state.clipboard = clipboard;
        },

        phoneScale(state, phoneScale) {
            state.phoneScale = phoneScale;
        },
        freedomActiveIds(state, ids) {
            state.freedomActiveIds = ids;
        },

        /**
         * 在适当的时候需要清空state里面的数据，否则会数据混乱
         * @param state
         */
        clear(state) {
            state.composeList = [];
            state.composeMap = {};
            state.freedomActiveIds = [];
            state.activeComposeId = null;
            state.clipboard = {};
        }
    },

    getters: {
        //通过target 查询单个
        getComposeByTarget(state) {
            return (target) => {
                return _.cloneDeep(state.composeMap[target] || {});
            }
        },

        //通过Underlying 查询列表
        getComposeByUnderlying(state) {
            return (underlying) => {
                let list = [];
                state.composeList.forEach(g => {
                    g.componentInfos.forEach(c => {
                        if (c.underlying === underlying) {
                            list.push(c)
                        }
                    });
                });
                return _.cloneDeep(list);
            }
        },
    },

    actions: {
        //初始化所有的组件数据
        initAllComponentGroup({commit}) {
            allComponentsGroupList({}).then(res => {
                commit("composeList", res.data);
            })
        }
    }
}
