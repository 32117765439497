<template>
    <div class="cz-material-selector-table-header-left-checkbox">
        <div>
            <el-checkbox @change="allChecked"
                         v-model="value.activeFile.checked"
            >
                {{i18n.t("materialSelector.checkAll")}}
            </el-checkbox>
        </div>
        <div>|</div>
        <div>{{i18n.t("materialSelector.theSelected", {checkedNumber: value.activeFile.checkedNumber})}}
        </div>
    </div>
</template>

<script>
    import i18n from "../../../../locale";

    export default {
        name: "cz-material-selector-table-header-checkbox",
        props: {
            value: Object
        },
        data(){
            return {
                i18n: i18n,
            }
        },
        methods: {
            allChecked(e) {
                this.$emit('allChecked', e)
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";
    .cz-material-selector-table-header-left-checkbox {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        & div {
            .flex-center;
        }

        & div:nth-child(1) {
            width: 80px;
            margin-left: -20px;
        }
        & div:nth-child(2) {
            width: 10px;
        }
        & div:nth-child(3) {
            width: 80px;
        }
    }
</style>
