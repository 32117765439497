export default {
    header: {
        createCompanyOrganization: "创建公司/组织",
        nameNotSet: "未设置姓名",
        mailboxIsNotSet: "未设置邮箱",
        personalData: "个人资料",
        enterpriseInformation: "企业资料",
        appletOrder: "小程序订单",
        memberManagement: "成员管理",
        visitTheOfficialWebsite: "访问官网",
        switchEnterprise: "切换企业",
        logOut: "退出登录",
        exitSuccessful: "退出成功",
    },
    notice: {
        title: "通知",
        noMessage: "暂无消息",
        markAllAsRead: "标记全部为已读",
    },
    login: {
        professionalAppletDesignTool: "专业级小程序设计工具",
        professionalAppletDesignToolDescription: "专为小程序设计研发，设计师和产品经理也可用制作小程序，无需做原型，无需任何开发人员。",
        professionalAppletDesignToolOther: "一次制作多平台发布，未来将陆续支持各大平台",
        chaozan: "登录到超赞管理端",
        forgetPassword: "忘记密码",
        noAccount: "没有账号？",
        freeRegistration: '免费注册',
        weChatScanLogin: '微信扫一扫登录',
        scanCodePublicLogin: '扫码关注公众号登录',
        otherLoginMethods: '其他登录方式',
        return: "返回",
        useAccountPasswordLogin: '使用账号密码登录',
        weChatScanRegistration: '微信扫一扫注册',
        scanCodePublicRegistration: '扫码关注公众号注册',
        otherRegistrationMethods: '其他注册方式',
        signGreatAccountChaozan: '注册超赞账号',
        signOfApproval: '注册即表示同意超赞',
        userServiceAgreement: '《用户服务协议》',
        alreadyAccount: '已有帐号？',
        text: '登录',
        nextStep: '下一步',
        resetPasswords: '重置密码',
        set8DigitsSecretLetterCombination: '设置8位以上数字, 字母组合的密码',
        confirmSecretCode: '确认密码',
        intoGreatChaoZan: '进入超赞',
        createAddEnterpriseInformation: '创建或加入企业信息',
        enterImmediately: '上次进入时间 {key}',
        theEnterprise: '于{key}邀请你加入',
        startYourBusinessOrganization: '创建自己的企业/组织',
        improveEnterpriseInformationOrganization: '完善企业信息, 创建自己的企业/组织',
        improveEnterpriseInformation: '完善企业信息',
        merchantNameCompanyName: '商户名称/公司名称',
        scaleCompany: '公司规模',
        industryInvolved: '所属行业',
        pleaseMerchantNameCompanyName: '请输入商户名称/公司名称',
        pleaseSelect: '请选择',
        contactNumber: '联系电话',
        pleaseEnterContactNumber: '请输入联系电话',
        pleaseEnterEmailAddress: '请输入邮箱',
        pleaseEnterMerchantDetailedAddress: '请输入商户详细地址',
        email: '邮箱',
        detailedAddressMerchant: '商户详细地址',
        lastStep: '上一步',
        enteringWorkspace: '进入工作区',
        completeInformation: '完善信息',
        bindThirdPartyAccounts: '绑定第三方账号',
        username: '用户名',
        password: '密码',
        name: '名称',
        enterPin: '请输入密码',
        completeRegistrationEnterSuper: '完成注册, 进入超赞',
        mobile: '手机号',
        hint: '提示',
        pleaseEnterMerchantNameCompanyName: '请输入商户名称或者公司名称',
        pleaseEnterCellPhoneNumber: '请输入联系电话',
        pleaseInputCorrectMobilePhoneNumber: '请输入正确的联系电话',
        pleaseEnterCorrectEmailAddress: '请输入正确的邮箱',
        pleaseEnterCompanySize: '请输入公司规模',
        pleaseEnterIndustryCompany: '请输入公司所属行业',
        error: '错误',
        pleaseEnterPasswordAgain: '请再次输入密码',
        inconsistentPasswordsEnteredTwice: '两次输入密码不一致!',
        lengthBetween8And16Characters: '长度在 8 到 16 个字符',
        pleaseConfirmPassword: '请确认密码',
        succeed: '成功',
        captchaCodeSentSuccessfully: '验证码发送成功',
        language: '语言',
        chinese: '中文',
        pleaseInputPhoneNumberOrEmail: '请输入手机号或者邮箱',
        pleaseEnterVerificationCode: '请输入验证码',
        sendVerificationCode: '发送验证码',
        retry: '重试',
        clicktheRefresh: '点击刷新',
        errorGettingQRCode: '获取二维码错误'
    },
    menu: {
        create: '创建',
        myMiniPro: '小程序',
        materialCenter: '企业素材',
        manager: '管理',
        services: '定制服务',
    },
    making: {
        pages: {
            systemPage: "功能页",
            microPage: "微页面",
            homePageDoesNotSupportDeletion: "首页不支持删除",
            homePageDoesNotSupportSettings: "首页不支持设置",

        },
        aside: {
            page: '页面',
            template: '模板',
            compose: '组件',
            material: '素材',
            materialTips:"超赞平台免费素材",
            mine: '我的',
            mineTips:"我和企业上传的素材",
            products: '商品',
            information: '文章',

        },
        guide: {
            next: '下一步',
            jump: '跳过',
            step1: {
                title: '资源面板',
                content: '小程序是由多个页面组成的，通过资源面板，你可以很方便的切换页面、素材和组件，然后将需要的产品、文章、组件或者图片拖放到页面编辑区中。'
            },
            step2: {
                title: '页面编辑区',
                content: '页面是由很多组件组成的，在编辑区可以放置不同的组件，实现不同的展示效果和功能。组件支持复制、删除、排列等操作，帮助你快速搭建页面。'
            },
            step3: {
                title: '页面/组件设置区',
                content: '在这里可以设置页面/组件的显示样式和内容，包括位置、大小、颜色、背景、透明度、阴影、间距以及点击等。'
            },
            step4: {
                title: '功能区',
                content: '左侧快捷组件包含最常用的组件，可直接拖入页面中；中间是保存、撤销、重做、图层等操作；右侧是小程序分享、发布、帮助等操作按钮和菜单。',
                nextButton: '准备好了，让我们开始吧！'
            },
        },
        scale: {
            help: '帮助',
            zoomIn: '放大',
            zoomOut: '缩小',
        },
        themeColor: {
            title: '主题色',
            history: '最近使用：{color}',
            preset: '主题色：{color}',
        },
        header: {
            save: '保存',
            next: '下一步',
            prev: '上一步',
            share: '分享',
            preview: '预览',
            shrink: '菜单收缩',
            member: '协作人员',
            edition: '版本记录',
            release: "去发布",
            text: "文本",
            juxing: "矩形框",
            yuan: "圆",
            tupian: "图片",
            manager: "跳转到管理端",
            layerAlign: {
                moveUp: '上移一层',
                moveDown: '下移一层',
                moveTop: '置顶',
                moveBottom: '置底',
            },
            toManager: '管理端',
            tip: '提示',
            tipSave: '即将跳转到管理端，是否立即保存此页面',
            help: {
                video: '视频教程',
                setting: '偏好设置',
                keyboard: '快捷键',
                guide: '新手引导',
                center: '帮助中心',
                customer: '在线客服',
            }
        },
        share: {
            title: '分享',
            status: '状态',
            closed: '已关闭',
            open: '已开启',
            auth: '权限',
            link: '地址',
            tip: '请用手机扫一扫查看',
            cancel: '取消',
            copy: '复制链接',
            andPwd: '及密码',
            type0: '所有人',
            type1: '仅企业成员',
            type2: '仅小程序成员',
            type3: '密码查看',
        },
        phone: {
            nonePage: '请选择页面'
        },
        properties: {
            video: {
                videoSource: "视频源",
                videoFileName: '视频文件名称',
                select: '选择',
                cover: "封面"
            },
            design: '设计',
            data: '数据',
            datasource: {
                title: '数据源',
                all: '所有',
                goodsgroup: '商品分组',
                informationgroup: '文章分组',
                allgoods: '所有商品',
                allinformation: '所有文章',
            },
            hotword: {
                title: '热词'
            },
            tabbar: {
                style: '样式',
                navigation: '导航',
                action: '动作',
                icon: '图标（点图标替换图片）',
            },
            group: {
                text: '文本',
                appearance: '外观',
                action: '动作',
            },
            alignment: {
                alignHorizontalEnds: '水平等间距',
                alignVerticalEnds: '垂直等间距',
                alignLeft: '左对齐',
                alignRight: '右对齐',
                alignTop: '顶对齐',
                alignBottom: '底对齐',
                alignHorizontalCenter: '水平居中',
                alignVerticalCenter: '垂直居中',
            },
            opacity: '透明度',
            fixed: {
                title: '运行时固定位置',
                none: '不固定',
                top: '相对顶部固定',
                bottom: '相对底部固定',
            },
            font: {
                weight300: '细体',
                weight400: '正常',
                weight600: '粗体',
            },
            textAlign: {
                left: '左对齐',
                center: '水平居中',
                right: '右对齐',
                top: '顶对齐',
                middle: '垂直居中',
                bottom: '底对齐',
            },

            backgroundColor: '背景色',
            backgroundImage: '背景图',
            boxBodyColor: '框体颜色',
            color: '颜色',

            boxShadow: {
                title: '阴影',
                shadow: '模糊',
                diffusion: '扩散',
            },
            padding: {
                title: '内边距',
                left: '左',
                top: '上',
                bottom: '下',
                right: '右'
            },
            action: {
                click: {
                    title: '单击'
                }
            },
            position: {
                x: 'X',
                y: 'Y',
                width: '宽',
                height: '高',
                rotation: '旋转',
                borderRadius: '圆角',
            },
            scroll: {
                text: '滚动',
                speed: '速度',
                speedSlow: '慢速',
                speedNormal: '正常',
                speedFast: '快速',
                direction: '方向',
                directionLeft: '向左',
                directionRight: '向右',
                content: '内容',
                contentPlaceholder: '滚动内容',
            },
            swiper: {
                title: '轮播图',
                text: "样式",
                name: "图片",
                designSwiper: "轮播",
                designScroll: "滚动",
                designGrid: "一行两个",
                column1: "一行一个",
                bigger: "大图模式",
                setting: "设置",
                add: '添加'
            },
            colorPicker: {
                title: '颜色设置',
                clear: '清空',
                close: '关闭',
                reset: '重置',
            },
            background: {
                size: {
                    title: '图片大小',
                    contain: '容纳',
                    cover: '覆盖',
                    fill: '填充',
                    auto: '默认',
                },
                repeat: {
                    title: '重复效果',
                    x: '水平',
                    y: '垂直',
                    xy: '水平垂直',
                    none: '不重复',
                },
                position: {
                    title: '图片位置',
                    lt: '左 上',
                    ct: '中 上',
                    rt: '右 上',
                    lc: '左 中',
                    cc: '中 中',
                    rc: '右 中',
                    lb: '左 下',
                    cb: '中 下',
                    rb: '右 下',
                }
            },
            page: {
                navigation: '导航栏',
                hideNavBar: '隐藏导航栏',
                pageBackground: '整体背景',
                title: '标题',
                description: '描述',

                start: { // 启动页
                    title: '启动页',
                    conf: '启动配置',
                    isStart: '是否启用启动页',
                    showSeconds: '显示秒数',
                    skipSeconds: '可跳过秒杀'
                }
            }
        },
        compose: {
            txt: {
                title: '文本'
            },
            riceTxt: {
                title: '富文本',
                length: '已经输入:{length}个字符。'
            },
            freeContainer: {
                title: '自由容器',
                height: '高度',
            },
            line: {
                title: '线',
                lineWidth: '线宽'
            },
            rectangle: {
                title: '矩形框',
            },
            circle: {
                title: '圆'
            },
            img: {
                title: '图片',
                none: '点击更换图片',
                change: '更换',
                clip: {
                    title: '裁剪',
                    scale: {
                        origin: '原比例',
                        free: '自由比例'
                    },
                },
            },
            video: {
                text: '视频'
            },
            button: {
                title: '按钮',
                style: {
                    s1: '样式一',
                    s2: '样式二',
                    s3: '样式三',
                    s4: '样式四',
                }
            },
            border: {
                title: '描边',
                solid: '实线',
                dashed: '虚线',
                dotted: '点线',
            },
            page: {
                title: '页面'
            },
            notice: {
                title: '公告'
            },
            search: {
                title: '搜索'
            },
            tabbar: {
                title: '底部导航',
                error: {
                    max: '最多添加5个页面',
                    min: '至少添加2个页面',
                    home: '首页不能删除',
                }
            },
            navigator: {
                title: '导航',
                type: {
                    text: '文字导航',
                    textImage: '图文导航',
                },
                add: '新增',
            },
            pageCard: {
                title: '页卡',
                pagePath: '指向页面',
                line: '指示线',
                type: {
                    text: '文字',
                    textImage: '图文',
                },
                add: '新增',
                to: '前往'
            },
            contentLayout: {
                title: '内容形式',
                type: {
                    iconPlusText: '图标+文字',
                    icon: '图标',
                    text: '文字',
                }
            },
            navigatorColor: {
                title: '导航颜色',
                type: {
                    custom: '自定义',
                    wholeStyle: '整体风格',
                },
                custom: {
                    selected: '选中',
                    unselected: '未选中',
                },
            },
            form: {
                title: '表单',
                submitCount: '提交次数',
                countOne: '一次',
                countNone: '不限',
                submit: {
                    title: '提交按钮',
                    text: '标题'
                },
                submitAfter: '提交后',
                placeholder: '提示',
                required: '必选项',
                hidden: '隐藏',
                inputPlaceholder: '请输入',
                noForm: '请拖入表单组件或者绑定表单',
                data: {
                    title: '绑定表单',
                    tip: '请从下列表单列表中，绑定一个表单',
                    add: '添加',
                    manager: '管理',
                    refresh: '刷新',
                    action: '操作',
                    showData: '查看数据',
                },
                input: {
                    title: '单行输入',
                },
                textarea: {
                    title: '多行输入'
                },
                radio: {
                    title: '单项选择器',
                    options: '选项',
                    optionsOnline: '每行一个选项',
                },
                checkbox: {
                    title: '多项选择器'
                },
                select: {
                    title: '单列选择器'
                },
                date: {
                    title: '日期选择器',
                    range: '日期范围',
                    rangePlaceholder: '请选择日期范围',
                    option: '日期选项',
                    optionPlaceholder: '请选择日期选项',
                    today: '含今天',
                    all: '所有',
                    pass: '过去',
                    future: '未来',
                    weekend: '周末',
                    weekdays: '工作日',
                    include: '包含',
                    exclude: '不包含',
                },
                time: {
                    title: '时间选择器',
                    options: '时间选项',
                    disable: '屏蔽时间',
                    disablePlaceholder: '请选择屏蔽时间',
                    default: '默认',
                    custom: '自定义',
                    hour: '一小时',
                    half: '30分钟',
                    quarter: '15分钟',
                },
                region: {
                    title: '地理位置',
                    manual: '手动标记',
                },
                file: {
                    title: '文件上传',
                    type: '文件类型',
                    typePlaceholder: '请选择文件类型',
                    img: '图片',
                    video: '视频',
                    doc: '文档',
                    button: '点击上传',
                    maxSize: '大小限制',
                    maxSizePlaceholder: '请输入文件大小限制',
                },
                phone: {
                    title: '手机号码',
                    type: '方式',
                    typePlaceholder: '请选择获取方式',
                    weChat: '微信授权',
                    manual: '手动填写',
                    validate: '短信验证',
                    code: '获取验证码',
                    auth: '点击获取',
                    inputCode: '请输入验证码'
                },
                area: {
                    title: '省市区县',
                    hidden: '隐藏',
                    province: '省/自治区/直辖市',
                    city: '市',
                    county: '区县',
                    addressPlaceholder: '详细地址的提示语',
                }
            },
            goods: {
                title: '商品',
                typeset: '排版',
                big: '大图模式',
                column1: '一行一个',
                column2: '一行两个',
                column3: '一行三个',
                big2Small: '一大两小',
                scrollX: '横向滑动',
                list: '列表',
                image: {
                    title: '图片',
                    spacing: '间距',
                    borderRadius: '圆角'
                },
                style: {
                    title: '样式',
                    none: '无边白',
                    shadow: '投影',
                    border: '描边白',
                },
                text: {
                    title: '标题',
                    name: '商品名称',
                    desc: '商品描述',
                    price: '商品价格',
                    badge: '商品角标',
                    badgeStyle: {
                        hot: '热卖',
                        hot1: 'HOT',
                        new: '新品',
                        new1: 'NEW',
                    },
                    buy: '购买按钮',
                    buyStyle: {
                        s1: '样式一',
                        s2: '样式二',
                        s3: '样式三',
                        s4: '样式四',
                    }
                },
                data: {
                    title: '来源',
                    goods: '商品',
                    group: '商品组',
                },
            },
            goodsGroup: {
                title: '商品组',
                menu: {
                    position: '菜单位置',
                    all: '显示\'全部\'',
                    top: '顶部',
                    left: '左侧',
                }
            },
            article: {
                title: '文章',
                text: '文字',
                name: '标题',
                desc: '副标题',
                author: '作者',
                time: '时间',
                shareDesc: '分享描述',
                images: '资料图',
                state: '状态',
                stateList: {
                    public: '发布中',
                    draft: '草稿箱',
                },
                data: {
                    title: '来源',
                    article: '文章',
                    group: '文章组',
                },
                position: {
                    inner: '图片内',
                    outer: '图片外',
                }
            },
            articleGroup: {
                title: '文章组',
            },
            templateDetail: {
                name: '模板',
                title: '详情区',
                goodsTitle: '基本信息区',
                goodsDesc: '固定样式，显示商品主图、价格等信息',
                goodsDetailTitle: '商品详情区',
                goodsDetailDesc: '每件商品可独立编辑',
                articleTitle: '基本信息区',
                articleDesc: '固定样式，显示文章主图、描述等信息',
                articleDetailTitle: '文章详情区',
                articleDetailDesc: '每条文章可独立编辑',
                data: {
                    name: '名称',
                    visible: '显示名称',
                    desc: '分组简介',
                }
            },
            groupTemplate: {
                sort: {
                    title: '排序',
                    first: '第一优先级',
                    second: '第二优先级',
                    orderAsc: '序号越大越靠前',
                    orderDesc: '序号越小越靠前',
                    timeAsc: '创建时间越晚越靠前',
                    timeDesc: '创建时间越早越靠前',
                },
                init: {
                    input: '请输入分组名称',
                    title: '添加分组',
                    e0: '请输入分组名称',
                    e1: '不允许超过10个字符',
                }
            },
        },
        material: {
            group: {
                pageMaterialBig: '大图',
                pageMaterialSmall: '小图',
                pageMaterialIcon: '图标',
                pageMaterialBackground: '背景',
            },
            big: {
                searchBarPlaceholder: '搜索您想要的图片',
                fine: '精选美图',
                theme: '主题推荐'
            },
            icon: {
                searchBarPlaceholder: '搜索您想要的图标',
                size: '图标大小',
                strokeWidth: '线段粗细',
                theme: '图标风格',
                themeOptions: {
                    outline: '线性',
                    filled: '填充',
                    twoTone: '双色',
                    multiColor: '多色',
                },
                colors: {
                    outline: '描边颜色',
                    filled: '填充颜色',
                    twoToneOutline: '描边颜色',
                    twoToneFilled: '填充颜色',
                    multiColorOutlineOuter: '外部描边颜色',
                    multiColorFilledOuter: '外部填充颜色',
                    multiColorOutlineInner: '内部描边颜色',
                    multiColorFilledInner: '内部填充颜色',
                }
            },
            background: {
                custom: '自定义背景',
                themeColor: '主题颜色',
                systemColor: '预设颜色',
            },
            back: '返回',
            totalCount: '共{count}张'
        },
        page: {
            tips: " 首页",
            shelf: "已下架",
            menu: {
                created: "创建副本",
                delete: "删除",
                move: "移动到...",
                index: "设为主页",
                shelf: "上架/下架",
                help:'了解微页面功能页区别',
            }
        },
        template: {
            orderHot: '最热',
            orderNew: '最新',
            searchBarPlaceholder: '搜索您想要的模板'
        },
        products: {
            searchBarPlaceholder: "搜索您想要的商品",
            formlabel: {
                class: "分组",
                sort: "排序",
                name: '名称',
                createTime: '发布时间',
                browseNumbers: '浏览量',
            },
            table: {
                img: "商品图",
                weight: "重量",
                color: "颜色",
                num: "库存",
                price: "价格",
            }
        },
        information: {
            searchBarPlaceholder: "搜索您想要的文章",
            repeatAdd: '重复添加了文章',
        }
    },
    template: {
        title: "微信、头条和支付宝小程序市场",
        tips: "最精美、好用的行业小程序就在这里",
        orText: "或者",
        createdText: "使用空白模板创建",
        salaText: "销售量：{salesCount}",
        detailText: "查看",
        seeText: "手机浏览",
        addtemp: "创建小程序",
        designTemp: "设计",
        manageTemp: "管理",
        noproTitle: "创建你的第一个小程序",
        noproTips: "想打造你的线上店铺？用超赞帮你轻松实现",
        selectTemp: "挑选模板",
        sucessTips: "您的订单已经支付成功",
        sucessText: "点击此处",
        sucessHref: "制作小程序",
        expiredRecentStr1: "小程序还有",
        expiredRecentStr2: "到期，到期后将无法使用请及时",
        expiredStr1: "小程序已失效",
        expiredStr2: "，已无法使用，请续费",
        renewbtn: "续费",
        tag: {
            recommend: "推荐",
            hot: "热门",
            newest: "最新",
            all: "全部",
        }
    },
    purchase: {
        information: {
            coupon: "优惠券",
            exchangeCoupons: "兑换优惠卷"
        },
        update: "更新",
        applet: "小程序",
        returnText: "返回小程序市场",
        payBtnText: "立即购买",
        modalTips: "模板介绍",
        remarks: "功能说明",
        payment: {
            paymentTitle: "超赞小程序",
            tips: "填写订单信息",
            states: {
                msg: "填写订单信息",
                check: "核对订单及支付",
                success: "支付成功"
            }
        },
        formlabel: {
            name: "小程序名称",
            minipro: "小程序",
            expiredDateBefore: "原到期时间",
            placeholder: "请输入小程序名称",
            industry: "行业",
            module: "模版",
            edition: "选择版本",
            time: " 时长",
            endTime: "到期时间",
            coupon: "优惠券",
            orderAmount: "订单总额",
            discount: "优惠总额",
            retreat: "退补总额",
            amount: "应付金额",
            orderNum: "订单编号",
            createdTime: "创建时间",
            submittText: "提交订单",
            couponCompany: "折",
            versionName: "版本",
        },
        orderTexr: "订单详情",
        paymentMethods: "支付方式",
        wechart: "微信支付",
        alipay: "支付宝",
        return: "返回",
        primary: "已支付",
        agreement: "我已阅读并同意",
        agreFile: "服务协议",
        edition: {
            usedText: "商业使用",
            usedYesText: "支持",
            usedNoText: "不支持",
            pageText: "页面上限",
            orderText: "每日订单上限",
            commodityText: "商品上限",
            space: "上传空间",

        }
    },
    manager: {
        selfInformation: {
            title: '个人资料',
            essentialInformation: "基本信息",
            headPortrait: "头像",
            replace: "更换",
            fullName: "姓名",
            birthday: "生日",
            selectDate: "选择日期",
            occupation: "职业",
            city: "所在城市",
            enterpriseOrganization: "企业/组织",
            personalProfile: "个人简介",
            updateInformation: "更新资料",
            enterprisesJoinedCreated:"已加入/创建的企业",
            invoiceIntoEnterpriseManagementPage:"（购买/续费/发票进入对应的企业管理页）",
            enterpriseName:"企业名称",
            role:"角色",
            joinCreateTime:"加入/创建时间",
            operation:"操作",
            notSet:"未设置",
            creator: "创建者",
            member:"成员",
            controller: "管理者",
            signOut:"退出",
            areYouSureYouWantToExit:"您确定要退出{merchantName}吗？",
            afterExitingYouCanOnlyBeReInvitedToJoin:"退出后，只能重新被邀请才能加入",
            uploadFailed:"上传失败"

        },
        accountSafty: {
            title: '帐号安全',
            unboundWechat: "未绑定微信",
            bindMobilePhoneNumber: "绑定手机号",
            pleaseEnterYourMobilePhoneNumber: "请输入手机号",
            pleaseEnterTheVerificationCode: "请输入验证码",
            sendVerificationCode: "发送验证码",
            securityCodeTimeOut: "{securityCodeTimeOut} s重试",
            bindMailbox: "绑定邮箱",
            pleaseEnterEmailAddress: "请输入邮箱",
            unbindWechat: "解绑微信",
            areYouSureYouWantToUnbindWechat: "确定要解绑微信“{wechatNickName}”吗",
            codeScanningBindingWechat: "扫码绑定微信",
            changePassword: "更换密码",
            authenticationToProtectAccountSecurity: "为保护帐号安全，使用{safeEmail}验证身份",
            cellPhoneNumber: "手机号",
            unboundCanHelpYouRetrieveYourAccount: "未绑定，绑定后可以帮助您找回账号",
            unbound: "解 绑",
            binding: "绑 定",
            mailbox: "邮箱",
            wechatLoginBinding: "微信登录绑定",
            wechatCodeScanningFastLogin: "已绑定微信登录，可以通过微信扫码快速登录",
            loginPassword: "登录密码",
            pleaseBindTheMailboxAndSetThePasswordFirst: "请先绑定邮箱再设置密码",
            accountPasswordLogin: "已设置登录密码，可以通过账号密码登录",
            replace: "更 换",
            pleaseSetYourMobileNumberOrEmailFirst: "请先设置手机号或者邮箱",
            pleaseBindYourMobileNumberOrEmailFirst: "请先绑定手机号或邮箱才可以解绑微信帐号！",
            bindingSucceeded: "绑定成功！",
            pleaseEnterTheCorrectMailbox: "请输入正确的邮箱",

        },
        informationManager: {
            replace: "更换",
            headPortrait: "头像"
        },
        order: {
            newlyBuild: "新建",
            upgradeOnly: "仅升级",
            renewalOnly: "仅续期",
            upgradeRenew: "升级&续期",
            appletInformation: "小程序信息",
            templateInformation: "模板信息",
            legalPerson: '企业归属',
            title: '小程序订单',
            record: '订单记录',
            miniName: '小程序名称',
            templateName: '模板名称',
            duration: '时长',
            coupon: '优惠',
            paymentMethod: '支付方式',
            paymentMethod0: '微信',
            paymentMethod1: '支付宝',
            amount: '实付金额',
            orderStatus: '交易状态',
            orderStatus0: '未支付',
            orderStatus1: '已支付',
            orderStatus2: '已取消',
            orderStatus3: '已退款',
            receipt: '发票',
            orderNumber: '订单号',
            payer: '付款人',
        },
        receipt: {
            apply: '申请发票',
            applied: '已申请',
            type: '发票类型',
            company: '公司',
            person: '个人',
            title: '发票抬头',
            taxNo: '公司税号',
            address: '快递地址',
            userName: '联系人',
            userPhone: '联系电话',
            cancel: '取消',
            commit: '确认申请',
            rules: {
                title: '请输入发票抬头',
                taxNo: '请输入公司税号',
                address: '请输入快递地址',
            }
        },
        person: {
            title: '成员管理',
            add: '添加',
            allUser: '全部成员',
            inviteModal: {
                title: '添加企业成员',
                inviteLabel: '账户邀请',
                add: '添加',
                rules: {
                    account: '请输入账户',
                },
            },
            roles: {
                role0: '所有者',
                role1: '企业成员',
                role2: '管理者',
            }
        },
        information: {
            title: '企业资料',
            company: {
                title: '企业信息',
                name: '企业/组织名称',
                scale: '团队规模',
                industry: '所属行业',
                phone: '联系电话',
                address: '公司地址',
            },
            person: {
                title: '我的企业名片',
                name: '姓名',
                phone: '手机号',
                email: '电子邮箱',
            },
            update: '更新资料',
        },
    },
    fileUpload: {
        sizeUnsupported: '文件大小超过限制，不超过{size}',
        typeUnsupported: '文件格式不支持，仅持支持{type}',
        wait: '等待上传',
    },
    materialSelector: {
        image: "图片",
        video: "视频",
        myUpload: "我的素材",
        groupUpload: "团队素材",
        sys: {
            title: "系统素材",
            bigImage: "大图",
            littleImage: "小图",
            bgImage: "背景图",
            icon: "图标"
        },
        cancel: "取消",
        confirm: "确认",
        rename: "重命名",
        confirmDel: "确定删除吗？",
        pleaseEnterName: "请输入名称",
        hint: "提示",
        defaultFolderCannotAdded: "默认文件夹 无法添加",
        lengthRangesFrom1To12Characters: "请检查不可包含特殊字符、长度在 1 到 12 个字符",
        defaultFolderCannotModified: "默认文件夹 无法修改",
        defaultFolderFileCannotDeleted: "默认文件夹 文件无法删除",
        vtRenew: "续费",
        creatorSystem: "创建者: 系统",
        ihidDocuments: "文件被我藏起来了！😜",
        maximumOfFilesSelected: "最多可选 {selectNumber} 个文件",
        creator: "创建者",
        pleaseEnterNewFileName: "请输入新的文件名",
        folderNotSelected: "没有选中文件夹",
        lengthRangesFrom1To20Characters: "长度在 1 到 20 个字符",
        folder: "文件夹",
        pleaseEnterTitleName: "请输入关键字",
        movingFolders: "移动文件夹",
        pleaseSelectDestinationFolder: "请选择目标文件夹",
        findKeywordRelatedResultsInMyUpload: "在「{target}」中找到 {number} 个与 「{key}」相关的结果",
        clear: "清空",
        checkAll: "全选",
        theSelected: "已选 {checkedNumber} 项",
        moveTo: "移动到",
        del: "删除",
        areDeleteSelectedContent: "确定删除选中的内容吗？",
        filteren: "筛选条件",
        fileName: "文件名称",
        fileSize: "文件大小",
        createTime: "创建时间",
        download: "下载",
        move: "移动",
        edit: "编辑"
    },
    material: {
        image: '图片',
        newFolder: "新建文件夹",
        reset: "续费",
        upload: "上传",
        noPictures: "暂无图片",
        imageFileRestrictMsg: "只能上传jpg/png文件，且不超过10M",
        video: "视频",
        noVideo: "暂无视频",
        videoFileRestrictMsg: "只能上传MP4文件，且不超过100M",
        hint: "提示",
        rename: "重命名",
        set: "修改",
        confirm: "确定",
        cancel: "取消",
        lengthRangesFrom1To12Characters: "长度在 1 到 12 个字符",
        defaultFolderFileCannotModified: "默认文件夹 文件无法修改",
        operationWillDeleteFileWantToContinue: "此操作将删除该文件, 是否继续?",
        defaultFolderFileCannotDel: "默认文件夹 文件无法删除",
        new: "新建",
        defaultFolderAlreadyExists: "默认文件夹 已存在",
        creator: "创建者",
        checkAll: "全选",
        selectedItem: "已选 {checkedNumber} 项",
        moveTo: "移动到",
        del: "删除",
        filter: "筛选条件",
        name: "名字",
        size: "大小",
        createTime: "创建时间",
        thereAreNoOtherFoldersToMove: "没有其它文件夹可以移动",
        lengthRangesFrom1To20Characters: "长度在 1 到 20 个字符",
        operationWillDeleteSelectedFileWantToContinue: "此操作将删除所选文件, 是否继续?",
        moveImage: "移动图片",
        move: "移动",
        open: "打开",
        dragFileHereOr: '将文件拖到此处，或',
        clickUpload: "点击上传",
    },
    keyboard: {
        title: '快捷键',
        group1: '组件创建',
        group2: '基础编辑',
        group3: '自由容器内组件操作',
        group4: '图层控制',
        group5: '微页面控制',
        group6: '画布控制',
        group7: '画板控制',
        t: '文本',
        r: '矩形',
        o: '圆形',
        l: '直线',
        save: '保存',
        delete: '删除',
        undo: '撤销',
        redo: '重做',
        cut: '剪切',
        copy: '复制',
        fastCopy: '快速复制',
        paste: '粘贴',
        review: '预览',
        link: '链接模式',
        mulSelect: '多选',
        seSelect: '选取部分',
        delimitSelect: '鼠标划定选取',
        selectAll: '全选',
        selectGroup: '选取组合内元素',
        editTextStart: '文字编辑',
        editTextEnd: '退出编辑',
        scale: '等比缩放',
        fastMove: '快速移动',
        alignT: '顶部对齐',
        alignB: '底对齐',
        alignL: '左对齐',
        alignR: '右对齐',
        alignC: '水平居中',
        alignV: '垂直居中',
        moveUp: '上移一层',
        moveDown: '下移一层',
        moveTop: '置顶',
        moveBottom: '置底',
        click: '单击',
        press: '按住不放',
        drag: '鼠标拖拽',
        direction: '上下左右',
        pageUp: '上一页',
        pageDown: '下一页',
        pageHome: '第一页',
        pageEnd: '最后一页',
        scaleDashboard: '缩放画布',
        mouseWheel: '鼠标滚轮',
        page: '页面',
        template: '模板',
        compose: '组件',
        material: '素材',
        my: '我的',
        goods: '商品',
        article: '文章',
        colorDashboard: '颜色面板',
        leftDashboard: '左侧面板'
    },
    action: {
        title: '动作设置',
        placeholder: '请选择',
        cancel: '取消',
        commit: '确定',
        inputPlaceholder: '请输入',
        insite: {
            title: '站内链接',
            page: '微页面',
            home: '店铺主页',
            my: '个人中心',
            cart: '购物车',
            allGoods: '全部商品',
            goods: '某一商品',
            goodsGroup: '商品分组',
            allArticle: '全部文章',
            article: '某一文章',
            articleGroup: '文章分组',
            coupon: '我的优惠券',
            login: '登录',
            address: '收货地址',
            collect: '我的收藏',
            goodsDetail: {
                name: '商品名称',
                group: '商品分组',
                title: '商品/价格',
                visitsNumbers: '访问量',
                browseNumbers: '浏览量',
                stock: '库存',
                saleCount: '总销量',
            },
            articleDetail: {
                name: '文章标题',
                group: '文章分组',
                title: '文章',
                author: '作者',
            },
            pageDetail: {
                name: '微页面名称',
                // group:'文章分组',
                title: '微页面',
                // author:'作者',
            },
        },
        outsite: {
            title: '站外链接',
            tip: '请在微信小程序后台配置此业务域名，否则将不能跳转',
            setting: '配置业务域名教程',
        },
        system: {
            title: '功能',
            select: '功能选择',
            phoneNumber: '电话号码',
            appId: 'APPID',
            path: '跳转路径',
            map: {
                title: '名称',
                address: '详细地址',
                lng: '经度',
                lat: '纬度',
                inputAddress: '请先输入详细地址',
                noPoint: '您的详细地址没有解析到结果',
                parse: '解析',
            },
            fileName: '文件',
            upload: '上传附件',
            accept: '文件大小限50MB以内，支持doc/docx/pdf/xls/xlsx/ppt/pptx 等格式',
        },
        senior: {
            title: '置空',
            phone: '电话',
            address: '地址',
            minipro: '跳转小程序',
            share: '分享',
            download: '文件下载',
        },
    },
    loadScroll: {
        init: '',
        loading: '加载中...',
        finish: '滑动或点击加载更多',
        nodata: '无更多数据',
    },
    productSelector: {
        title: {
            goods: '选择商品',
            goodsGroup: '选择商品组',
            article: '选择文章',
            articleGroup: '选择文章组',
            pageCard: '选择微页面',
            tabbar: '选择微页面',
            coupon: '选择优惠券',
            '': '',
        }
    },
    services: {
        header: {
            title: "小程序定制服务",
            tips: "超赞解决方案技术专家团队拥有深厚的技术实力及丰富的实施经验，满足用户个性化应用开发需求，帮助用户构建创新型技术解决方案，为企业提供小程序（系统）等应用开发服务。",
            btn: "在线咨询",
            saomazixun: "微信扫码咨询",
        },
        cards: {
            qiyemini: "企业品牌小程序",
            qiyeminiTips: "专业设计师一对一量身设计和开发，云计算独立部署，彰显企业品牌形象。",
            dianshangmini: "电商小程序",
            dianshangminiTips: "支持单店、多店、分销等电商模式和功能，完成团购、秒杀、分销等多种营销场景需求。",
            yuyuemini: "预约小程序",
            yuyueminiTips: "为4S店、中介、场馆参观、体验等服务场景，提供预约小程序，支持评论、导航、客户登记等功能。",
            kechengmini: "课程小程序",
            kechengminiTips: "支持单店、多店、分销等电商模式和功能，完成团购、秒杀、分销等多种营销场景需求。",
            jifenmini: "会员积分小程序",
            jifenminiTips: "专业设计师一对一量身设计和开发，云计算独立部署，彰显企业品牌形象。",
            hangyemini: "众多行业小程序",
            hangyeminiTips: "为众多行业量身定制开发多种功能的小程序，支持微信，支付宝，百度，抖音等多种应用场景交互应用。",
            zixun: "在线咨询",
        },
        content: {
            youshi: "定制开发优势",
            team: "专业团队",
            xiangmujingyan: "经验丰富的项目经理",
            youshiTips: "专家团队均由具有战略思想的项目经理领导，同时我们邀请客户加入我们的设计环节，参与迭代开发设计，并建立长期产品开发路线图。",
            jiejuefangan: "解决方案",
            shixiancelve: "实现策略",
            shixiancelvetips: "领先的企业在技术上、运营上正在向精益化发展，寻求更快、更好、更强大的业务发展。 要提高企业竞争力，您需要一个可以提高生产效率和价值的解决方案。",
            youhuacelve: "优化策略",
            youhuacelvetips: "在成熟的行业解决方案基础上，根据企业自身特点，进行必要的调整和创新。",
            yunyingcelve: "运营策略",
            yunyingcelvetips: "打造出色的产品仅仅是开始，我们将帮助您打造业务运营方案，提高客户满意度。",
            xiaoguojiance: "效果监测",
            shishigenzong: "实时跟踪",
            shishigenzongtips: "更快地试错和验证，意味着可以节省大量的时间在无效的问题上。跟踪解决方案的运行情况，持续有效的优化。",
            shujumaidian: "数据埋点",
            shujumaidiantips: "在成熟的行业解决方案基础上，根据企业自身特点，进行必要的调整和创新。",
            zhinengfenxi: "智能分析",
            zhinengfenxitips: "通过智能分析系统，了解用户的使用逻辑，为优化方案提供依据。",
            zhulizengzhang: "助力增长",
        },
        fuwuliucheng: "小程序服务流程",
        xuqiugoutong: "需求沟通",
        laingshengdingzhi: "量身定制方案",
        xuanzefangan: "选择方案下单",
        zhuceduoduan: "为您注册多端小程序",
        goutongzhuangxiu: "沟通装修制作",
        chakanyanshou: "客户查看验收",
        wenxintishi: "全程标准化服务 保障小程序完美上线"
    },
    goodDetailPage: {
        commodity: "商品",
        detail: "详情",
        goodDescription: "这里是商品名称这里是商品名称这里是商",
        service: "客服",
        shop: "店铺",
        shoppingCart: "购物车",
        addToTheCart: "加入购物车",
        buyNow: "立即购买",
        goodsInfo: {
            name: '1.61依莎克非球面树脂眼镜片防紫外线抗辐射眼镜片翡翠膜眼镜片',
            feature: '四色可选',
            service: '收货后结算·快递发货',
            skus: '颜色；尺码',
        }
    },
    goodBaseInfo: {
        share: "分享",
        freight: "运费",
        sales: "销量",
        discount: "优惠",
        fullReduction: "满减",
        couponsAreUniversal: "优惠券全场通用",
        service: "服务",
        choose: "选择",
        classificationOptional: "共{skuCount}种分类可选",
        shopHome: "店铺首页",
        enterTheStoreAndStrollAround: "进店逛逛",
        brand: "品牌",
        enterpriseCertification: "企业认证",
        yearsOld: "年老店"
    },
    editGoodDetailPage: {
        reveal: "展示",
        shopButton: "店铺按钮",
        clickButtonStoreHomepage: "买家点击该按钮快速返回到店铺主页",
        recommendedProducts: "推荐商品",
        recommendedProductsDescription: "勾选后商品基本信息下会根据用户行为展示推荐商品, 提高转化",
        buyNow: "立即购买",
        showBuyNowButton: "展示「立即购买」按钮",
        showBuyNowButtonDescription: "如果不勾选，商品详情页仅展示加入购物车",
        showBuyNowButtonFooterDescription: "默认名称为立即购买，可自定义名称，中文最大6个字，英文10个字；自定义后，对全店商品有效，若需设置单个商品，可前往商品管理页单独编辑设置。",
        sales: "销量",
        showSalesInformation: "展示「销量信息」",
        displayWhenTheSalesVolumeReaches: "销量到达多少件时显示",
        share: "分享",
        showAppletSharing: "显示小程序分享",
        showCustomPrefix: "显示自定义前缀",
        title: "标题",
        titleContent: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
        viewExampleOfSharingEffect: "查看分享效果示例",
        showPosterSharing: "显示海报分享",
        showPosterSharerInformation: "显示海报分享人信息",
    },
    editDataset: {
        dataQuery: "数据查询",
        dataSource: "数据源"
    },
    editMyPage: {
        myOrder: "我的订单",
        backgroundColor: "背景色",
        showMyOrder: "显示我的订单"
    },
    displayDataset: {
        pleaseEnterContent: "请输入内容",
        pleaseChoose: "请选择",
        inquiry: "查询"
    },
    displayMyOrder: {
        myOrder: "我的订单",
        viewAllOrders: "查看全部订单"
    },
    displayCoupon: {
        useImmediately: "立即领取"
    },
    editCoupon: {
        coupon: "优惠券",
        color: "颜色",
        hideRobbedAndExpiredCoupons: "隐藏已抢完及失效的券"
    },
    mineFileItem: {
        rename: "重命名",
        delete: "删除",
        moveTo: "移动到",
    },
    mineMaterial: {
        uploadMaterial: "上传素材",
    },
    mobileUploadDialog: {
        mobileUpload: "手机上传",
        description: " 用手机“扫一扫”上传手机相册图片",
        thisPageWillAutomaticallyClose: "（上传成功后，本页面将自动关闭）"
    },
    mine: {
        myUpload: "我的素材",
        teamUpload: "团队素材",
        MineGroupList: {
            whole: "全部"
        }
    },
    upload: {
        continueAdding: "继续添加",
        startUploading: "开始上传",
        complete: {
            tip: "图片上传完成"
        },
        empty: {
            tip: "拍照或从相册中选择图片",
            uploadPictures: "上传图片"
        },
        error: {
            info: "图片上传失败，请重新生成二维码"
        }
    },
    myMiniPro: {
        template: {
            update: "更新于{getUpdateTimeStr}前",
            renewNow: "立即续费",
            dueInAFewDays: "{getExpTimeDay}后到期",
            managementApplet: "管理小程序",
            designApplet: "设计小程序",
            renew: "续费",
            appletSettings: "小程序设置"
        }
    },
    createMiniPro: {
        qrTip: "手机扫描二维码查看",
        searchForAppletTemplateYouWant: "搜索您想要的小程序模板，例如咖啡店"
    }
}
