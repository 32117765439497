<template>
    <div v-if="false">
        <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                  <i-earth :strokeWidth="3"/><div class="text">语言</div><i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item  v-for="item in options">{{item.label}}</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
    </div>
</template>

<script>
    export default {
        name: "Language",
        data() {
            return {
                language: "Zh-cn",
                options: [
                    {
                        label: this.$t('login.chinese'),
                        value: "Zh-cn"
                    },
                    {
                        label: "English",
                        value: "En-us"
                    }
                ],
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index.less";

    .el-dropdown-link {
        .flex-center;
        cursor: pointer;

        .text {
            margin-left: 5px;
        }
    }
</style>
