import materialSelector from '../cz-material-selector';
const obj = {};
obj.install = (Vue) => {
    const Constructor = Vue.extend(materialSelector);
    const constructor = new Constructor();

    Vue.prototype.$materialSelector = function (options) {
        document.body.appendChild(constructor.$mount().$el);
        constructor.show(options);
    }
};
export default obj
