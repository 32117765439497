<template>
    <div class="cz-material-selector-content">
        <CzMaterialSelectorContentHeader @clickUpload="clickUpload" v-model="value"/>
        <CzMaterialSelectorTable ref="materialSelectorTable" v-model="value"/>
        <div class="cz-material-selector-content-footer">
            <div class="footer-left">
                <el-pagination
                        v-if="value.pagination && value.pagination.total > value.pagination.pageSize"
                        :pager-count="5"
                        :page-size="value.pagination.pageSize"
                        layout="prev, pager, next"
                        :total="value.pagination.total"
                        background
                        @current-change="currentChange"
                ></el-pagination>
            </div>
            <div class="footer-right">
                <el-button @click="close" size="small">{{i18n.t("materialSelector.cancel")}}</el-button>
                <el-button @click="confirm" type="primary" size="small">{{i18n.t("materialSelector.confirm")}}</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import CzMaterialSelectorContentHeader from "./cz-material-selector-content-header";
    import CzMaterialSelectorTable from "./cz-material-selector-table";
    import i18n from "../../../../locale";
    export default {
        name: "cz-material-selector-content",
        components: {CzMaterialSelectorTable, CzMaterialSelectorContentHeader},
        data(){
            return {
                i18n: i18n,
            }
        },
        props: {
            value: Object,
        },
        methods: {
            currentChange(e) {
                this.$bus.$emit(this.$BUS_EVENT.PAGINATION_CHANGE, e);
            },
            clickUpload() {
                this.$refs.materialSelectorTable.clickUpload()
            },
            confirm() {
                console.log("confirm");
                this.$emit("confirm")
            },
            close() {
                console.log("close");
                this.$emit("close");
            },
        },
        beforeDestroy() {
            this.$bus.$off(this.$BUS_EVENT.PAGINATION_CHANGE);
        }
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";
    .cz-material-selector-content {
        flex: 1;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        .cz-material-selector-content-footer {
            height: 60px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            margin-bottom: 10px;

            .footer-left {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                flex: 1;
            }

            .footer-right {
                width: 200px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }

            .el-button {
                width: 80px;
                margin-right: 21px;
            }
        }
    }
</style>
