const memoryUnits = ["KB", "MB", "GB"];

export default {
    getMemory : (kb) => {
        let size = kb;
        for (let memoryUnit of memoryUnits) {
            if (size > 1024){
                size = (size / 1024);
            }else {
                return Math.round(size ? size : 0) + memoryUnit;
            }
        }
    }
}
