<template>
    <div class="cz-material-selector-content-header">
        <div v-if="value.activeFileType && value.activeFileType.children && value.activeFileType.children.length > 0">
            <el-menu
                    class="cz-material-selector-content-header-menu"
                    mode="horizontal"
                    text-color="#333333"
                    active-text-color="#FF6F33"
                    :default-active="value.activeCode"
                    @select="onSelect"
            >
                <el-menu-item v-for="item in value.activeFileType.children"
                              :index="item.index" v-if="item.children.length <= 0 ">
                    {{item.title}}
                </el-menu-item>

                <el-submenu v-for="item in value.activeFileType.children"
                            :index="item.index" v-if="item.children.length > 0 ">
                    <template slot="title">{{item.title}}</template>
                    <el-menu-item class="submenu-item" v-for="sitem in item.children"
                                  :index="sitem.index">
                        {{sitem.title}}
                    </el-menu-item>
                </el-submenu>
            </el-menu>
        </div>
        <div v-else class="cz-material-selector-content-header-folder-name">
            {{value.activeFolder?value.activeFolder.groupName:''}}
        </div>
        <div v-if="!sysMaterial">
            <el-button @click="clickUpload" size="small" class="cz-material-selector-content-header-button">
                <div class="flex-center">
                    <i-upload/>
                    <span style="margin-left: 5px;">上传{{value.activeFileType.title}}</span>
                </div>
            </el-button>
        </div>
    </div>
</template>

<script>
    import i18n from "../../../../locale";

    export default {
        name: "cz-material-selector-content-header",
        data() {
            return {
                i18n: i18n,
            }
        },
        props: {
            value: Object,
        },
        computed: {
            sysMaterial() {
                return this.value.activeImageType.code === 2;
            },
        },
        watch: {
            "value.activeCode": {
                deep: true,
                immediate: true,
                handler(code) {
                    this.setImageType(code)
                }
            }
        },
        methods: {
            clickUpload() {
                this.$emit("clickUpload")
            },
            onSelect(index) {
                this.setImageType(index)
            },
            setImageType(indexStr) {
                let _this = this;
                if (indexStr) {
                    _this.$set(_this.value, "pagination", {pageNum: 1});
                    let index = indexStr.split("-")[0];
                    if (_this.value.activeFileType.code === 0) {
                        let data = _this.value.activeFileType.children.find(
                            item => item.index === index
                        );
                        if (!(data.children) || data.children.length === 0) {
                            _this.value.activeImageType = data;
                        } else {
                            _this.value.activeImageType = data.children.find(
                                item => item.index === indexStr
                            );
                        }
                    } else {
                        _this.value.activeImageType = {
                            index: "2",
                            code: 0,
                            router: "CzMaterialSelectorTableDefault",
                            title: _this.i18n.t("materialSelector.groupUpload"),
                        };
                    }
                }
            },
        },
    }
</script>

<style scoped lang="less">
    @import "../../../../assets/style/index.less";

    .cz-material-selector-content-header {
        height: 54px;
        width: 100%;
        border-bottom: solid 1px @color-border;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .cz-material-selector-content-header-folder-name {
            font-size: 14px;
            margin-top: 18px;
            margin-left: 20px;
            font-weight: bold;
        }

        .cz-material-selector-content-header-button {
            margin-top: 12px;
            margin-right: 23px;
        }

        .cz-material-selector-content-header-menu {
            height: 54px;

            .el-menu-item {
                height: 55px;
            }

            & /deep/ .el-submenu .el-submenu__title {
                height: 55px !important;
            }
        }
    }
</style>
