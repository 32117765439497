import Vue from 'vue'
import VueI18n from 'vue-i18n'
import customZhCn from './lang/edit-zh-CN'
import managerZhCn from './lang/manager-zh-CN'
import customZhTw from './lang/zh-TW'
import customEnUs from './lang/en-US'
import elementEn from 'element-ui/lib/locale/lang/en'
import elementZh from 'element-ui/lib/locale/lang/zh-CN'
import elementTw from 'element-ui/lib/locale/lang/zh-TW'
import Cache from "../utils/Cache";
Vue.use(VueI18n);

// 自动根据浏览器系统语言设置语言
let lang = Cache.cache.get('local') || 'zh-CN';

Vue.config.lang = lang;
Vue.locale = () => {};
const messages = {
  'zh-CN': {
    ...customZhCn,
    ...managerZhCn,
    ...elementZh
  },
  'zh-TW': {
    ...customZhTw,
    ...elementTw
  },
  'en-US': {
    ...customEnUs,
    ...elementEn
  }
};
const i18n = new VueI18n({
  locale: lang,
  messages
});

export default i18n
