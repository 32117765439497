<template>
    <CzModal :visible="visible">
        <div class="content">
            <div class="logout">
                <i @click="logout" style="margin-right: 25px;" class="el-icon-error"></i>
            </div>
            <div class="bottom">
                <components :is="showKey" @callback="callback">
                    <template slot="title">
                        <h1>登录超时请重新登录</h1>
                    </template>
                </components>
            </div>
            <div class="top">
                <el-radio-group v-model="showKey" style="margin-bottom: 30px;">
                    <el-radio-button label="UsernamePasswordForm">账号密码登录</el-radio-button>
                    <el-radio-button label="SecurityCodeForm">验证码登录</el-radio-button>
                    <el-radio-button label="WeChatQRCodeForm">微信扫码</el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </CzModal>
</template>

<script>
    import CzModal from "../common/cz-modal/index"

    import UsernamePasswordForm from "../login/UsernamePasswordForm";
    import SecurityCodeForm from "../login/SecurityCodeForm";
    import WeChatQRCodeForm from "../login/WeChatQRCodeForm";
    import CommonLogin from "../../utils/CommonLogin";
    export default {
        name: "index",
        components: {WeChatQRCodeForm, SecurityCodeForm, UsernamePasswordForm,CzModal},
        data(){
            return {
                showKey: 'UsernamePasswordForm',
                visible:false,
            }
        },
        mounted() {
            this.$nextTick(function () {
                this.visible = true;
            })
        },
        methods:{
            callback(result){
                if (result && result === true){
                    this.visible = false;
                    this.$notify({
                        title: '成功',
                        message: '登录成功',
                        type: 'success'
                    });
                }else {
                    this.$notify.error({
                        title: '错误',
                        message: "登录意外失败"
                    });
                }
            },
            logout(){
                this.visible = false;
                CommonLogin.logout();
            }
        }
    }
</script>

<style scoped lang="less">

    @import "../../assets/style/index";

    .content{
        width: 750px;
        height: 550px;
        background: white;
        z-index: 9999999;
        box-shadow: 0 4px 16px rgba(0,0,0,0.1);
        border-radius: @border-radius-lg;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 20px 0;
        .top{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;
        }

        .bottom {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 400px;
            flex: 1;
        }
        .logout{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 30px;
            font-size: 26px;
            cursor: pointer;
            transition: 0.1s;
        }
        .logout:hover{
            color: @color-primary;
            transition: 0.1s;
        }
    }
</style>
