import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import ElementUI from 'element-ui';
import './assets/style/el-theme/index.css'
import './assets/style/popconfirm.less'
import i18n from './locale/index'
import bus from "./bus/install"
import store from './store'
import config from "./config/index"
import context from "./utils/Context"
import message from "./utils/Message"
import Cache from "./utils/Cache"
import tokenExpireModal from "./components/token-expire-modal/index.js"
import materialSelector from "./components/common/cz-material-selector/js/index.js"
import Contextmenu from "vue-contextmenujs"
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
import {install} from '@icon-park/vue/es/all';
import SvgIcons from "./components/common/cz-svg-icons/install"
import VueAMap from 'vue-amap';
import clickOutside from "./directive/click-outside";
import VueClipboard from 'vue-clipboard2'
import VueTimeago from 'vue-timeago'

/*import fuck from "./utils/Fuck";*/
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

Vue.use(bus);
Vue.use(config);
/*Vue.use(fuck);*/
Vue.use(context);
Vue.use(message);
Vue.use(VueDPlayer);
Vue.use(Contextmenu);
Vue.use(tokenExpireModal);
Vue.use(materialSelector);
Vue.use(SvgIcons);
install(Vue,"i");
Vue.use(clickOutside);
Vue.use(VueClipboard)
Vue.use(Cache)
Vue.use(VueTimeago, {
  name: "timeago",
  locale: 'zh-CN',
  locales: {
    'zh-CN': require('date-fns/locale/zh_cn'),
    'en-US': require('date-fns/locale/en')
  }
});

//高德地图
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'ccf49e512a59aa05cebf8f9e1de2aaae',
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  // 默认高德 sdk 版本为 1.4.4
  v: '1.4.4'
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
