<template>
    <svg :class="[className]">
        <use :xlink:href="`#cz-icon-${name}`"/>
    </svg>
</template>

<script>
    export default {
        name: "cz-svg-icon",
        props:{
            name:String,
            className:String
        }
    }
</script>

<style scoped lang="less">

</style>
